import React from 'react';
import List from 'components/List';
import { observer } from 'mobx-react-lite';
import { standingSort } from 'pages/currentseasons/Standings';
import { calcStandings, rowStyle } from 'helpers/standings';
import { useQuery } from 'react-query';
import { esportsApi } from 'helpers/Api';

function getFields(tagId, compact) {
    const basicFields = [
        { field: 'no', title: '#' },
        { field: 'nickname', title: 'Player' },
    ];

    if (tagId === 'soccer') {
        if (!compact) {
            basicFields.push({ field: 'games_played', title: 'GP' });
            basicFields.push({ field: 'points', title: 'PTs' });
        }

        const compactFields = [
            { field: 'win_rate', title: 'W%' },
            { field: 'draw_rate', title: 'D%' },
            { field: 'loss_rate', title: 'L%' },
        ];

        const restFields = [
            { field: 'wins', title: 'W' },
            { field: 'draws', title: 'D' },
            { field: 'loses', title: 'L' },
            { field: 'goals_total_for', title: 'GF' },
            { field: 'goals_total_against', title: 'GA' },
            { field: 'goals_total_difference', title: 'GD' },
            { field: 'goals_for_per_match', title: 'GF/M' },
            { field: 'goals_against_per_match', title: 'GA/M' },
            { field: 'points_per_match', title: 'P/M' },
        ];

        const fields = compact ? [...basicFields, ...compactFields] : [...basicFields, ...compactFields, ...restFields];

        return fields;
    } else if (tagId === 'basketball') {
        const compactFields = [
            { field: 'win_rate', title: 'PCT' },
            { field: 'points_for_per_match', title: 'PF' },
            { field: 'points_against_per_match', title: 'PA' },
            { field: 'diff', title: 'DIFF' },
        ];

        const restFields = [
            { field: 'games_played', title: 'GP' },
            { field: 'wins', title: 'W' },
            { field: 'loses', title: 'L' },
        ];
        return compact ? [...basicFields, ...compactFields] : [...basicFields, ...restFields, ...compactFields];
    }
}

function SportStandings({ title, sportId, compact = false, options = {}, showPromotionRelegationAesthetics = false }) {
    const { isLoading, data } = useQuery(
        ['SportStandings', sportId],
        () => {
            return esportsApi.getList({ xtc: false }, `sports/${sportId}/players/standings`).then((response) => {
                return {
                    standing: response.data.map(calcStandings(sportId)),
                    tagId: response.tagId
                };
            });
        }
    );

    if (isLoading) {
        return null;
    }

    const fields = getFields(data.tagId, compact) || [];

    return (
        <>
            {data && (
                <List
                    data={data.standing}
                    hasSelection={false}
                    hasSearch={false}
                    hasPagination={false}
                    hasFiltering={false}
                    title={title ? title : compact ? `Outcome Ratio (%)` : 'Standings'}
                    options={{
                        ...options,
                        rowStyle: rowStyle(showPromotionRelegationAesthetics, data.standing),
                    }}
                    fields={fields.map((field) => ({
                        ...field,
                        emptyValue: '-',
                        filtering: false,
                        customSort: standingSort(field),
                    }))}
                />
            )}
        </>
    );
}

export default observer(SportStandings);
