import styled from 'styled-components';

export const Wrapper = styled.div`
    flex: 0 0 150px;
    margin: 0 3px 3px 3px;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    outline: 1px solid #ddd;
`;

export const RemoveIcon = styled.i`
    position: absolute;
    right: 3px;
    top: 5px;
    width: 25px;
    height: 25px;
    font-size: 25px;
    line-height: 25px !important;
    color: red;
    cursor: pointer;
`;

export const Title = styled.p`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    text-shadow: 0 0 4px black;
    word-break: break-all;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: 400;
    transition: opacity 0.3s ease 0s;
`;

export const EnhancedFileWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    flex: 1;
    border: 1px solid #ddd;
    padding: 15px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    box-shadow: 0 4px 10px -5px rgba(68, 68, 68, 0.5), inset 6px 0 0 #6d8ec7;
    cursor: grab;
`;

export const SmallImage = styled.div`
    flex: 0 0 155px;
    margin: 0;
    position: relative;
    outline: 1px solid #ddd;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.type === 'image' ? `url(${props.url})` : null)};
    background-color: ${(props) => (props.type !== 'image' ? 'rgba(9, 30, 66, 0.3)' : null)};
`;

export const InputsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 10px;

    .form-horizontal,
    .form-group {
        margin-right: 0;
        margin-left: 0;
    }
`;

export const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const DateTime = styled.div`
    flex: 0 0 calc(50% - 5px);

    & .form-group {
        margin-bottom: 0;
    }
`;

export const Active = styled.div`
    flex: 0 0 calc(50% - 5px);
    align-self: center;
`;

export const Link = styled.div`
    flex: 0 0 calc(50% - 5px);

    & .form-group {
        margin-bottom: 0;
    }
`;
