import React from 'react';
import { useHistory } from 'react-router-dom';

const StepIcon = ({ title, accomplished, url, accomplishedIcon, unaccomplishedIcon }) => {
    const history = useHistory();

    return (
        <div
            className="step-icon"
            onClick={() => {
                if (url) {
                    history.push(url);
                }
            }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: url ? 'pointer' : 'default',
            }}
        >
            <h5 style={{ lineHeight: '1.5' }}>&nbsp;</h5>
            <img alt="Step" src={accomplished ? accomplishedIcon : unaccomplishedIcon} />
            <h5
                style={{
                    lineHeight: '1.5',
                    color: accomplished ? 'black' : '#bdbdbd',
                }}
            >
                {title}
            </h5>
        </div>
    );
};

export default StepIcon;
