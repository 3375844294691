import React from 'react';
import GraphStats from 'pages/fixture/GraphStats';
import Banner from 'pages/fixture/Banner';
import CompactTable from 'pages/fixture/CompactTable';
import HeadToHeadTable from 'components/HeadToHeadTable';
import CurrentSeasonsFilter from 'components/CurrentSeasonsFilter';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useHeadToHeadStore } from './store';
import { esportsApi } from 'helpers/Api';

const HeadToHead = () => {
    const {
        fetchSeason,
        sportId,
        season,
        seasonStandings,
        seasonParticipants,
        homeParticipant,
        awayParticipant,
        homeStandings,
        awayStandings,
        homeForm,
        awayForm,
        stats,
        headToHeadFixtures,
        homeUpcomingFixtures,
        awayUpcomingFixtures,
        homeLatestFixtures,
        awayLatestFixtures,
        updateHomeParticipant,
        updateAwayParticipant,
        setActiveTab,
    } = useHeadToHeadStore();

    let { seasonId, player1, player2 } = useParams();
    const [options, setOptions] = React.useState();
    const [notFound, setNotFound] = React.useState(false);

    React.useEffect(() => {
        if (!seasonId) {
            esportsApi.getList({ status: 1 }, 'seasons').then((seasons) => {
                const currentSeasons = seasons.filter((season) => {
                    // TODO: May we won't filter the knockout
                    return !season.format.includes(2);
                });

                if (currentSeasons.length > 0) {
                    fetchSeason(currentSeasons[0].id);

                    setOptions(
                        currentSeasons.map((s) => ({
                            value: s.id,
                            label: `${s.tournament.category.sport.name} / ${s.tournament.category.name} / ${s.name}`,
                        }))
                    );
                } else {
                    setNotFound(true);
                }
            });
        } else {
            fetchSeason(seasonId, player1, player2);
        }
    }, [fetchSeason, seasonId, player1, player2]);

    return (
        <div className="head-to-head-wrapper">
            <Helmet>
                <title>GT Sports - Head to Head</title>
                <meta
                    name="description"
                    content="Compare player to player and get insight information about past player meetings, players forms, current standings and in-game trends.
"
                />
                <meta property="og:title" content="GT Sports - Head to Head" />
                <meta
                    property="og:description"
                    content="Compare player to player and get insight information about past player meetings, players forms, current standings and in-game trends.
"
                />
                <meta property="og:url" content="https://gtleagues.com/current-seasons" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports head-to-head" />
            </Helmet>
            {!seasonId && options && (
                <div className="my-2">
                    <CurrentSeasonsFilter
                        onChange={(change) => {
                            if (change.value) {
                                fetchSeason(change.value);
                            }
                        }}
                        options={options}
                        defaultValue={options[0]}
                    />
                </div>
            )}
            {notFound && <div className="my-2">There are no active seasons.</div>}
            {homeParticipant && awayParticipant && homeForm && awayForm && (
                <Banner
                    fixture={null}
                    homeParticipant={{ participant: homeParticipant }}
                    awayParticipant={{ participant: awayParticipant }}
                    participants={seasonParticipants.map(({ id, player: { nickname } }) => ({
                        value: id,
                        label: nickname,
                    }))}
                    stats={stats}
                    onHomeParticipantChange={updateHomeParticipant}
                    onAwayParticipantChange={updateAwayParticipant}
                />
            )}
            {stats && seasonStandings.length > 0 && homeParticipant && awayParticipant && (
                <GraphStats
                    homePlayerId={homeParticipant.player.id}
                    awayPlayerId={awayParticipant.player.id}
                    homeSeasonStandings={homeStandings}
                    awaySeasonStandings={awayStandings}
                    totalSeasonParticipants={seasonStandings.length}
                    season={season}
                    sportId={sportId}
                    homeForm={homeForm}
                    awayForm={awayForm}
                    stats={stats}
                />
            )}

            {headToHeadFixtures && headToHeadFixtures.length > 0 && (
                <HeadToHeadTable data={headToHeadFixtures} setActiveTab={setActiveTab} />
            )}

            {(homeLatestFixtures.length > 0 || awayLatestFixtures.length > 0) && (
                <>
                    <div
                        className="p-2 mt-4 gt-bg"
                        style={{
                            color: '#fff',
                        }}
                    >
                        Latest Matches
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ flex: '0 0 50%', borderRight: '2px solid #999' }}>
                            {homeLatestFixtures.length > 0 && <CompactTable data={homeLatestFixtures} />}
                        </div>
                        <div style={{ flex: '0 0 50%' }}>
                            {awayLatestFixtures.length > 0 && <CompactTable data={awayLatestFixtures} />}
                        </div>
                    </div>
                </>
            )}

            {(homeUpcomingFixtures.length > 0 || awayUpcomingFixtures.length > 0) && (
                <>
                    <div
                        className="p-2 mt-4 gt-bg"
                        style={{
                            color: '#fff',
                        }}
                    >
                        Upcoming Matches
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ flex: '0 0 50%', borderRight: '2px solid #999' }}>
                            {homeUpcomingFixtures.length > 0 && <CompactTable data={homeUpcomingFixtures} />}
                        </div>
                        <div style={{ flex: '0 0 50%' }}>
                            {awayUpcomingFixtures.length > 0 && <CompactTable data={awayUpcomingFixtures} />}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default observer(HeadToHead);
