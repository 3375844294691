import { esportsApi } from 'helpers/Api';
import { action, computed, decorate, observable } from 'mobx';

class Common {
    sports = [];

    constructor() {
        esportsApi.getList({ orderBy: 'order', orderDirection: 'asc' }, 'sports').then(
            action((sports) => {
                this.setSports(sports);
            })
        );
    }

    get activeSports() {
        return this.sports.filter((sport) => sport.active);
    }

    setSports(value) {
        this.sports = value;
    }
}

decorate(Common, {
    sports: observable,
    activeSports: computed,
    setSports: action.bound,
});

export default Common;
