import React from 'react';
import _get from 'lodash/get';
import { toAbsoluteUrl } from 'helpers/utils';

const CategoryCrest = ({ data }) => (
    <div>
        <img
            width="25"
            src={
                _get(data, 'season.tournament.category.crest')
                    ? _get(data, 'season.tournament.category.crest')
                    : toAbsoluteUrl('/media/pages/sportstree/flags/flag_default.jpg')
            }
            alt="Category Crest"
        />
    </div>
);

export default CategoryCrest;
