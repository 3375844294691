/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
// import en from 'i18n-iso-countries/lang/en';
import App from './app/App';
import GlobalContext from 'context';
import './tailwind.generated.css';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from './_metronic/layout';
import { MetronicI18nProvider } from './_metronic/i18n';
import { configure } from 'mobx';
import { hydrate } from 'stores/mobx/hydrate';
import { auth } from 'app/modules/Auth/auth.store';
import { sportstree } from 'pages/sportstree/store';
import { getTimeZone } from 'helpers/dates';
import * as mobx from 'stores/mobx';
import 'mobx-react-lite/batchingForReactDom';
import { observe } from 'mobx';
import config from 'config';

moment.tz.setDefault(getTimeZone());

countries.registerLocale(en);

configure({
    // enforceActions: 'always',
});

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
mobx.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
mobx.setupAxios(axios);

/**
 * Observe auth store and set API URL based on user's role.
 */
observe(auth, 'user', (change) => {
    if (change.newValue) {
        config.api_url = process.env.REACT_APP_API_URL_RW;
    } else {
        config.api_url = process.env.REACT_APP_API_URL_RO;
    }
});

hydrate('sportstree', sportstree).then(() => {
    hydrate('auth', auth).then(() => {
        ReactDOM.render(
            <MetronicI18nProvider>
                <MetronicLayoutProvider>
                    <MetronicSubheaderProvider>
                        <MetronicSplashScreenProvider>
                            <GlobalContext.Provider value={{ countries }}>
                                <App basename={PUBLIC_URL} />
                            </GlobalContext.Provider>
                        </MetronicSplashScreenProvider>
                    </MetronicSubheaderProvider>
                </MetronicLayoutProvider>
            </MetronicI18nProvider>,
            document.getElementById('root')
        );
    });
});
