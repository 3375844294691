/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { useAuthStore } from 'app/modules/Auth/auth.store';
import { observer } from 'mobx-react-lite';

function Routes() {
    const { user } = useAuthStore();
    const isAuthorized = user !== null;

    return (
        <Switch>
            {!isAuthorized ? (
                <Route path="/auth">
                    <AuthPage />
                </Route>
            ) : (
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {/* {!isAuthorized ? (
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )} */}

            <Layout>
                <BasePage />
            </Layout>
        </Switch>
    );
}

export default observer(Routes);
