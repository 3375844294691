import React from 'react';
import _get from 'lodash/get';
import { isValidUrl, toAbsoluteUrl } from 'helpers/utils';

const Crest = ({ participant, margin }) => {
    return _get(participant, 'participant.team.crest') && isValidUrl(participant.participant.team.crest) ? (
        <img style={{ margin: margin !== undefined ? margin : 5 }} alt="team" src={participant.participant.team.crest} />
    ) : (
        <img
            style={{ margin: margin !== undefined ? margin : 5 }}
            alt="team"
            src={toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png')}
        />
    );
};

export default Crest;
