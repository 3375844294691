import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Sports from 'pages/sportstree/sports';
import Categories from 'pages/sportstree/categories';
import Tournaments from 'pages/sportstree/tournaments';
import Seasons from 'pages/sportstree/seasons';
import SeasonDetails from 'pages/sportstree/seasondetails';
import Fixtures from 'pages/sportstree/fixtures';
import Participants from 'pages/sportstree/participants';
import Standings from 'pages/sportstree/standings';
// import Knockouts from 'pages/sportstree/knockouts';
import Navigation from 'pages/sportstree/Navigation';
import Breadcrumbs from 'components/Breadcrumbs';
import { useSportsTreeStore } from 'pages/sportstree/store';

const SportsTree = ({ match }) => {
    const store = useSportsTreeStore();

    return (
        <>
            <Navigation depth={store.depth} urls={store.urlByDepth} />
            <Breadcrumbs items={store.breadcrumbsItems} />
            <Switch>
                <Redirect exact from={match.url} to={`${match.url}/sports`.replace('//', '/')} />
                <Route exact path={`${match.url}/sports`} component={Sports} />
                <Route exact path={`${match.url}/sports/:sportId/categories`} component={Categories} />
                <Route exact path={`${match.url}/categories/:categoryId/tournaments`} component={Tournaments} />
                <Route exact path={`${match.url}/tournaments/:tournamentId/seasons`} component={Seasons} />
                <Route exact path={`${match.url}/seasons/:seasonId`} component={SeasonDetails} />
                <Route exact path={`${match.url}/seasons/:seasonId/fixtures`} component={Fixtures} />
                <Route exact path={`${match.url}/seasons/:seasonId/participants`} component={Participants} />
                <Route exact path={`${match.url}/seasons/:seasonId/standings`} component={Standings} />
                {/* <Route exact path={`${match.url}/knockouts`} component={Knockouts} /> */}
            </Switch>
        </>
    );
};

export default observer(SportsTree);
