import React from 'react';
import Fixtures from 'pages/sportstree/fixtures';
import Status from 'pages/sportstree/fixtures/Status';
import DatesPagination from 'components/DatesPagination';
import moment from 'moment-timezone';
import Helmet from 'react-helmet';

const PastResults = () => {
    const [kickoffDate, setKickoffDate] = React.useState(moment());

    return (
        <div>
            <Helmet>
                <title>GT Sports - Past Results</title>
                <meta
                    name="description"
                    content="Easily review past games results. We offer current day results and easy access to the results of the past five days."
                />
                <meta property="og:title" content="GT Sports - Past Results" />
                <meta
                    property="og:description"
                    content="Easily review past games results. We offer current day results and easy access to the results of the past five days."
                />
                <meta property="og:url" content="https://gtleagues.com/past-results" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports past five days results" />
            </Helmet>
            <DatesPagination
                onChange={(value) => {
                    setKickoffDate(value);
                }}
            />
            <Fixtures
                key={kickoffDate.toISOString()}
                title={`Past Results: ${kickoffDate.format('DD MMM YYYY')}`}
                hasSport
                hasFiltering={false}
                hasSelection={false}
                isSporstree={false}
                defaultPageSize={50}
                disableEdit={true}
                defaultStatusFilter={[
                    String(Status.ENDED),
                    String(Status.POSTPONED),
                    String(Status.CANCELLED),
                    String(Status.FORFEIT),
                ]}
                defaultKickoffFilter={kickoffDate.toDate()}
                defaultKickoffOrderDirection="desc"
                defaultMatchNrOrderDirection="desc"
                detailPanel={null}
                disableActions={true}
            />
        </div>
    );
};

export default PastResults;
