import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import * as Styled from 'components/common/mediamanager/styles';
import Thumb from 'components/common/mediamanager/thumb.react';

export default function Dropzone({ maxSize, acceptedFileTypes, multiple }) {
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles = acceptedFiles.filter((file) => {
                return !files.find((f) => {
                    return f.name === file.name && f.size === file.size && f.type === file.type;
                });
            });
            setFiles([...files, ...acceptedFiles]);
        },
        onDropRejected: (rej) => console.log('rejected', rej),
        maxSize: maxSize ? maxSize * 1024 : undefined,
        accept: acceptedFileTypes,
        multiple: multiple,
    });

    const removeFile = (fileName) => {
        const newFiles = files.filter((file) => file.name !== fileName);
        setFiles(newFiles);
    };

    return (
        <>
            <div>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Styled.Container>Drag n drop some files here, or click to select files</Styled.Container>
                </div>
                <Styled.ThumbsContainer>
                    {files.map((file) => (
                        <Thumb key={file.name} removeFile={removeFile} file={file} />
                    ))}
                </Styled.ThumbsContainer>
            </div>
        </>
    );
}

Dropzone.propTypes = {
    cloudFiles: PropTypes.array,
    maxSize: PropTypes.number,
    acceptedFileTypes: PropTypes.array,
    multiple: PropTypes.bool,
};

Dropzone.defaultProps = {
    cloudFiles: [],
    multiple: false,
};
