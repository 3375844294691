/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { useAuthStore } from 'app/modules/Auth/auth.store';

export function AsideMenuList({ layoutProps }) {
    const auth = useAuthStore();
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? ' menu-item-active menu-item-open ' : '';
    };

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item ${getMenuItemActive('/dashboard')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/dashboard')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/dashboard_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/dashboard_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/past-results')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/past-results">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/past-results')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/todays_res_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/todays_res_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">Past Results</span>
                    </NavLink>
                </li>
                {!!auth.user && auth.user.role === 'admin' && (
                    <li className={`menu-item ${getMenuItemActive('/users')}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/users">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={
                                        checkIsActive(location, '/users')
                                            ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/players_on.svg')
                                            : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/players_off.svg')
                                    }
                                />
                            </span>
                            <span className="menu-text">Users</span>
                        </NavLink>
                    </li>
                )}
                {!!auth.user && (
                    <li className={`menu-item ${getMenuItemActive('/sports-tree')}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/sports-tree">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={
                                        checkIsActive(location, '/sports-tree')
                                            ? toAbsoluteUrl(
                                                  '/media/pages/sportstree/sidebar_menu_btns/sports_tree_on.svg'
                                              )
                                            : toAbsoluteUrl(
                                                  '/media/pages/sportstree/sidebar_menu_btns/sports_tree_off.svg'
                                              )
                                    }
                                />
                            </span>
                            <span className="menu-text">Sports Tree</span>
                        </NavLink>
                    </li>
                )}
                <li className={`menu-item ${getMenuItemActive('/current-seasons')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/current-seasons">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/current-seasons')
                                        ? toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/current_seasons_on.svg'
                                          )
                                        : toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/current_seasons_off.svg'
                                          )
                                }
                            />
                        </span>
                        <span className="menu-text">Current Seasons</span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/standings')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/standings">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/standings')
                                        ? toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_on.svg'
                                          )
                                        : toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_off.svg'
                                          )
                                }
                            />
                        </span>
                        <span className="menu-text">Standings</span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/global-ranking')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/global-ranking">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/global-ranking')
                                        ? toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_on.svg'
                                          )
                                        : toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_off.svg'
                                          )
                                }
                            />
                        </span>
                        <span className="menu-text">Global Ranking</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/players')}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/players">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/players')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/players_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/players_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">Players</span>
                        <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Players</span>
                                </span>
                            </li>

                            {!!auth.user && (
                                <li
                                    className={`menu-item menu-item-submenu ${getMenuItemActive('/players/list')}`}
                                    aria-haspopup="true"
                                    data-menu-toggle="hover"
                                >
                                    <NavLink className="menu-link menu-toggle" to="/players/list">
                                        <i className="menu-bullet menu-bullet-dot">
                                            <span />
                                        </i>
                                        <span className="menu-text">List</span>
                                    </NavLink>
                                </li>
                            )}

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <NavLink className="menu-link menu-toggle" to="/players/profiles">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Profiles</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className={`menu-item ${getMenuItemActive('/head-to-head')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/head-to-head">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/head-to-head')
                                        ? toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_on.svg'
                                          )
                                        : toAbsoluteUrl(
                                              '/media/pages/sportstree/sidebar_menu_btns/global_ranking_off.svg'
                                          )
                                }
                            />
                        </span>
                        <span className="menu-text">Head to Head</span>
                    </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive('/about-us')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/about-us">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/about-us')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/about_us_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/about_us_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">About Us</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item menu-item-submenu ${
                        getMenuItemActive('/game-rules') ||
                        getMenuItemActive('/tournament-structure') ||
                        getMenuItemActive('/coordination-with-esic') ||
                        getMenuItemActive('/credibility-of-the-game')
                    }`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <a className="menu-link menu-toggle">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/game-rules') ||
                                    checkIsActive(location, '/tournament-structure') ||
                                    checkIsActive(location, '/coordination-with-esic') ||
                                    checkIsActive(location, '/credibility-of-the-game')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/game_rules_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/game_rules_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">About the Game</span>
                        <i className="menu-arrow" />
                    </a>
                    <div className="menu-submenu ">
                        <ul className="menu-subnav">
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/game-rules')}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink className="menu-link menu-toggle" to="/game-rules">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Game Rules</span>
                                </NavLink>
                            </li>
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/tournament-structure')}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink className="menu-link menu-toggle" to="/tournament-structure">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Tournament Structure</span>
                                </NavLink>
                            </li>
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    '/credibility-of-the-game'
                                )}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink className="menu-link menu-toggle" to="/credibility-of-the-game">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Credibility of the Game</span>
                                </NavLink>
                            </li>
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    '/coordination-with-esic'
                                )}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink className="menu-link menu-toggle" to="/coordination-with-esic">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Coordination With ESIC</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
                {!!auth.user && (
                    <li className={`menu-item ${getMenuItemActive('/bonus-system')}`} aria-haspopup="true">
                        <NavLink className="menu-link" to="/bonus-system">
                            <span className="svg-icon menu-icon">
                                <SVG
                                    src={
                                        checkIsActive(location, '/bonus-system')
                                            ? toAbsoluteUrl(
                                                  '/media/pages/sportstree/sidebar_menu_btns/bonus_system_on.svg'
                                              )
                                            : toAbsoluteUrl(
                                                  '/media/pages/sportstree/sidebar_menu_btns/bonus_system_off.svg'
                                              )
                                    }
                                />
                            </span>
                            <span className="menu-text">Bonus System</span>
                        </NavLink>
                    </li>
                )}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive('/live-stream')}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <a className="menu-link menu-toggle">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/dashboard_off.svg')} />
                        </span>
                        <span className="menu-text">Live Streaming</span>
                        <i className="menu-arrow" />
                    </a>
                    <div className="menu-submenu ">
                        <ul className="menu-subnav">
                            <li
                                className={`menu-item menu-item-submenu ${getMenuItemActive('/live-stream')}`}
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                            >
                                <NavLink className="menu-link menu-toggle" to="/live-stream">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Live TV</span>
                                </NavLink>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv1"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 1</span>
                                </a>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv2"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 2</span>
                                </a>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv3"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 3</span>
                                </a>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv4"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 4</span>
                                </a>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv5"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 5</span>
                                </a>
                            </li>

                            <li className={`menu-item menu-item-submenu`} aria-haspopup="true" data-menu-toggle="hover">
                                <a
                                    className="menu-link menu-toggle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.twitch.tv/gt_sports_tv6"
                                >
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">GT Channel 6</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li className={`menu-item ${getMenuItemActive('/contact-us')}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/contact-us">
                        <span className="svg-icon menu-icon">
                            <SVG
                                src={
                                    checkIsActive(location, '/contact-us')
                                        ? toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/contact_on.svg')
                                        : toAbsoluteUrl('/media/pages/sportstree/sidebar_menu_btns/contact_off.svg')
                                }
                            />
                        </span>
                        <span className="menu-text">Contact Us</span>
                    </NavLink>
                </li>
            </ul>
        </>
    );
}
