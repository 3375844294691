import React from 'react';
import { esportsApi } from 'helpers/Api';
import PlayerCard from 'components/PlayerCard';
import Helmet from 'react-helmet';

const PhotoView = () => {
    const [players, setPlayers] = React.useState([]);

    React.useEffect(() => {
        esportsApi.getList({ active: true }, 'players').then((response) => {
            setPlayers(response);
        });
    }, []);

    return (
        <div className="flex flex-wrap">
            <Helmet>
                <title>GT Sports - Player Profiles</title>
                <meta
                    name="description"
                    content="List of all GTSports Leagues players. Get details for your favorite players.
"
                />
                <meta property="og:title" content="GT Sports - Player Profiles" />
                <meta
                    property="og:description"
                    content="List of all GTSports Leagues players. Get details for your favorite players.
"
                />
                <meta property="og:url" content="https://gtleagues.com/players/profiles" />
                <meta
                    name="keywords"
                    content="gtsports gtleagues esports e-gaming e-sports player profiles favorite players"
                />
            </Helmet>
            {players.map((p, index) => (
                <PlayerCard player={p} index={index} key={p.id} />
            ))}
        </div>
    );
};

export default PhotoView;
