import React, { useEffect, useState, useMemo } from 'react';
import _get from 'lodash/get';
import { esportsApi } from 'helpers/Api';

const Results = ({ sportId, fixture }) => {
    const [availableStats, setAvailableStats] = useState([]);

    useEffect(() => {
        esportsApi.getOne(sportId, 'sports').then((response) => {
            setAvailableStats(response.tag.availableStats);
        });
    }, [sportId]);

    const stats = useMemo(() => {
        let reduced = Array.from(
            availableStats
                .filter(({ active = true }) => active)
                .sort(({ order: orderA = 999 }, { order: orderB = 999 }) => orderA - orderB)
                .reduce((carry, item) => {
                    const mapKey = item.key.replace('home', '$side').replace('away', '$side');

                    if (!carry.has(mapKey)) {
                        carry.set(mapKey, {});
                    }

                    if (item.key.includes('home')) {
                        carry.get(mapKey).home = _get(fixture, `result.stats.${item.key}`, '-');
                    } else if (item.key.includes('away')) {
                        carry.get(mapKey).away = _get(fixture, `result.stats.${item.key}`, '-');
                    }

                    // TODO: Write a regex for that
                    carry.get(mapKey).label = item.name
                        .replace('Home ', '')
                        .replace('Away ', '')
                        .replace(' Home', '')
                        .replace(' Away', '');

                    return carry;
                }, new Map())
        );

        if (Array.isArray(reduced) && reduced.length > 0) {
            return reduced.map(([_, element]) => element);
        }

        return [];
    }, [availableStats, fixture]);

    return (
        <div className="flex flex-col p-8">
            {stats.map((stat) => (
                <div className="flex flex-row items-center justify-between" key={stat.label}>
                    <span className="gradient-color">{stat.home}</span>
                    <span className="mx-4 uppercase">{stat.label}</span>
                    <span className="gradient-color">{stat.away}</span>
                </div>
            ))}
        </div>
    );
};

export default Results;
