import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const TVLink = ({ channel }) => {
    return (
        <Tooltip title={channel}>
            <a className="relative inline-block" onClick={(e) => e.stopPropagation()} href={`/${channel}`}>
                <span className="absolute w-full pt-1 text-xs font-bold text-center">{channel}</span>
                <i className="text-3xl fas fa-tv gradient-color"></i>
            </a>
        </Tooltip>
    );
};

export default TVLink;
