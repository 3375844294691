/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import 'react-circular-progressbar/dist/styles.css';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export default function App({ basename }) {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 60 * 5,
            },
        },
    });

    return (
        <React.Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
                <MaterialThemeProvider>
                    <I18nProvider>
                        <QueryClientProvider client={queryClient}>
                            <Routes />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </I18nProvider>
                </MaterialThemeProvider>
            </BrowserRouter>
        </React.Suspense>
    );
}
