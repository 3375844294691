import React from 'react';
import Table from 'pages/fixture/Table';
import Tabs from 'components/Tabs';

const HeadToHead = ({ data, setActiveTab }) => {
    return (
        <>
            <div
                className="p-2 gt-bg"
                style={{
                    color: '#fff',
                }}
            >
                Head to Head Record
            </div>
            <div>
                <Tabs
                    variant="fullWidth"
                    onChangeCb={(newValue) => {
                        setActiveTab(newValue);
                    }}
                    items={[
                        {
                            label: 'Latest Matches',
                            render: () => <Table data={data} />,
                        },
                        {
                            label: 'Current Season',
                            render: () => <Table data={data} />,
                        },
                    ]}
                ></Tabs>
            </div>
        </>
    );
};

export default HeadToHead;
