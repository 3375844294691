import React from 'react';
import Async from 'react-select/async';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

const getFieldCSSClasses = (touched, errors) => {
    const classes = [];
    if (touched && errors) {
        classes.push('is-invalid');
    }

    if (touched && !errors) {
        classes.push('is-valid');
    }

    return classes.join(' ');
};

const AsyncSelectCompponent = ({ loadOptions, ...props }) => {
    const {
        field,
        form: { touched, errors, setFieldValue, setFieldTouched },
        label,
        withFeedbackLabel = true,
        customFeedbackLabel,
        defaultInputValue = '',
        ...rest
    } = props;

    return (
        <>
            {label && <label>{label}</label>}
            <Async
                defaultInputValue={defaultInputValue}
                className={getFieldCSSClasses(touched, errors[field.name])}
                defaultOptions
                cacheOptions
                loadOptions={loadOptions}
                onChange={(selected) => {
                    let value;

                    if (Array.isArray(selected)) {
                        value = selected;
                    } else if (!selected) {
                        value = [];
                    } else {
                        value = selected.value;
                    }

                    setFieldValue(field.name, value);
                }}
                onBlur={() => setFieldTouched(field.name, true)}
                {...rest}
            />
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched}
                    label={label}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
};

AsyncSelectCompponent.displayName = 'Form.AsyncSelect';

export default AsyncSelectCompponent;
