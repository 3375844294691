import React, { useEffect, useState } from 'react';
import List from 'components/List';
import Api from 'helpers/Api';
import StandingsLegend from 'components/StandingsLegend';
import AsyncInput from 'components/AsyncInput';
import Helmet from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { standingSort } from 'pages/currentseasons/Standings';
import { getFields, calcStandings, rowStyle } from 'helpers/standings';

const customSorter = (a, b) => {
    if (a.name.toLowerCase().includes('draft')) {
        return 1;
    }
    if (b.name.toLowerCase().includes('draft')) {
        return -1;
    }

    return 0;
};

function Standings() {
    const [season, setSeason] = useState(null);
    const [seasons, setSeasons] = useState([]);
    const [data, setData] = useState(null);
    const [tagId, setTagId] = useState(null);

    useEffect(() => {
        new Api('seasons').getList({ xtc: false, status: 1 }).then((response) => {
            setSeasons((response || []).sort(customSorter));
            if (response.length > 0) {
                setSeason({ value: response[0].id, label: `${response[0].name} (${response[0].tournament.name})` });
            }
        });
    }, []);

    useEffect(() => {
        if (season) {
            const s = seasons.find((s) => s.id === season.value);
            new Api(`seasons/${season.value}/standings`).getList({ xtc: false }).then((response) => {
                setData(response.data.map(calcStandings(s.tournament.category.sport.id)));
                setTagId(response.tagId);
            });
        }
    }, [season, seasons]);

    const loadOptions = React.useCallback((inputValues) => {
        return new Api('seasons')
            .getList({
                q: inputValues,
                status: 1,
                // orderBy: 'name', orderDirection: 'DESC'
            })
            .then((seasons) => {
                return seasons
                    .sort(customSorter)
                    .map((s) => ({ value: s.id, label: `${s.name} (${s.tournament.name})` }));
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>GT Sports - Standings</title>
                <meta
                    name="description"
                    content="Seasons standings for your favorite tournaments at a glance. Review season player performance and advanced player statistics."
                />
                <meta property="og:title" content="GT Sports - Standings" />
                <meta
                    property="og:description"
                    content="Seasons standings for your favorite tournaments at a glance. Review season player performance and advanced player statistics."
                />
                <meta property="og:url" content="https://gtleagues.com/current-seasons" />
                <meta
                    name="keywords"
                    content="gtsports gtleagues esports e-gaming e-sports active season standings player performance"
                />
            </Helmet>
            <div className="mb-2">
                <AsyncInput
                    key={season && season.value}
                    defaultValue={season}
                    onChange={(selected) => {
                        setSeason(selected);
                    }}
                    placeholder="Current Seasons"
                    loadOptions={loadOptions}
                />
            </div>
            {data && season && (
                <React.Fragment>
                    <List
                        data={data}
                        hasSelection={false}
                        hasPagination={false}
                        hasSearch={false}
                        hasFiltering={false}
                        title={`${seasons.find((s) => s.id === season.value).tournament.name} Season Standings`}
                        options={{
                            rowStyle: rowStyle(true, data),
                        }}
                        fields={getFields(tagId).map((field) => ({
                            ...field,
                            filtering: false,
                            emptyValue: '-',
                            customSort: standingSort(field),
                        }))}
                    />
                    <StandingsLegend />
                </React.Fragment>
            )}
        </>
    );
}

export default observer(Standings);
