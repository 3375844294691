import React from 'react';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control', 'form-control-solid'];
    if (touched && errors) {
        classes.push('is-invalid-select');
    }

    if (touched && !errors) {
        classes.push('is-valid-select');
    }

    return classes.join(' ');
};

export default function SelectInput({
    field,
    form: { touched, errors },
    label,
    withFeedbackLabel = true,
    type = 'text',
    customFeedbackLabel,
    children,
    options = [],
    ...props
}) {
    return (
        <>
            {label && <label>{label}</label>}
            <select className={getFieldCSSClasses(touched, errors)} {...field} {...props}>
                {field.value === '' && <option value="">Select an option</option>}
                {children}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {withFeedbackLabel && !props.disabled && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched}
                    label={label}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
