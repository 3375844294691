import React from 'react';
import { Popover } from '@material-ui/core';

export const useStatsPopover = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return { anchorEl, setAnchorEl, handleClick, handleClose };
};

const StatsPopover = ({ anchorEl, handleClose, children }) => {
    const open = Boolean(anchorEl);
    const id = open ? 'stats-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            PaperProps={{
                style: {
                    borderRadius: 0,
                    padding: 50,
                    width: '100%',
                },
            }}
        >
            {children}
        </Popover>
    );
};

export default StatsPopover;
