import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MediaContext } from 'context/media';
import * as Styled from './styles';

class File extends Component {
    static contextType = MediaContext;

    handleRemove = () => {
        const { file } = this.props;
        const { setFiles } = this.context;

        setFiles(file);
    };

    render() {
        const { file } = this.props;
        return (
            <Styled.Wrapper
                style={{
                    backgroundImage: file.type === 'image' ? `url(${file.url})` : null,
                    backgroundColor: file.type !== 'image' ? 'rgba(9, 30, 66, 0.3)' : null
                }}
            >
                <Styled.RemoveIcon className="fa fa-times-circle" onClick={this.handleRemove} />
                <Styled.Title>{file.name}</Styled.Title>
            </Styled.Wrapper>
        );
    }
}

File.propTypes = {
    file: PropTypes.object
};

export default File;
