import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '_metronic/layout';
import { useAuthStore } from 'app/modules/Auth/auth.store';

const Logout = () => {
    const { logout, token } = useAuthStore();

    useEffect(() => {
        logout();
    }, [logout]);

    return !!token ? <LayoutSplashScreen /> : <Redirect to="/" />;
};

export default Logout;
