export const pick = (props) => (object) =>
    props.reduce((carry, property) => ({ ...carry, [property]: object[property] }), {});

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

/**
 *
 * @param {Object[]} participants - Fixture's participants
 * @returns {Object[]}
 */

export const homeAwayParticipants = (participants) => {
    return [participants.find((p) => p.side === 'home'), participants.find((p) => p.side === 'away')];
};

/**
 *
 * @param {String} input
 * @returns {Boolean}
 */
export const isValidUrl = (input) => {
    try {
        new URL(input);
    } catch (_) {
        return false;
    }

    return true;
};

export function validateNum(event) {
    let theEvent = event || window.event;

    let key;

    if (theEvent.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
    } else {
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }

    let regex = /[0-9]|\./;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}

export function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

export const loadJS = (url, callback, location) => {
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = callback;
    scriptTag.onreadystatechange = callback;

    location.appendChild(scriptTag);
};

export const createCachedCall = (cb = () => {}, timeout = 0) => {
    const cache = {};
    return async (deps = []) => {
        let now = Date.now();
        let cacheKey = JSON.stringify(deps);
        if (cache[cacheKey] && timeout > now - cache[cacheKey].previousTime) {
            return cache[cacheKey].data;
        } else {
            cache[cacheKey] = {};
            cache[cacheKey].data = await cb();
            cache[cacheKey].previousTime = Date.now();
            return cache[cacheKey].data;
        }
    };
};
