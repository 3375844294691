import React from 'react';
import { Route } from 'react-router-dom';
import Table from './table';
import PhotoView from './photoview';
import { useAuthStore } from 'app/modules/Auth/auth.store';

const Players = ({ match }) => {
    const auth = useAuthStore();

    return (
        <>
            {/* <Redirect exact={true} from={`/players`} to={`${match.path}/table-view`} /> */}
            {!!auth.user && <Route path={`${match.path}/list`} component={Table} />}
            <Route path={`${match.path}/profiles`} component={PhotoView} />
        </>
    );
};

export default Players;
