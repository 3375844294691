import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const EditAction = ({ handleClick }) => (
    <IconButton
        onClick={(event) => {
            event.stopPropagation();
            handleClick();
        }}
    >
        <EditIcon />
    </IconButton>
);

export default EditAction;
