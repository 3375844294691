import React from 'react';
import _get from 'lodash/get';
import { toAbsoluteUrl, isValidUrl } from 'helpers/utils';

const Team = ({ participant, teamName }) => {
    return (
        <div className="flex flex-column flex-center">
            {_get(participant, 'participant.team.crest') && isValidUrl(participant.participant.team.crest) ? (
                <img alt="team" src={participant.participant.team.crest} style={{ width: 20, marginBottom: 5 }} />
            ) : (
                <img
                    alt="team"
                    src={toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png')}
                    style={{ width: 20, marginBottom: 5 }}
                />
            )}
            {teamName}
        </div>
    );
};

export default Team;
