import React from 'react';
import Api from 'helpers/Api';
import { action, decorate } from 'mobx';

export default class FixturePage {
    constructor() {
        this.fixturesApi = new Api('fixtures');
    }

    fetchFixture(id) {
        return this.fixturesApi.getOne(id);
    }
}

decorate(FixturePage, {
    fetchFixture: action.bound,
});

export const fixturePage = new FixturePage();

const storeContext = React.createContext(fixturePage);

export const useFixturePageStore = () => React.useContext(storeContext);
