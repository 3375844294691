import React from 'react';
import _get from 'lodash/get';

const Score = ({ data }) => {
    return (
        <>
            <span className="font-bold" style={{ color: '#4A7DFF' }}>
                {_get(data, 'result.stats.home_score')} - {_get(data, 'result.stats.away_score')}
            </span>
        </>
    );
};

export default Score;
