import React, { useEffect, useMemo, useState } from 'react';
import Chip from 'components/Chip';
import List from 'components/List';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import Team from 'pages/sportstree/fixtures/Team';
import Status from 'pages/sportstree/fixtures/Status';
import CategoryCrest from 'components/CategoryCrest';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import TVLink from 'components/TVLink';
import { esportsApi } from 'helpers/Api';
import { useQuery } from 'react-query';

const availableStatuses = [Status.LIVE, Status.NOT_STARTED];

function UpcomingMatches({ activeSport, activeCategory, activeStatus, date }) {
    const store = useSportsTreeStore();
    const [data, setData] = useState([]);
    const history = useHistory();

    const mode = useMemo(() => {
        return activeCategory ? 'categoryFixtures' : activeSport ? 'sportFixtures' : 'allFixtures';
    }, [activeCategory, activeSport]);

    const apiPath = useMemo(() => {
        return activeCategory
            ? `categories/${activeCategory.value}/fixtures`
            : activeSport
            ? `sports/${activeSport.value}/fixtures`
            : 'fixtures';
    }, [activeCategory, activeSport]);

    const query = useQuery(['UpcomingMatchesFixtures', mode, activeStatus, date.toISOString()], () => {
        const startISO = date.clone().startOf('day').toISOString();
        const endISO = date.clone().endOf('day').toISOString();

        const params = {
            limit: 1000,
            filters: [
                activeStatus !== null
                    ? { field: 'status', operator: 'eq', value: activeStatus }
                    : {
                          field: 'status',
                          operator: 'in',
                          value: availableStatuses,
                      },
                {
                    field: 'kickoff',
                    operator: 'between',
                    value: `${startISO},${endISO}`,
                },
            ],
            orderBy: ['kickoff', 'matchNr'],
            orderDirection: ['asc', 'asc'],
        };

        return esportsApi.getList(params, apiPath);
    });

    useEffect(() => {
        if (!query.isLoading) {
            store.updateResources('fixtures', query.data);

            let d = fixtureHelpers.mapResponse({ timeOnly: true })({
                data: query.data,
            });

            setData(d.data);
        }
    }, [store, query.data, query.isLoading]);

    return (
        <>
            {query.isLoading ? (
                <div className="max-w-screen-sm min-h-screen m-4 mx-auto text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <List
                    hasPagination={false}
                    hasSelection={false}
                    hasSearch={false}
                    hasFiltering={false}
                    onRowClick={(event, rowData) => {
                        history.push(`/fixtures/${rowData.id}`);
                    }}
                    options={{
                        headerStyle: {
                            padding: '10px',
                        },
                        cellStyle: {
                            padding: '10px',
                        },
                    }}
                    title={''}
                    data={data}
                    fields={[
                        { field: 'kickoff', title: 'Time', width: '1%' },
                        { field: 'matchNr', title: '#', width: '1%' },
                        !apiPath.includes('sports')
                            ? {
                                  field: 'season.tournament.category.sport.name',
                                  title: 'Sport',
                                  sorting: false,
                                  width: '1%',
                              }
                            : null,
                        {
                            field: 'category',
                            title: 'Cat.',
                            width: '1%',
                            sorting: false,
                            render: (rowData) => <CategoryCrest data={rowData} />,
                        },
                        { field: 'week', title: 'Wk', width: '1%' },
                        // { field: 'player1', title: 'Player 1', sorting: false, width: '1%' },
                        // { field: 'player2', title: 'Player 2', sorting: false, width: '1%' },
                        {
                            field: 'homeTeam',
                            title: 'Home',
                            sorting: false,
                            headerStyle: {
                                textAlign: 'center',
                            },
                            cellStyle: {
                                textAlign: 'center',
                            },
                            render: (rowData) => (
                                <>
                                    <Team
                                        participant={rowData.participants.find((p) => p.side === 'home')}
                                        teamName={rowData.homeTeam}
                                    />
                                    <strong>{rowData.player1}</strong>
                                </>
                            ),
                        },
                        {
                            field: 'awayTeam',
                            title: 'Away',
                            headerStyle: {
                                textAlign: 'center',
                            },
                            cellStyle: {
                                textAlign: 'center',
                            },
                            sorting: false,
                            render: (rowData) => (
                                <>
                                    <Team
                                        participant={rowData.participants.find((p) => p.side === 'away')}
                                        teamName={rowData.awayTeam}
                                    />
                                    <strong>{rowData.player2}</strong>
                                </>
                            ),
                        },
                        {
                            field: 'channel',
                            title: 'TV',
                            width: '1%',
                            render: (rowData) => {
                                if (!rowData.channel) {
                                    return null;
                                }

                                return <TVLink channel={rowData.channel} />;
                            },
                        },
                        {
                            field: 'Stats',
                            title: 'Stats',
                            sorting: false,
                            width: '1%',
                            render: () => {
                                return (
                                    <>
                                        <div>
                                            <i className="far fa-chart-bar"></i>
                                        </div>
                                    </>
                                );
                            },
                        },
                        {
                            field: 'status',
                            title: 'Status',
                            render: (rowData) => (
                                <>
                                    {rowData.status !== undefined && (
                                        <Chip
                                            label={
                                                fixtureHelpers
                                                    .getStatuses()
                                                    .find(({ value }) => value === rowData.status).label
                                            }
                                            color={fixtureHelpers.getStatusesColors(rowData.status).color}
                                            bgColor={fixtureHelpers.getStatusesColors(rowData.status).bgColor}
                                        />
                                    )}
                                </>
                            ),
                        },
                    ].filter(Boolean)}
                    renderToolbar={() => {
                        return (
                            <div className="py-2 bg-white">
                                <div
                                    style={{
                                        margin: 10,
                                        padding: 10,
                                        color: '#fff',
                                        background: 'linear-gradient(90deg, #005DFF 10%, #B52EAC 50%, #FF1758 100%)',
                                    }}
                                >
                                    {date.format('dddd, LL')}
                                </div>
                            </div>
                        );
                    }}
                />
            )}
        </>
    );
}

export default UpcomingMatches;
