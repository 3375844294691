export const getFields = (tagId) => {
    if (tagId === 'soccer') {
        return [
            { field: 'no', title: '#' },
            { field: 'nickname', title: 'Player' },
            { field: 'games_played', title: 'GP' },
            { field: 'points', title: 'PTs' },
            { field: 'wins', title: 'W' },
            { field: 'draws', title: 'D' },
            { field: 'loses', title: 'L' },
            { field: 'goals_total_for', title: 'GF' },
            { field: 'goals_total_against', title: 'GA' },
            { field: 'goals_total_difference', title: 'GD' },
            { field: 'win_rate', title: 'W%' },
            { field: 'draw_rate', title: 'D%' },
            { field: 'loss_rate', title: 'L%' },
            { field: 'goals_for_per_match', title: 'GF/M' },
            { field: 'goals_against_per_match', title: 'GA/M' },
            { field: 'points_per_match', title: 'P/M' },
        ];
    } else if (tagId === 'basketball') {
        return [
            { field: 'no', title: '#' },
            { field: 'nickname', title: 'Player' },
            { field: 'games_played', title: 'GP' },
            { field: 'wins', title: 'W' },
            { field: 'loses', title: 'L' },
            { field: 'win_rate', title: 'PCT' },
            { field: 'points_for_per_match', title: 'PF' },
            { field: 'points_against_per_match', title: 'PA' },
            { field: 'diff', title: 'DIFF' },
            // { field: 'total_for', title: 'PF' },
            // { field: 'total_against', title: 'PA' },
            // { field: 'halftime_total_for', title: 'HPF' },
            // { field: 'halftime_total_against', title: 'HPA' },
            // { field: 'total_difference', title: 'PD' },
            // { field: 'standing_points_per_match', title: 'SPPM' },
        ];
    }

    return [];
};

export const calcStandings = (sportId) => (item, index) => {
    return {
        ...item,
        ...(sportId === 2 && { diff: calcDiff(item.points_total_difference, item.games_played) }),
        no: index + 1,
    };
};

const calcDiff = (totalDifference, gamesPlayed) => {
    const diff = totalDifference / gamesPlayed;
    return isNaN(diff) ? '-' : diff.toFixed(2);
};

export const rowStyle = (showPromotionRelegationAesthetics, data) => (props, index) => {
    const styles = {
        backgroundColor: props.tableData && props.tableData.id % 2 === 0 ? '#f1f1f1' : '#fff',
    };

    if (showPromotionRelegationAesthetics) {
        if (index === 0 || index === 1) {
            styles.borderLeft = '5px solid #3AA856';
        }

        // if (index === data.length - 1 || index === data.length - 2) {
        //     styles.borderLeft = '5px solid #EA4335';
        // }
    }

    return styles;
};
