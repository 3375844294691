import React, { useMemo } from 'react';
import AsyncInput from 'components/AsyncInput';
import { useQueryClient } from 'react-query';
import { esportsApi } from 'helpers/Api';

const CategoriesFilter = ({ sportId, ...rest }) => {
    const queryClient = useQueryClient();

    const mode = useMemo(() => {
        return sportId ? 'sportCategories' : 'allCategories';
    }, [sportId]);

    const apiPath = useMemo(() => {
        return sportId ? `sports/${sportId}/categories` : 'categories';
    }, [sportId]);

    const loadOptions = async (inputValues) => {
        const data = await queryClient.fetchQuery(['categoriesFilter', mode, sportId], () => {
            return esportsApi.getList({ q: inputValues }, apiPath);
        });

        return data.map((s) => ({ value: s.id, label: s.name }));
    };

    return <AsyncInput key={sportId} {...rest} placeholder="All Categories" loadOptions={loadOptions} />;
};

export default CategoriesFilter;
