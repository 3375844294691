import React, { useMemo } from 'react';
import Brackets from 'pages/sportstree/knockouts/brackets';
import { useSportsTreeStore } from 'pages/sportstree/store';
import Form from 'components/Form';
import seasonsHelpers from 'helpers/seasons';
import { useKnockoutsStore } from 'pages/sportstree/knockouts/store';

const Knockouts = () => {
    const { knockout, knockoutApi } = useKnockoutsStore();

    const {
        selectedSeason: { id },
    } = useSportsTreeStore();

    const initialValues = useMemo(() => {
        return {
            id: knockout ? knockout.id : '',
            totalSeeds: knockout ? knockout.totalSeeds : '',
            rankedBasedAllocation: knockout ? knockout.rankedBasedAllocation : '',
            matchupDistribution: knockout ? knockout.matchupDistribution : '',
            eliminationMode: knockout ? knockout.eliminationMode : '',
            totalRounds: knockout ? knockout.totalRounds : '',
            progressionRounds: knockout ? knockout.progressionRounds : '',
            seasonId: id,
        };
    }, [id, knockout]);

    return (
        <div className="bg-white shadow-xl p-2">
            {knockout && (
                <>
                    <h2>Settings</h2>
                    <Form
                        fields={[
                            {
                                name: 'totalSeeds',
                                label: 'Total Seeds',
                                componentType: 'Input',
                                type: 'number',
                                disabled: true,
                            },
                            // {
                            //     name: 'rankedBasedAllocation',
                            //     label: 'Ranked Based Allocation',
                            //     componentType: 'Checkbox',
                            //     disabled: true,
                            // },
                            // {
                            //     name: 'matchupDistribution',
                            //     label: 'Matchup Distribution',
                            //     componentType: 'SelectInput',
                            //     options: seasonsHelpers.getMatchupDistributions(),
                            //     disabled: true,
                            // },
                            {
                                name: 'eliminationMode',
                                label: 'Elimenation Mode',
                                componentType: 'SelectInput',
                                options: seasonsHelpers.getEliminationModes(),
                                disabled: true,
                            },
                            {
                                name: 'totalRounds',
                                label: 'Total Rounds',
                                componentType: 'Input',
                                type: 'number',
                                disabled: true,
                            },
                            knockout.eliminationMode === 2
                                ? {
                                      name: 'progressionRounds',
                                      label: 'Progression Rounds',
                                      componentType: 'Input',
                                      type: 'number',
                                      disabled: true,
                                  }
                                : null,
                        ].filter(Boolean)}
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            const { id, ...data } = values;
                            knockoutApi.update(id, data).then((res) => {
                                setSubmitting(false);
                                resetForm(initialValues);
                            });
                        }}
                    />
                    <h2>Brackets</h2>
                    <div style={{ overflow: 'scroll' }}>
                        <Brackets knockout={knockout} knockoutId={knockout.id} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Knockouts;
