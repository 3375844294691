import React from 'react';
import Helmet from 'react-helmet';

const GameRules = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <Helmet>
                <title>GT Sports - Game Rules</title>
                <meta
                    name="description"
                    content="Read about the supported games, in-game settings, rules of the game and rules of the GTSports studios.
"
                />
                <meta property="og:title" content="GT Sports - Game Rules" />
                <meta
                    property="og:description"
                    content="Read about the supported games, in-game settings, rules of the game and rules of the GTSports studios.
"
                />
                <meta property="og:url" content="https://gtleagues.com/game-rules" />
            </Helmet>
            <table className="table table-dark">
                <thead>
                    <tr>
                        <th scope="col">Game</th>
                        <th scope="col">Console</th>
                        <th scope="col">Game Mode</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>FIFA23</td>
                        <td>
                            <span class="label label-inline label-light-primary font-weight-bold">PS5</span>
                        </td>
                        <td>1 player vs 1 player</td>
                    </tr>
                </tbody>
            </table>

            <p>
                <strong>In-game Settings</strong>
            </p>
            <ul>
                <li>
                    <p>All players must be 18+</p>
                </li>
                <li>
                    <p>2 halves of 6 min. each</p>
                </li>
                <li>
                    <p>Squad rating: 95 for all teams and players</p>
                </li>
                <li>
                    <p>Time of Day: 9pm</p>
                </li>
                <li>
                    <p>Weather: Clear</p>
                </li>
                <li>
                    <p>Pitch Pattern: Default</p>
                </li>
                <li>
                    <p>Pitch Wear: None</p>
                </li>
                <li>
                    <p>Game Speed: Regular</p>
                </li>
                <li>
                    <p>Fast substitutions: Yes</p>
                </li>
                <li>
                    <p>Season: Summer</p>
                </li>
                <li>
                    <p>Camera Settings: Tele Broadcast</p>
                </li>
                <li>
                    <p>Injuries: Off</p>
                </li>
                <li>
                    <p>Offsides: On</p>
                </li>
                <li>
                    <p>Bookings: On</p>
                </li>
                <li>
                    <p>Handball: On(except for penalties)</p>
                </li>
            </ul>
            <p>
                <strong>Game Rules</strong>
            </p>
            <ul>
                <li>
                    <p>
                        Players are not allowed to modify gamepad in-game support settings. These settings are the same
                        for all players.
                    </p>
                </li>
                <li>
                    <p>
                        Players not present at the designated start time for any match will be disqualified, and their
                        opponent shall be granted a 3-0 score. In such a case the game is annotated with the{' '}
                        <strong>FORFEIT</strong> flag. Forfeited games are essentially voided when it comes to betting.
                    </p>
                </li>
                <li>
                    <p>
                        “GT leagues” employs backup players. They are eligible to substitute any player from the league
                        in a given season, in case that the player has to be absent. This always takes place after
                        qualification games. The reason we do that is related to delivering high quality games even if a
                        player has to be absent. Absent players are annotated in the players list.
                    </p>
                </li>
                <li>
                    <p>
                        An interrupted match, due to outside circumstances or disconnection, will be replayed starting
                        from the minute and with the same score it was interrupted at a later date/time decided by GTSL.
                    </p>
                </li>
                <li>
                    <p>
                        If a game is paused or interrupted intentionally by a Player, GTSL has the right to disqualify
                        that Player.
                    </p>
                </li>
            </ul>
            <p>
                <strong>Studio Rules</strong>
            </p>
            <ul>
                <li>
                    <p>Players shall arrive in specific time frames when tournaments take place</p>
                </li>
                <li>
                    <p>Players are not allowed to have their mobile phones inside the premises of GT Leagues</p>
                </li>
                <li>
                    <p>
                        Players are not allowed to communicate with people of the outside world when tournaments take
                        place
                    </p>
                </li>
                <li>
                    <p>Players are not allowed to bring their own gaming equipment (controller etc)</p>
                </li>
                <li>
                    <p>Players can not deny to be part of the livestream</p>
                </li>
                <li>
                    <p>Players are not allowed to leave the premises and come back later when tournaments take place</p>
                </li>
                <li>
                    <p>Players are not allowed to discuss opponents with other players, prior to the games</p>
                </li>
                <li>
                    <p>
                        Players bathroom visits are supervised (an operator accompanies the player to the bathroom and
                        waits outside)
                    </p>
                </li>
                <li>
                    <p>When players are not playing, they shall remain in the resting zones</p>
                </li>
                <li>
                    <p>All studio premises, including resting zones are fully monitored and under surveillance 24/7</p>
                </li>
                <li>
                    <p>
                        At unknown intervals, players are subject to undergo anti-doping checks. Primarily for
                        substances that enhance reflexes.
                    </p>
                </li>
            </ul>
        </div>
    );
};

export default GameRules;
