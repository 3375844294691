import { esportsApi } from 'helpers/Api';
import { action, decorate, observable, observe } from 'mobx';

class FavoriteSeasons {
    constructor(sportstree, auth) {
        this.sportstree = sportstree;
        this.auth = auth;

        observe(this.sportstree, 'seasons', (change) => {
            this.fetchFavoriteSeasons();
        });
    }

    data = observable.array();

    fetchFavoriteSeasons() {
        esportsApi.getList({ xtc: false }, `users/${this.auth.user.id}/favorite-seasons`).then((response) => {
            this.setData(response);
        });
    }

    setData(values) {
        this.data.replace(values);
    }
}

decorate(FavoriteSeasons, {
    data: observable,
    setData: action.bound,
});

export default FavoriteSeasons;
