import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import MuiLink from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(1, 2),
        marginBottom: 25,
    },
}));

export default function SimpleBreadcrumbs({ items }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <Breadcrumbs aria-label="Breadcrumb">
                    {items.map((item) => {
                        if (item.active) {
                            return (
                                <Link key={item.text} to={item.link} color="inherit">
                                    {item.text}
                                </Link>
                            );
                        }

                        return (
                            <Typography key={item.text} color="textPrimary">
                                <strong>{item.text}</strong>
                            </Typography>
                        );
                    })}
                </Breadcrumbs>
            </Paper>
        </div>
    );
}
