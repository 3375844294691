/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from 'react';
import TimeZone from 'components/TimeZone';
import { Tab, Nav } from 'react-bootstrap';
import { useAuthStore } from 'app/modules/Auth/auth.store';

export function QuickPanel() {
    const [selectedTab, setSelectedTab] = useState('Settings');

    const setTab = (_tabName) => {
        setSelectedTab(_tabName);
    };

    const {
        updateTimeZone,
        user: { timezone },
    } = useAuthStore();

    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
            <Tab.Container defaultActiveKey={selectedTab}>
                <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                    <Nav
                        onSelect={setTab}
                        as="ul"
                        role="tablist"
                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
                    >
                        <Nav.Item as="li">
                            <Nav.Link
                                eventKey="Settings"
                                className={`nav-link ${selectedTab === 'Settings' ? 'active' : ''}`}
                            >
                                Settings
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div
                        className="offcanvas-close mt-n1 pr-5"
                        style={{ position: 'absolute', top: '15px', right: '10px' }}
                    >
                        <a
                            href="#"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            id="kt_quick_panel_close"
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                </div>

                <div className="offcanvas-content px-10">
                    <div className="tab-content">
                        <div
                            id="kt_quick_panel_settings"
                            role="tabpanel"
                            className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${
                                selectedTab === 'Settings' ? 'active show' : ''
                            }`}
                        >
                            <form className="form">
                                <div>
                                    <h5 className="font-weight-bold mb-3">Personal</h5>
                                    <div className="form-group mb-0 row">
                                        <label className="col-4 col-form-label">Timezone:</label>
                                        <div className="col-8 text-right">
                                            <TimeZone
                                                onChange={(selected) => {
                                                    updateTimeZone(selected.value);
                                                }}
                                                defaultValue={{ label: timezone, value: timezone }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="separator separator-dashed my-6"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </div>
    );
}
