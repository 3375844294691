import React, { useEffect, useState, useMemo } from 'react';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import MuiTextField from '@material-ui/core/TextField';
import Chip from 'components/Chip';
import List, { prepareParams } from 'components/List';
import EditAction from 'components/List/EditAction';
import ImportButton from 'components/ImportButton';
import request from 'helpers/request';
import config from 'config';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import Api, { esportsApi } from 'helpers/Api';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import UpdateStatus from 'pages/sportstree/fixtures/UpdateStatus';
import UpdateKickoff from 'pages/sportstree/fixtures/UpdateKickoff';
import Team from 'pages/sportstree/fixtures/Team';
import Status from 'pages/sportstree/fixtures/Status';
import Notification from 'components/Notification';
import TVLink from 'components/TVLink';
// import { observer } from 'mobx-react-lite';
import _debounce from 'lodash/debounce';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useAuthStore } from 'app/modules/Auth/auth.store';
import { validateNum } from 'helpers/utils';
import { getTimeZone } from 'helpers/dates';
import * as Yup from 'yup';

let validationSchema = Yup.object().shape({
    matchNr: Yup.number().min(1).max(32767),
    status: Yup.number().min(0).max(10),
    kickoff: Yup.date(),
    week: Yup.number().min(1).max(255), //.required(),
    player1: Yup.number().required(),
    player2: Yup.number().required(),
});

function Fixtures({
    hasSport = false,
    isSporstree = true,
    defaultPageSize = 10,
    disableEdit,
    defaultStatusFilter,
    defaultKickoffFilter,
    defaultKickoffOrderDirection,
    defaultMatchNrOrderDirection,
    disableActions,
    ...rest
}) {
    const { seasonId } = useParams();
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const history = useHistory();
    const store = useSportsTreeStore();
    const fixture = store.getResource('fixtures', selectedResourceId);
    const auth = useAuthStore();
    const [status, setStatus] = useState(null);

    const notify = (variant, message) => {
        setStatus({ key: Math.random(), message, variant });
    };

    useEffect(() => {
        store.refreshTable();
    }, [store, seasonId]);

    useEffect(() => {
        if (store.selectedSeason.id === -1 && isSporstree) {
            history.push(`/sports-tree/sports`);
        }
    }, [history, store, isSporstree]);

    const handleImport = (data) => {
        request(`${config.api_url}/fixtures/import`, {
            method: 'POST',
            body: JSON.stringify({
                data,
                seasonId: store.selectedSeason.id,
            }),
        })
            .then((res) => {
                store.refreshTable();
            })
            .catch((e) => {
                notify('error', e.message);
            });
    };

    const fields = [
        { name: 'matchNr', label: 'Match Number', componentType: 'Input', type: 'number' },
        { name: 'kickoff', label: 'Kickoff', componentType: 'DateTimePicker' },
        {
            name: 'status',
            label: 'Status',
            componentType: 'SelectInput',
            options: fixtureHelpers.getStatuses(),
        },
        { name: 'week', label: 'Week', componentType: 'Input', type: 'number' },
        {
            name: 'player1',
            label: 'Home Participant',
            componentType: 'AsyncSelectInput',
            loadOptions: (inputValues) =>
                new Api(`seasons/${store.selectedSeason.id}/participants`)
                    .getList({ q: inputValues })
                    .then((items) => items.map((item) => ({ value: item.id, label: item.player.nickname }))),
            defaultInputValue:
                selectedResourceId &&
                fixture &&
                fixture.participants &&
                fixture.participants.find((p) => p.side === 'home')
                    ? fixture.participants.find((p) => p.side === 'home').participant.player.nickname
                    : '',
        },
        {
            name: 'player2',
            label: 'Away Participant',
            componentType: 'AsyncSelectInput',
            loadOptions: (inputValues) =>
                new Api(`seasons/${store.selectedSeason.id}/participants`)
                    .getList({ q: inputValues })
                    .then((items) => items.map((item) => ({ value: item.id, label: item.player.nickname }))),
            defaultInputValue:
                selectedResourceId &&
                fixture &&
                fixture.participants &&
                fixture.participants.find((p) => p.side === 'away')
                    ? fixture.participants.find((p) => p.side === 'away').participant.player.nickname
                    : '',
        },
        {
            name: 'channel',
            label: 'TV',
            componentType: 'Select',
            isClearable: true,
            options: Array.from({ length: 7 }).map((_, index) => ({
                label: `TV ${index + 1}`,
                value: `TV${index + 1}`,
            })),
        },
    ];

    const updateFunctionBuilder = () => {
        return _debounce((fixtureId, stat, value) => {
            store.updateFixtureStat(fixtureId, stat, value ? +value : null);
        }, 250);
    };

    const handleStatChange = (fixtureId, stat, updateFunction) => (event) => {
        const {
            target: { value },
        } = event;

        updateFunction(fixtureId, stat, value);
    };

    const fetchData = (query) => {
        const kickoffFilter = query.filters.find((f) => f.column.field === 'kickoff');

        if (kickoffFilter && kickoffFilter.value instanceof Date) {
            const defaultFilter = moment(kickoffFilter.value).tz(getTimeZone());
            const startISO = defaultFilter.clone().startOf('day').toISOString();
            const endISO = defaultFilter.clone().endOf('day').toISOString();

            // Manipulate kickoff filter
            kickoffFilter.operator = 'between';
            kickoffFilter.value = `${startISO},${endISO}`;
        }

        const [page, params] = prepareParams(query, true, store.totals.get('fixtures'));
        const apiPath = seasonId ? `seasons/${seasonId}/fixtures` : 'fixtures';

        return esportsApi
            .getList(
                {
                    ...params,
                    ...(query.orderDirection === '' && {
                        orderBy: ['kickoff', 'matchNr'],
                        orderDirection: [
                            defaultKickoffOrderDirection ? defaultKickoffOrderDirection : 'asc',
                            defaultMatchNrOrderDirection ? defaultMatchNrOrderDirection : 'asc',
                        ],
                    }),
                },
                apiPath
            )
            .then((response) => {
                store.updateTotals('fixtures', response.total);
                store.updateResources('fixtures', response.data);

                return {
                    page,
                    totalCount: response.total,
                    data: response.data,
                };
            })
            .then(fixtureHelpers.mapResponse({ timeOnly: false, kickoffISO: !!auth.user && !disableEdit }))
            .catch((e) => {
                return {
                    page: 0,
                    totalCount: 0,
                    data: [],
                };
            });
    };

    const initialValues = useMemo(() => {
        const resource = store.getResource('fixtures', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            matchNr: resource ? resource.matchNr : '',
            kickoff: resource ? resource.kickoff : '',
            status: resource ? resource.status : '',
            week: resource ? resource.week || '' : '',
            channel: resource ? resource.channel || '' : '',
            ...(!resource && { seasonId }),
            player1:
                fixture && fixture.participants && fixture.participants.find((p) => p.side === 'home')
                    ? fixture.participants.find((p) => p.side === 'home').participant.player.id
                    : '',
            player2:
                fixture && fixture.participants && fixture.participants.find((p) => p.side === 'away')
                    ? fixture.participants.find((p) => p.side === 'away').participant.player.id
                    : '',
        };
    }, [store, selectedResourceId, fixture, seasonId]);

    return (
        <>
            {status && <Notification key={status.key} variant={status.variant} message={status.message} />}
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setShowCreateEdit(false);
                    setSelectedResourceId(null);
                }}
                resource="fixtures"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
                customOnSubmit={async (data) => {
                    const { id, player1, player2, ...rest } = data;

                    if (fixture) {
                        const oldPlayer1 =
                            fixture &&
                            fixture.participants &&
                            fixture.participants.find((p) => p.side === 'home') &&
                            fixture.participants.find((p) => p.side === 'home').participant.player.id;

                        const oldPlayer2 =
                            fixture &&
                            fixture.participants &&
                            fixture.participants.find((p) => p.side === 'away') &&
                            fixture.participants.find((p) => p.side === 'away').participant.player.id;

                        const promises = [esportsApi.update(id, rest, 'fixtures')];
                        if (oldPlayer1 !== player1) {
                            promises.push(fixtureHelpers.createOrUpdateFixtureParticipant(fixture, player1, 'home'));
                        }
                        if (oldPlayer2 !== player2) {
                            promises.push(fixtureHelpers.createOrUpdateFixtureParticipant(fixture, player2, 'away'));
                        }

                        return Promise.all(promises);
                    } else {
                        const fixture = await esportsApi.create(rest, 'fixtures');

                        await fixtureHelpers.createOrUpdateFixtureParticipant(fixture, player1, 'home');
                        await fixtureHelpers.createOrUpdateFixtureParticipant(fixture, player2, 'away');

                        return Promise.resolve(fixture);
                    }
                }}
            />
            <List
                onRowClick={(event, rowData) => {
                    history.push(`/fixtures/${rowData.id}`);
                }}
                hasSearch={false}
                resource="fixtures"
                data={fetchData}
                title={`${store.selectedSeason.name} Fixtures`}
                fields={[
                    {
                        field: 'matchNr',
                        title: '#',
                        // lookup: Array.from({ length: 100 }, (v, i) => i + 1).reduce((carry, item) => {
                        //     carry[item] = item;
                        //     return carry;
                        // }, {}),
                        width: '1%',
                        cellStyle: { whiteSpace: 'nowrap' },
                    },
                    hasSport
                        ? {
                              field: 'season.tournament.category.sport.name',
                              title: 'Sport',
                          }
                        : null,
                    {
                        field: 'week',
                        title: 'Week',
                        lookup: Array.from({ length: 255 }, (v, i) => i + 1).reduce((carry, item) => {
                            carry[item] = item;
                            return carry;
                        }, {}),
                        width: '1%',
                        cellStyle: { whiteSpace: 'nowrap' },
                    },
                    {
                        field: 'kickoff',
                        title: 'Kickoff',
                        type: 'date',
                        ...(defaultKickoffFilter && { defaultFilter: defaultKickoffFilter }),
                        render: ({ id, kickoff }) => {
                            if (!!auth.user && !disableEdit) {
                                return (
                                    <div className="py-2" onClick={(e) => e.stopPropagation()}>
                                        <UpdateKickoff fixtureId={id} kickoff={kickoff} />
                                    </div>
                                );
                            }

                            return kickoff;
                        },
                    },
                    {
                        field: 'homeTeam',
                        title: 'Home',
                        sorting: false,
                        filtering: false,
                        headerStyle: {
                            textAlign: 'center',
                        },
                        cellStyle: {
                            textAlign: 'center',
                        },
                        render: (rowData) => (
                            <>
                                <Team
                                    participant={rowData.participants.find((p) => p.side === 'home')}
                                    teamName={rowData.homeTeam}
                                />
                                <strong>{rowData.player1}</strong>
                            </>
                        ),
                    },
                    {
                        field: 'awayTeam',
                        title: 'Away',
                        sorting: false,
                        filtering: false,
                        headerStyle: {
                            textAlign: 'center',
                        },
                        cellStyle: {
                            textAlign: 'center',
                        },
                        render: (rowData) => (
                            <>
                                <Team
                                    participant={rowData.participants.find((p) => p.side === 'away')}
                                    teamName={rowData.awayTeam}
                                />
                                <strong>{rowData.player2}</strong>
                            </>
                        ),
                    },
                    {
                        field: 'homeScore',
                        title: 'Home Score',
                        filtering: false,
                        sorting: false,
                        render: (rowData) => (
                            <>
                                <MuiTextField
                                    key={rowData.id}
                                    disabled={!auth.user || disableEdit}
                                    type="text"
                                    defaultValue={_get(store.getFixtureById(rowData.id), `result.stats.home_score`, '')}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleStatChange(rowData.id, 'home_score', updateFunctionBuilder())}
                                    onKeyPress={validateNum}
                                />
                            </>
                        ),
                    },
                    {
                        field: 'awayScore',
                        title: 'Away Score',
                        filtering: false,
                        sorting: false,
                        render: (rowData) => (
                            <>
                                <MuiTextField
                                    key={rowData.id}
                                    disabled={!auth.user || disableEdit}
                                    type="text"
                                    defaultValue={_get(store.getFixtureById(rowData.id), `result.stats.away_score`, '')}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleStatChange(rowData.id, 'away_score', updateFunctionBuilder())}
                                    onKeyPress={validateNum}
                                />
                            </>
                        ),
                    },
                    defaultStatusFilter.includes(String(Status.NOT_STARTED))
                        ? {
                              field: 'channel',
                              title: 'TV',
                              width: '1%',
                              filtering: false,
                              sorting: false,
                              render: (rowData) => {
                                  if (!rowData.channel) {
                                      return null;
                                  }

                                  return <TVLink channel={rowData.channel} />;
                              },
                          }
                        : null,
                    {
                        field: 'Stats',
                        title: 'Stats',
                        sorting: false,
                        width: '1%',
                        render: () => {
                            return (
                                <>
                                    <div>
                                        <i className="far fa-chart-bar"></i>
                                    </div>
                                </>
                            );
                        },
                    },
                    {
                        field: 'status',
                        title: 'Status',
                        filtering: true,
                        ...(defaultStatusFilter && { defaultFilter: defaultStatusFilter }),
                        // defaultFilter: [String(Status.LIVE), String(Status.NOT_STARTED)],
                        lookup: fixtureHelpers.getStatuses().reduce((acc, status) => {
                            acc[status.value] = String(status.label);
                            return acc;
                        }, {}),
                        render: (rowData) => (
                            <>
                                {rowData.status !== undefined && (
                                    <div className="flex flex-row items-center" onClick={(e) => e.stopPropagation()}>
                                        <Chip
                                            label={
                                                fixtureHelpers
                                                    .getStatuses()
                                                    .find(({ value }) => value === rowData.status).label
                                            }
                                            color={fixtureHelpers.getStatusesColors(rowData.status).color}
                                            bgColor={fixtureHelpers.getStatusesColors(rowData.status).bgColor}
                                        />
                                        {!!auth.user && !disableActions && (
                                            <UpdateStatus
                                                status={rowData.status}
                                                enabled={true}
                                                fixtureId={rowData.id}
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        ),
                    },
                ].filter(Boolean)}
                detailPanel={(rowData) => {
                    const stats = store.activeSport.tag.availableStats
                        .filter(({ active = true }) => active)
                        .filter((stat) => stat.key !== 'home_score' && stat.key !== 'away_score')
                        .sort(({ order: orderA = 999 }, { order: orderB = 999 }) => orderA - orderB);

                    const renderStat = (stat) => (
                        <div key={stat.key}>
                            <MuiTextField
                                style={{ width: 300 }}
                                disabled={!auth.user}
                                label={stat.name}
                                type="text"
                                defaultValue={_get(store.getFixtureById(rowData.id), `result.stats.${stat.key}`, '')}
                                onChange={handleStatChange(rowData.id, stat.key, updateFunctionBuilder())}
                                onKeyPress={validateNum}
                            />
                        </div>
                    );

                    return (
                        <div className="flex flex-center">
                            <h1 className="p-4">Home</h1>
                            <div className="p-4">
                                {stats.filter((stat) => stat.key.includes('home')).map((stat) => renderStat(stat))}
                            </div>
                            <div className="p-2">
                                {stats.filter((stat) => stat.key.includes('away')).map((stat) => renderStat(stat))}
                            </div>
                            <h1 className="p-4">Away</h1>
                        </div>
                    );
                }}
                renderActions={
                    !disableActions
                        ? (row) => (
                              <>
                                  <EditAction
                                      handleClick={() => {
                                          setSelectedResourceId(row.id);
                                          setShowCreateEdit(true);
                                      }}
                                  />
                              </>
                          )
                        : null
                }
                renderToolbar={
                    !disableActions
                        ? () => (
                              <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                                  <ImportButton onChange={handleImport} />
                                  <Button style={{ marginLeft: 15 }} onClick={() => setShowCreateEdit(true)}>
                                      Create Fixture
                                  </Button>
                              </div>
                          )
                        : null
                }
                defaultPageSize={defaultPageSize}
                {...rest}
            />
        </>
    );
}

export default Fixtures;
