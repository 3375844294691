import React from 'react';
import Helmet from 'react-helmet';

const TournamentStructure = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <Helmet>
                <title>GT Sports - Tournament Structure</title>
                <meta
                    name="description"
                    content="Understand how GTSports tournaments are operated and how results are uploaded on our site."
                />
                <meta property="og:title" content="GT Sports - Tournament Structure" />
                <meta
                    property="og:description"
                    content="Understand how GTSports tournaments are operated and how results are uploaded on our site."
                />
                <meta property="og:url" content="https://gtleagues.com/current-seasons" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports tournament structure" />
            </Helmet>
            <p>
                <strong>Structure</strong>
            </p>
            <p>Gt offers individual tournaments lasting 2 hours and 30 minutes each. In every tournament players of the same league,compete with three different teams each ,based on the name of the group of teams they play(La Liga , Super Lig etc.)Each league competes for 8 hours in total,playing three tournaments. Every twenty-four hours three leagues are playing each for eight hours to a combination of 9 different tournaments and 180 games per day.Players are rewarded with bonuses based on their performance.</p>
            <p>In practice, four leagues of twenty-four players of different capacities will give you a spectacular event. New players that enter GT have to play qualifying games with the existing squad so as to confirm their level and therefore enroll them in the proper League. Every visitor can check the players’ level via the dashboard, standings or global rankings.</p>
            <p>The games are being held in GT League studios and everything is fully monitored and streamlined. You can view the game live through our Twitch TV channels and YouTube channels.</p>

            <p>
                <strong>Results</strong>
            </p>
            <p>Results for all tournaments are uploaded within 10 minutes from the completion of the event. Only in extreme cases is this different. Delayed results are related to technical issues. In case that results are delayed we guarantee that results will be available in no more than 12 hours.</p>
        </div>
    );
};

export default TournamentStructure;
