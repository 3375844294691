import React, { useMemo, useCallback } from 'react';
import AsyncInput from 'components/AsyncInput';
import Api from 'helpers/Api';

const TournamentsFilter = ({ categoryId, ...rest }) => {
    const sportsApi = useMemo(() => new Api(categoryId ? `categories/${categoryId}/tournaments` : 'tournaments'), [
        categoryId,
    ]);

    const loadOptions = useCallback(
        (inputValues) => {
            return sportsApi
                .getList({ q: inputValues })
                .then((sport) => sport.map((s) => ({ value: s.id, label: s.name })));
        },
        [sportsApi]
    );

    return <AsyncInput key={categoryId} {...rest} placeholder="All Tournaments" loadOptions={loadOptions} />;
};

export default TournamentsFilter;
