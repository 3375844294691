import React from 'react';
import EditAction from 'components/List/EditAction';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import List from 'components/List';
import { esportsApi } from 'helpers/Api';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';

const Users = () => {
    const [data, setData] = React.useState([]);
    const [version, setVersion] = React.useState(1);
    React.useEffect(() => {
        esportsApi.getList({}, 'users').then((res) => {
            setData(res);
        });
    }, [version]);

    const [selectedResourceId, setSelectedResourceId] = React.useState(null);
    const [showCreateEdit, setShowCreateEdit] = React.useState(false);

    const fields = [
        { name: 'name', label: 'Name', componentType: 'Input' },
        { name: 'email', label: 'Email', componentType: 'Input' },
        { name: 'password', label: 'Password', componentType: 'Input' },
        {
            name: 'role',
            label: 'Role',
            componentType: 'SelectInput',
            options: [
                { label: 'Admin', value: 'admin' },
                { label: 'Operator', value: 'operator' },
                { label: 'Score Operator', value: 'scoreOperator' },
                { label: 'Viewer', value: 'viewer' },
            ],
        },
    ];

    const initialValues = React.useMemo(() => {
        const resource = data.find((item) => item.id === selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            name: resource ? resource.name : '',
            email: resource ? resource.email : '',
            role: resource ? resource.role : '',
        };
    }, [data, selectedResourceId]);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
        role: Yup.string().required(),
    });

    return (
        <React.Fragment>
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    setVersion(version + 1);
                    setSelectedResourceId(null);
                    setShowCreateEdit(false);
                }}
                resource="users"
                fields={fields}
                initialValues={initialValues}
                hasSportstreeStore={false}
                validationSchema={validationSchema}
            />
            <List
                data={data}
                hasSelection={false}
                hasSearch={false}
                hasPagination={false}
                hasFiltering={false}
                fields={[
                    { field: 'id', title: 'ID' },
                    { field: 'name', title: 'Name' },
                    { field: 'email', title: 'Email' },
                    { field: 'name', title: 'Name' },
                    { field: 'role', title: 'Role' },
                    {
                        field: 'edit',
                        title: 'Edit',
                        sorting: false,
                        render: (rowData) => (
                            <>
                                <EditAction
                                    handleClick={() => {
                                        setSelectedResourceId(rowData.id);
                                        setShowCreateEdit(true);
                                    }}
                                />
                            </>
                        ),
                    },
                ]}
                renderToolbar={() => (
                    <div className="p-2">
                        <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => setShowCreateEdit(true)}>Create User</Button>
                        </div>
                    </div>
                )}
            />
        </React.Fragment>
    );
};

export default Users;
