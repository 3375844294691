import React, { Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import Dashboard from 'pages/dashboard';
import Users from 'pages/users';
import PastResults from 'pages/pastresults';
import SportsTree from 'pages/sportstree';
import Players from 'pages/players';
import CurrentSeasons from 'pages/currentseasons';
import Fixture from 'pages/fixture';
import GlobalRanking from 'pages/globalranking';
import Standings from 'pages/standings';
import HeadToHead from 'pages/headtohead';
import AboutUs from 'pages/aboutus';
import ContactUs from 'pages/contactus';
import GameRules from 'pages/gamerules';
import TournamentStructure from 'pages/tournamentstructure';
import CredibilityOfTheGame from 'pages/credibilityofthegame';
import CoordinationWithEsic from 'pages/coordinationwithesic';
import LiveStream from 'pages/livestream';
import TV1 from 'pages/channels/tv1';
import TV2 from 'pages/channels/tv2';
import TV3 from 'pages/channels/tv3';
import TV4 from 'pages/channels/tv4';
import TV5 from 'pages/channels/tv5';
import TV6 from 'pages/channels/tv6';
import BonusSystem from 'pages/bonussystem';
import { useAuthStore } from 'app/modules/Auth/auth.store';

export default function BasePage() {
    const auth = useAuthStore();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from="/" to="/dashboard" />
                <ContentRoute path="/dashboard" component={Dashboard} />
                {!!auth.user && auth.user.role === 'admin' && <Route path="/users" component={Users} />}
                <Route path="/past-results" component={PastResults} />
                <Route path="/sports-tree" component={SportsTree} />
                <Route path="/players" component={Players} />
                <Route path="/current-seasons" component={CurrentSeasons} />
                <Route path="/fixtures/:id" component={Fixture} />
                <Route path="/global-ranking" component={GlobalRanking} />
                <Route path="/standings" component={Standings} />
                <Route path="/head-to-head/:seasonId?/:player1?/:player2?" component={HeadToHead} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/game-rules" component={GameRules} />
                <Route path="/tournament-structure" component={TournamentStructure} />
                <Route path="/credibility-of-the-game" component={CredibilityOfTheGame} />
                <Route path="/coordination-with-esic" component={CoordinationWithEsic} />
                <Route path="/live-stream" component={LiveStream} />
                <Route path="/TV1" component={TV1} />
                <Route path="/TV2" component={TV2} />
                <Route path="/TV3" component={TV3} />
                <Route path="/TV4" component={TV4} />
                <Route path="/TV5" component={TV5} />
                <Route path="/TV6" component={TV6} />
                {!!auth.user && <Route path="/bonus-system" component={BonusSystem} />}
                <Redirect to="/error/error-v1" />
            </Switch>
        </Suspense>
    );
}
