import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import Api from 'helpers/Api';
import Notification from 'components/Notification';
import CreateEditHeader from './CreateEditHeader';
import CreateEditForm from './CreateEditForm';

export default function CreateEditDialog({
    id,
    show,
    onHide,
    resource,
    apiPath,
    fields,
    initialValues,
    validationSchema,
    customOnSubmit,
    requestMapper,
    hasSportstreeStore = true,
}) {
    const esportsApiRef = useRef(new Api(apiPath || resource));
    const [status, setStatus] = useState(null);
    const store = useSportsTreeStore();

    const esportsApi = esportsApiRef.current;

    const notify = (variant, message) => {
        setStatus({ key: Math.random(), message, variant });
    };

    const onSubmit = (values) => {
        const data = Object.keys(values).reduce((acc, key) => {
            if (values[key] !== '') {
                acc[key] = values[key];
            }

            return acc;
        }, {});

        if (customOnSubmit && typeof customOnSubmit === 'function') {
            customOnSubmit(data)
                .then((data) => {
                    onHide();
                    notify('success', 'Edited successfully!');
                    if (hasSportstreeStore) {
                        store.updateResource(resource, data);
                    }
                })
                .catch((e) => {
                    notify('error', e.message);
                });

            return;
        }

        if (values.id) {
            const { id, ...rest } = data;
            esportsApi
                .update(id, rest)
                .then((data) => {
                    onHide();
                    notify('success', 'Edited successfully!');
                    if (hasSportstreeStore) {
                        store.updateResource(resource, data);
                    }
                })
                .catch((e) => {
                    notify('error', e.message);
                });
        } else {
            esportsApi
                .create(data)
                .then(() => {
                    onHide();
                    notify('success', 'Created successfully!');
                    if (hasSportstreeStore) {
                        store.updateTotals(resource, store.totals.get(resource) + 1);
                    }
                })
                .catch((e) => {
                    notify('error', e.message);
                });
        }
    };

    return (
        <>
            {status && <Notification key={status.key} variant={status.variant} message={status.message} />}
            <Modal size="lg" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
                <CreateEditHeader id={id} />
                <CreateEditForm
                    onSubmit={onSubmit}
                    actionsLoading={false}
                    onHide={onHide}
                    fields={fields}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    requestMapper={requestMapper}
                />
            </Modal>
        </>
    );
}
