import React, { useEffect, useState, useMemo } from 'react';
import _get from 'lodash/get';
import List from 'components/List';
import EditAction from 'components/List/EditAction';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import Api from 'helpers/Api';
import ImportButton from 'components/ImportButton';
import Notification from 'components/Notification';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { toAbsoluteUrl, isValidUrl } from 'helpers/utils';
import * as Yup from 'yup';

let validationSchema = Yup.object().shape({
    teamId: Yup.number().required(),
    playerId: Yup.number().required(),
    seasonId: Yup.number().required(),
});

export default function Fixtures() {
    const { seasonId } = useParams();
    const history = useHistory();
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const store = useSportsTreeStore();
    const [status, setStatus] = useState(null);

    const notify = (variant, message) => {
        setStatus({ key: Math.random(), message, variant });
    };

    useEffect(() => {
        if (store.selectedSeason.id === -1) {
            history.push(`/sports-tree/sports`);
        }
    }, [history, store]);

    const fields = useMemo(
        () => [
            {
                name: 'playerId',
                label: 'Player',
                componentType: 'AsyncSelectInput',
                loadOptions: (inputValues) => {
                    return new Api('players')
                        .getList({ q: inputValues, filters: [{ field: 'active', operator: 'eq', value: true }] })
                        .then((players) => players.map((player) => ({ value: player.id, label: player.nickname })));
                },
                defaultInputValue:
                    selectedResourceId && store.getResource('participants', selectedResourceId)
                        ? store.getResource('participants', selectedResourceId).player.nickname
                        : '',
            },
            {
                name: 'teamId',
                label: 'Team',
                componentType: 'AsyncSelectInput',
                loadOptions: (inputValues) =>
                    new Api('teams')
                        .getList({ q: inputValues })
                        .then((teams) => teams.map((team) => ({ value: team.id, label: team.name }))),
                defaultInputValue:
                    selectedResourceId && store.getResource('participants', selectedResourceId)
                        ? store.getResource('participants', selectedResourceId).team.name
                        : '',
            },
            { name: 'seasonId', componentType: 'HiddenInput' },
        ],
        [selectedResourceId, store]
    );

    const handleImport = async (values) => {
        const teams = await new Api('teams').getList({
            xtc: false,
            filters: [{ field: 'name', operator: 'in', value: `${values.map((value) => value.team).join(',')}` }],
        });

        const players = await new Api('players').getList({
            xtc: false,
            filters: [{ field: 'nickname', operator: 'in', value: `${values.map((value) => value.player).join(',')}` }],
        });

        const data = values
            .map((value) => ({
                seasonId: store.selectedSeason.id,
                playerId: _get(
                    players.find((p) => p.nickname === value.player),
                    'id'
                ),
                teamId: _get(
                    teams.find((p) => p.name === value.team),
                    'id'
                ),
            }))
            .filter((item) => item.playerId && item.teamId);

        new Api('seasons/participants')
            .createMany(data)
            .then((res) => {
                store.refreshTable();
            })
            .catch((e) => {
                notify('error', e.message);
            });
    };

    const initialValues = useMemo(() => {
        const resource = store.getResource('participants', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            playerId: resource ? resource.playerId : '',
            teamId: resource ? resource.teamId : '',
            seasonId: store.selectedSeason.id,
        };
    }, [store, selectedResourceId]);

    return (
        <>
            {status && <Notification key={status.key} variant={status.variant} message={status.message} />}
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setSelectedResourceId(null);
                    setShowCreateEdit(false);
                }}
                resource="participants"
                apiPath="seasons/participants"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
            />
            <List
                hasSearch={false}
                hasFiltering={false}
                title={`${store.selectedSeason.name} Participants`}
                apiPath={`seasons/${seasonId}/participants`}
                resource="participants"
                crudResource="seasons/participants"
                fields={[
                    { field: 'player.nickname', title: 'Player', sorting: false, filtering: false },
                    { field: 'team.name', title: 'Team', sorting: false, filtering: false },
                    {
                        field: 'url',
                        title: 'Crest',
                        filtering: false,
                        sorting: false,
                        render: (rowData) =>
                            rowData.team.crest && isValidUrl(rowData.team.crest) ? (
                                <img alt="team" src={rowData.team.crest} style={{ width: 50 }} />
                            ) : (
                                <img
                                    alt="team"
                                    src={toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png')}
                                    style={{ width: 50 }}
                                />
                            ),
                    },
                ]}
                renderActions={(row) => (
                    <>
                        <EditAction
                            handleClick={() => {
                                setSelectedResourceId(row.id);
                                setShowCreateEdit(true);
                            }}
                        />
                    </>
                )}
                renderToolbar={() => (
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <ImportButton onChange={handleImport} />
                        <Button style={{ marginLeft: 15 }} onClick={() => setShowCreateEdit(true)}>
                            Create Participant
                        </Button>
                    </div>
                )}
            />
        </>
    );
}
