import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
// import { ModalProgressBar } from '_metronic/_partials/controls';

export default function CreateEditHeader({ id }) {
    const [title] = useState(id ? 'Edit' : 'Create');

    return (
        <>
            {/* <ModalProgressBar /> */}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}
