import React from 'react';
import MaterialTable from 'material-table';
import Team from 'pages/sportstree/fixtures/Team';
import Score from 'components/Score';
import { useHistory } from 'react-router-dom';

const Table = ({ data }) => {
    const history = useHistory();

    return (
        <MaterialTable
            onRowClick={(event, rowData) => {
                history.push(`/fixtures/${rowData.id}`);
            }}
            columns={[
                { field: 'kickoff', title: 'Time' },
                {
                    field: 'season.name',
                    title: 'Season',
                    sorting: false,
                },
                {
                    field: 'homeTeam',
                    title: 'Home',
                    sorting: false,
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                    width: '25%',
                    render: (rowData) => (
                        <React.Fragment>
                            <Team
                                participant={rowData.participants.find((p) => p.side === 'home')}
                                teamName={rowData.homeTeam}
                            />
                            <strong>{rowData.player1}</strong>
                        </React.Fragment>
                    ),
                },
                {
                    field: 'score',
                    title: 'Score',
                    filtering: false,
                    sorting: false,
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                    render: (rowData) => (
                        <div className="flex items-center justify-center">
                            <Score data={rowData} />
                        </div>
                    ),
                },
                {
                    field: 'awayTeam',
                    title: 'Away',
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                        height: '115px',
                    },
                    width: '25%',
                    sorting: false,
                    render: (rowData) => (
                        <React.Fragment>
                            <Team
                                participant={rowData.participants.find((p) => p.side === 'away')}
                                teamName={rowData.awayTeam}
                            />
                            <strong>{rowData.player2}</strong>
                        </React.Fragment>
                    ),
                },
            ]}
            style={{ borderRadius: 0 }}
            title={''}
            data={data}
            options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                toolbar: false,
                headerStyle: {
                    padding: '5px',
                },
                cellStyle: {
                    padding: '5px',
                },
            }}
        />
    );
};
export default Table;
