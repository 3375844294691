import React, { useEffect, useMemo, useState, useRef } from 'react';
import clsx from 'clsx';
import SimpleStatCard from 'components/SimpleStatCard';
import AsyncInput from 'components/AsyncInput';
import SportsFilter from 'components/SportsFilter';
import CategoriesFilter from 'components/CategoriesFilter';
import UpcomingMatches from 'components/UpcomingMatches';
import SportStandings from 'components/SportStandings';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import Status from 'pages/sportstree/fixtures/Status';
import Tabs from 'components/Tabs';
import FixturesCount from 'pages/dashboard/FixturesCount';
import Helmet from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { toAbsoluteUrl } from 'helpers/utils';
import { useDashboardStore } from 'pages/dashboard/store';
import { getNextNDays } from 'helpers/dates';
import { useStores } from 'stores/mobx';

function Dashboard() {
    const {
        activeDay,
        endedMatchesCount,
        scheduledMatchesCount,
        remainingMatchesCount,
        getEndedMatchesCount,
        getScheduledMatchesCount,
        getRemainingMatchesCount,
        activeSport,
        activeCategory,
        activeStatus,
        setActiveSport,
        setActiveCategory,
        setActiveStatus,
        setActiveDay,
    } = useDashboardStore();
    const { common } = useStores();
    const { activeSports: sports } = common;

    const [sportInitialFetch, setSportInitialFetch] = useState(false);

    const calledOnce = useRef(false);

    useEffect(() => {
        if (calledOnce.current) {
            return;
        }
        if (sports.length > 0) {
            setSportInitialFetch(true);
            calledOnce.current = true;
        }
    }, [sports]);

    useEffect(() => {
        if (sports.length > 0) {
            const firstSport = { value: sports[0].id, label: sports[0].name };
            setActiveSport(firstSport);
        }
    }, [sports, setActiveSport]);

    useEffect(() => {
        getEndedMatchesCount();
        getScheduledMatchesCount();
        getRemainingMatchesCount();
    }, [getEndedMatchesCount, getScheduledMatchesCount, getRemainingMatchesCount]);

    const dates = useMemo(() => getNextNDays(7), []);

    const statuses = activeDay === 0 ? [Status.LIVE, Status.NOT_STARTED, Status.ENDED] : [Status.NOT_STARTED];

    return (
        sports.length > 0 &&
        sportInitialFetch && (
            <>
                <Helmet>
                    <title>GT Sports - Dashboard</title>
                    <meta
                        name="description"
                        content="GTSports Leagues, a premium esports content and live tournaments brand.
"
                    />
                    <meta property="og:title" content="GT Sports - Dashboard" />
                    <meta
                        property="og:description"
                        content="GTSports Leagues, a premium esports content and live tournaments brand.
"
                    />
                    <meta property="og:url" content="https://gtleagues.com/dashboard" />
                </Helmet>
                <FixturesCount />
                {activeDay === 0 && (
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <SimpleStatCard
                                svg={toAbsoluteUrl('/media/pages/dashboard/scheduled_fixtures.svg')}
                                title="Scheduled Matches"
                                count={scheduledMatchesCount}
                            />
                        </div>
                        <div className="col-md-4 my-2">
                            <SimpleStatCard
                                svg={toAbsoluteUrl('/media/pages/dashboard/ended_fixtures.svg')}
                                title="Ended Matches"
                                count={endedMatchesCount}
                            />
                        </div>
                        <div className="col-md-4 my-2">
                            <SimpleStatCard
                                svg={toAbsoluteUrl('/media/pages/dashboard/remaining_fixtures.svg')}
                                title="Remaining Matches"
                                count={remainingMatchesCount}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="md:flex my-2 justify-content-end">
                            <div style={{ flex: '0 0 160px', marginLeft: 5 }}>
                                <SportsFilter key={activeSport} defaultValue={activeSport} onChange={setActiveSport} />
                            </div>
                            <div style={{ flex: '0 0 160px', marginLeft: 5 }}>
                                <CategoriesFilter
                                    sportId={activeSport ? activeSport.value : null}
                                    onChange={setActiveCategory}
                                    defaultValue={activeCategory}
                                />
                            </div>
                            <div style={{ flex: '0 0 160px', marginLeft: 5 }}>
                                <AsyncInput
                                    key={JSON.stringify(statuses)}
                                    defaultValue={activeStatus}
                                    onChange={setActiveStatus}
                                    loadOptions={(q) =>
                                        new Promise((resolve) =>
                                            resolve(
                                                fixtureHelpers
                                                    .getStatuses()
                                                    .filter(({ value }) => statuses.includes(value))
                                                    .filter((status) =>
                                                        q.length > 0
                                                            ? status.label.toLowerCase().includes(q.toLowerCase())
                                                            : true
                                                    )
                                            )
                                        )
                                    }
                                    placeholder="By Status"
                                />
                            </div>
                        </div>
                    </div>
                    {activeSport && (
                        <div className="col-xxl-4 col-md-12">
                            <div className="my-2">
                                <SportStandings sportId={activeSport.value} compact />
                            </div>
                        </div>
                    )}
                    <div className={clsx({ 'col-md-12': true, 'col-xxl-8': !!activeSport })}>
                        <div className="my-2">
                            <Tabs
                                defaultValue={activeDay}
                                variant="scrollable"
                                scrollButtons="auto"
                                onChangeCb={(newValue) => {
                                    setActiveDay(newValue);
                                }}
                                items={dates.map((date) => ({
                                    label: date.format('MMMM DD'),
                                    render: () => (
                                        <UpcomingMatches
                                            key={date.toString()}
                                            date={date}
                                            activeSport={activeSport}
                                            activeCategory={activeCategory}
                                            activeStatus={activeStatus ? activeStatus.value : null}
                                        />
                                    ),
                                }))}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

export default observer(Dashboard);
