import React from 'react';
import SVG from 'react-inlinesvg';
import SolidForm from 'pages/fixture/SolidForm';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { toAbsoluteUrl } from 'helpers/utils';
import { isKnockout } from 'pages/sportstree/seasons';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import './graphStats.scss';

const Hexagon = ({ color, children }) => {
    return (
        <div
            className={`relative flex flex-col items-center justify-center`}
            style={{
                width: '80px',
                height: '80px',
            }}
        >
            {children}
            <div className="absolute inset-0 h-full">
                <SVG style={{ fill: color || '#000' }} src={toAbsoluteUrl('/media/pages/fixture/hexagon.svg')}></SVG>
            </div>
        </div>
    );
};

const Form = ({ form, color = '#0658f0' }) => {
    const percentage = React.useMemo(() => {
        if (!form) {
            return '-';
        }

        const formArr = form.form.split(' ');

        if (formArr.filter((item) => item !== '-').length === 0) {
            return '-';
        }

        const formPoints = formArr
            .filter((item) => item !== '-')
            .reduce((acc, value) => {
                if (value === 'W') {
                    acc += 2;
                } else if (value === 'D') {
                    acc += 1;
                }

                return acc;
            }, 0);

        return ((formPoints / (formArr.filter((item) => item !== '-').length * 2)) * 100).toFixed(0);
    }, [form]);

    return (
        <div className="flex flex-col items-center mb-4">
            <div style={{ maxWidth: 150 }} className="m-4">
                <CircularProgressbarWithChildren
                    value={percentage}
                    styles={buildStyles({
                        pathColor: color,
                        // trailColor: 'green',
                        strokeLinecap: 'butt',
                    })}
                >
                    <div className="text-center">
                        <span style={{ borderBottom: `1px solid ${color}` }} className="text-2xl font-black">
                            {percentage === '-' ? '-' : `${percentage}%`}
                        </span>
                        <p className="m-0">
                            <small>Form</small>
                        </p>
                    </div>
                </CircularProgressbarWithChildren>
            </div>
            <div className="text-sm">PERFORMANCE</div>
            <SolidForm form={form} />
        </div>
    );
};

const Statistics = ({ sportId, stat, victories, victoriesColor = '#0658f0' }) => {
    return (
        <div className="flex flex-col items-center px-4 text-center">
            <Hexagon color={victoriesColor}>
                <h2 className="m-0 font-black">{victories}</h2>
                <p className="m-0 text-xs">VICTORIES</p>
            </Hexagon>
            {sportId === 2 && stat && (
                <React.Fragment>
                    <div className="my-2">
                        <p className="m-0">Total Points:</p>
                        <p className="m-0 font-bold">{stat.score_total_for || '-'}</p>
                    </div>
                    <div className="my-2">
                        <p className="m-0">Avg. points/match:</p>
                        <p className="m-0 font-bold">{calcPointsFor(stat, 'score_total_for') || '-'}</p>
                    </div>
                    <div className="my-2">
                        <p className="m-0">Points DIFF:</p>
                        <p className="m-0 font-bold">{calcDiff(stat) || '-'}</p>
                    </div>
                </React.Fragment>
            )}
            {[1, 3].includes(sportId) && stat && (
                <React.Fragment>
                    <div className="my-2">
                        <p className="m-0">Total Goals:</p>
                        <p className="m-0 font-bold">{stat.score_total_for || '-'}</p>
                    </div>
                    <div className="my-2">
                        <p className="m-0">Goals For Per Match:</p>
                        <p className="m-0 font-bold">{calcPointsFor(stat, 'score_total_for') || '-'}</p>
                    </div>
                    <div className="my-2">
                        <p className="m-0">Goals Against Per Match:</p>
                        <p className="m-0 font-bold">{calcPointsFor(stat, 'score_total_against') || '-'}</p>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

const RankingTable = ({ season, total, active, isAway = false }) => {
    const { setCurrentSeason } = useSportsTreeStore();
    const history = useHistory();

    const bgColor = isAway ? '#f61457' : '#0658f0';
    return (
        <div
            style={{
                left: !isAway ? '4px' : null,
                right: isAway ? '4px' : null,
            }}
            className={`relative cursor-pointer flex flex-col ${isAway ? 'items-end' : 'items-start'}`}
            onClick={() => {
                setCurrentSeason(season);
                history.push(`/sports-tree/seasons/${season.id}/standings`);
            }}
        >
            {Array.from({ length: total }).map((_, index) => (
                <div className="relative" style={{ margin: '2px 0' }} key={index}>
                    <div
                        className={`${active === index + 1 ? 'w-8' : 'w-6'}`}
                        style={{
                            height: '3px',
                            backgroundColor: active === index + 1 ? bgColor : '#999',
                        }}
                    ></div>
                    {active === index + 1 && (
                        <div className="absolute" style={{ [isAway ? 'left' : 'right']: '-27px', top: '-8px' }}>
                            #{index + 1}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const GraphStats = ({
    homePlayerId,
    awayPlayerId,
    homeSeasonStandings,
    awaySeasonStandings,
    totalSeasonParticipants,
    sportId,
    season,
    homeForm,
    awayForm,
    stats,
}) => {
    const homeStats = stats.find((stat) => stat.id === homePlayerId);
    const awayStats = stats.find((stat) => stat.id === awayPlayerId);

    const [homeWinsPercent, awayWinsPercent] = React.useMemo(() => {
        const homeWins = stats && Array.isArray(stats) && stats.length > 0 && homeStats ? homeStats.wins : 0;
        const awayWins = stats && Array.isArray(stats) && stats.length > 0 && awayStats ? awayStats.wins : 0;
        const gamesPlayed = stats && Array.isArray(stats) && stats.length > 0 && homeStats ? homeStats.games_played : 0;

        let homeWinsPercent = (homeWins / gamesPlayed) * 100;
        let awayWinsPercent = (awayWins / gamesPlayed) * 100;

        if (isNaN(homeWinsPercent)) {
            homeWinsPercent = 0;
        }

        if (isNaN(awayWinsPercent)) {
            awayWinsPercent = 0;
        }

        return [homeWinsPercent, awayWinsPercent];
    }, [stats, homeStats, awayStats]);

    return (
        <div className="flex items-center justify-center graph-stats-wrapper">
            <div className="left-section">
                {!isKnockout(season) && (
                    <div className="relative flex flex-col items-center p-4">
                        <RankingTable
                            total={totalSeasonParticipants}
                            active={homeSeasonStandings ? homeSeasonStandings.rank : null}
                            season={season}
                        />
                        {sportId !== 2 && (
                            <div>
                                <Hexagon color={'#999'}>
                                    <h2 className="m-0 font-black">
                                        {homeSeasonStandings ? homeSeasonStandings.points : '-'}
                                    </h2>
                                    <p className="m-0 text-sm">POINTS</p>
                                </Hexagon>
                            </div>
                        )}
                    </div>
                )}
                <div className="p-4">
                    <Form form={homeForm} />
                </div>
            </div>
            <div className="flex items-center middle-section">
                <div className="p-4">
                    <Statistics
                        sportId={sportId}
                        stat={homeStats ? homeStats : null}
                        victories={
                            stats && Array.isArray(stats) && stats.length > 0 && homeStats ? homeStats.wins : '-'
                        }
                    />
                </div>
                <div className="flex flex-col items-center p-4">
                    <div style={{ maxWidth: 200 }}>
                        <CircularProgressbarWithChildren
                            value={awayWinsPercent}
                            styles={buildStyles({
                                pathColor: '#f61457',
                                strokeLinecap: 'butt',
                            })}
                        >
                            <div className="absolute text-center p-8">
                                <span className="gradient-color">
                                    {stats && Array.isArray(stats) && stats.length > 0 ? stats[0].games_played : '-'}
                                </span>
                                <div>GAMES PLAYED</div>
                            </div>
                            <CircularProgressbar
                                counterClockwise
                                value={homeWinsPercent}
                                styles={buildStyles({
                                    pathColor: '#0658f0',
                                    trailColor: 'transparent',
                                    strokeLinecap: 'butt',
                                })}
                            />
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className="mt-4">
                        {sportId !== 2 && (
                            <Hexagon color={'#999'}>
                                <h2 className="m-0 font-black">
                                    {stats && Array.isArray(stats) && stats.length > 0 ? stats[0].draws : '-'}
                                </h2>
                                <p className="m-0 text-xs">DRAWS</p>
                            </Hexagon>
                        )}
                    </div>
                </div>
                <div className="p-4">
                    <Statistics
                        sportId={sportId}
                        stat={awayStats ? awayStats : null}
                        victories={
                            stats && Array.isArray(stats) && stats.length > 0 && awayStats ? awayStats.wins : '-'
                        }
                        victoriesColor="#f61457"
                    />
                </div>
            </div>
            <div className="right-section">
                <div className="p-4">
                    <Form form={awayForm} color="#f61457" />
                </div>
                {!isKnockout(season) && (
                    <div className="relative flex flex-col items-center p-4">
                        <RankingTable
                            total={totalSeasonParticipants}
                            active={awaySeasonStandings ? awaySeasonStandings.rank : null}
                            season={season}
                            isAway
                        />
                        {sportId !== 2 && (
                            <div>
                                <Hexagon color={'#999'}>
                                    <h2 className="m-0 font-black">
                                        {awaySeasonStandings ? awaySeasonStandings.points : '-'}
                                    </h2>
                                    <p className="m-0 text-sm">POINTS</p>
                                </Hexagon>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GraphStats;

/**
 * Helpers
 */

const calcPointsFor = (stat, key) => {
    const total = _get(stat, key);
    const gamesPlayed = _get(stat, 'games_played');

    const result = total / gamesPlayed;

    if (isNaN(result)) {
        return '-';
    }

    return result % 1 === 0 ? result : result.toFixed(2);
};

const calcDiff = (stat) => {
    const totalFor = _get(stat, 'score_total_for');
    const totalAgainst = _get(stat, 'score_total_against');
    const gamesPlayed = _get(stat, 'games_played');

    const x = totalFor - totalAgainst;

    const result = x / gamesPlayed;

    return isNaN(result) ? '-' : result;
};
