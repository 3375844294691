import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const BootstrapButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        padding: '0.65rem 1rem',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})(Button);

export default BootstrapButton;
