import React from 'react';
import { useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment-timezone';

const getFieldCSSClasses = (touched, errors) => {
    const classes = ['form-control'];
    if (touched && errors) {
        classes.push('is-invalid');
    }

    if (touched && !errors) {
        classes.push('is-valid');
    }

    return classes.join(' ');
};

export default function DatePicker({ field, ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext();

    return (
        <>
            <div>{props.label && <label>{props.label}</label>}</div>
            <ReactDatePicker
                className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                style={{ width: '100%' }}
                {...field}
                {...props}
                value={(field.value && new Date(field.value)) || null}
                selected={(field.value && new Date(field.value)) || null}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                    setFieldValue(field.name, moment(date).format('YYYY-MM-DD'));
                }}
            />
            {errors[field.name] && touched[field.name] ? (
                <div className="invalid-datepicker-feedback">{errors[field.name].toString()}</div>
            ) : (
                <div className="feedback">
                    Please enter <b>{props.label}</b> in 'mm/dd/yyyy format
                </div>
            )}
        </>
    );
}
