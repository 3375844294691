import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Helmet from 'react-helmet';

const ContactUs = () => {
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <h1>Contact Us</h1>
            <Helmet>
                <title>GT Sports - Contact Us</title>
                <meta
                    name="description"
                    content="Inquiries and questions are always welcome. Feel free to get in touch with GTSports Leagues."
                />
                <meta property="og:title" content="GT Sports - Contact Us" />
                <meta
                    property="og:description"
                    content="Inquiries and questions are always welcome. Feel free to get in touch with GTSports Leagues."
                />
                <meta property="og:url" content="https://gtleagues.com/TV3" />
            </Helmet>
            <Form>
                <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        type="text"
                        placeholder="Enter subject"
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        as="textarea"
                        placeholder="Enter message"
                    />
                </Form.Group>

                <a className="btn btn-primary" href={`mailto:support@gtleagues.com?subject=${subject}&body=${body}`}>
                    Send Mail
                </a>
            </Form>
        </div>
    );
};

export default ContactUs;
