import React from 'react';
import Select from 'react-select';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

const getFieldCSSClasses = (touched, errors) => {
    const classes = [];
    if (touched && errors) {
        classes.push('is-invalid');
    }

    if (touched && !errors) {
        classes.push('is-valid');
    }

    return classes.join(' ');
};

const SelectComponent = ({ options, ...props }) => {
    const {
        field,
        form: { touched, errors, setFieldValue, setFieldTouched },
        label,
        withFeedbackLabel = true,
        customFeedbackLabel,
        ...rest
    } = props;

    const defaultValue = options.find((option) => option.value === field.value);

    return (
        <>
            {label && <label>{label}</label>}
            <Select
                className={getFieldCSSClasses(touched, errors[field.name])}
                onChange={(selected) => {
                    if (rest.isMulti) {
                        if (Array.isArray(selected)) {
                            setFieldValue(
                                field.name,
                                selected.map((s) => s.value)
                            );
                        } else {
                            setFieldValue(field.name, []);
                        }
                    } else {
                        setFieldValue(field.name, selected ? selected.value : null);
                    }
                }}
                options={options}
                onBlur={() => setFieldTouched(field.name, true)}
                defaultValue={defaultValue}
                {...rest}
            />
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched}
                    label={label}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
};

SelectComponent.displayName = 'Form.Select';

export default SelectComponent;
