import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import CategoriesFilter from 'components/CategoriesFilter';
import TournamentsFilter from 'components/TournamentsFilter';
import SeasonsFilter from 'components/SeasonsFilter';
import { observer } from 'mobx-react-lite';
import Api, { esportsApi } from 'helpers/Api';
import { Modal } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useKnockoutsStore } from 'pages/sportstree/knockouts/store';

const KnockoutParticipants = () => {
    const { selectedSport } = useSportsTreeStore();
    const {
        knockout,
        participantsToCreate,
        updateParticipantsToCreate,
        removeParticipantToCreate,
        clearParticipantsToCreate,
    } = useKnockoutsStore();
    const [category, setCategory] = useState(false);
    const [tournament, setTournament] = useState(false);
    const [season, setSeason] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState([]);
    const [savedParticipants, setSavedParticipants] = useState([]);
    const [matchups, setMatchups] = useState([]);

    const updateMatchups = useCallback(() => {
        return esportsApi.getList({}, `knockouts/${knockout.id}/brackets`).then((response) => {
            setMatchups(response[0].matchups);
        });
    }, [knockout]);

    useEffect(() => {
        if (knockout) {
            updateMatchups();
        }
    }, [knockout, updateMatchups]);

    useEffect(() => {
        esportsApi.getList({}, `knockouts/${knockout.id}/participants`).then((response) => {
            setSavedParticipants(response);
        });
    }, [selectedParticipants, knockout]);

    const selectParticipants = savedParticipants
        .map((savedParticipant) => ({
            label: savedParticipant.participant.player.nickname,
            value: savedParticipant.id,
        }))
        .map((savedParticipant) => {
            if (
                matchups.some(
                    (matchup) =>
                        matchup.homeParticipantId === savedParticipant.value ||
                        matchup.awayParticipantId === savedParticipant.value
                )
            ) {
                savedParticipant.isDisabled = true;
            }

            return savedParticipant;
        });

    const handleDeleteParticipant = (id) => () => {
        removeParticipantToCreate(id);
    };

    return (
        <div className="bg-white p-4">
            {savedParticipants.length < knockout.totalSeeds && (
                <>
                    <div className="my-2">
                        <button className="btn btn-primary" onClick={() => setShow(true)}>
                            Add Participants
                        </button>
                    </div>
                    {participantsToCreate.map((participant) => (
                        <div className="py-2" key={participant.value}>
                            <div className="flex justify-between items-center">
                                <span>{participant.label}</span>
                                <button
                                    className="btn btn-primary"
                                    onClick={handleDeleteParticipant(participant.value)}
                                >
                                    Delete
                                </button>
                            </div>
                            <hr />
                        </div>
                    ))}
                    {participantsToCreate.length > 0 && (
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    if (participantsToCreate.length !== knockout.totalSeeds) {
                                        alert(`Participants count should be exactly ${knockout.totalSeeds}`);
                                        return;
                                    }
                                    const knockoutParticipantsApi = new Api('knockouts/participants');

                                    const data = participantsToCreate.map((participant) => ({
                                        knockoutId: knockout.id,
                                        seasonParticipantId: participant.value,
                                    }));

                                    knockoutParticipantsApi.createMany(data).then((response) => {
                                        setSelectedParticipants([]);
                                        clearParticipantsToCreate();
                                    });
                                }}
                            >
                                Create Participants
                            </button>
                        </div>
                    )}
                </>
            )}
            {knockout && knockout.totalSeeds === savedParticipants.length && (
                <>
                    {matchups
                        .sort((a, b) => a.id - b.id)
                        .map((matchup) => (
                            <div key={matchup.id} className="shadow-outline my-4 p-2">
                                <div className="p-2">
                                    <span>Participant 1</span>
                                    <SelectKnockoutParticipant
                                        matchup={matchup}
                                        updateMatchups={updateMatchups}
                                        options={selectParticipants}
                                        side="home"
                                    />
                                </div>
                                <hr />
                                <div className="p-2">
                                    <span>Participant 2</span>
                                    <SelectKnockoutParticipant
                                        matchup={matchup}
                                        updateMatchups={updateMatchups}
                                        options={selectParticipants}
                                        side="away"
                                    />
                                </div>
                            </div>
                        ))}
                </>
            )}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body className="overlay overlay-block">
                    <CategoriesFilter
                        onChange={(selected) => {
                            setCategory(selected ? selected.value : false);
                        }}
                        sportId={selectedSport.id}
                    />
                    {category && (
                        <div className="my-2">
                            <TournamentsFilter
                                onChange={(selected) => {
                                    setTournament(selected ? selected.value : false);
                                }}
                                categoryId={category}
                            />
                        </div>
                    )}
                    {tournament && (
                        <div className="my-2">
                            <SeasonsFilter
                                onChange={(selected) => {
                                    setSeason(selected ? selected.value : false);
                                }}
                                tournamentId={tournament}
                            />
                        </div>
                    )}
                    {category && season && (
                        <div className="my-2">
                            <SeasonParticipants
                                seasonId={season}
                                onChange={(selected) => {
                                    setSelectedParticipants(selected);
                                }}
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="submit"
                        onClick={() => {
                            if (selectedParticipants) {
                                updateParticipantsToCreate(selectedParticipants);
                            }
                            setShow(false);
                        }}
                        className="btn btn-primary btn-elevate"
                    >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const SelectKnockoutParticipant = ({
    options,
    matchup,
    updateMatchups,
    side,
    savedParticipants,
    setSavedParticipants,
}) => (
    <Select
        isClearable
        options={options}
        value={options.filter((sel) => sel.value === matchup[`${side}ParticipantId`])}
        onChange={(selected) => {
            const knockoutMatchupsApi = new Api('knockouts/matchups');

            knockoutMatchupsApi
                .update(matchup.id, {
                    [`${side}ParticipantId`]: selected ? selected.value : null,
                })
                .then(() => {
                    updateMatchups();
                });
        }}
    />
);

const SeasonParticipants = ({ seasonId, ...rest }) => {
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        esportsApi.getList({}, `seasons/${seasonId}/participants`).then((res) => {
            setParticipants(res);
        });
    }, [seasonId]);

    return (
        <div>
            <Select
                isMulti
                // menuIsOpen
                options={participants.map((participant) => ({
                    label: participant.player.nickname,
                    value: participant.id,
                }))}
                {...rest}
            />
        </div>
    );
};

export default observer(KnockoutParticipants);
