import Api from 'helpers/Api';
import _get from 'lodash/get';
import moment from 'moment-timezone';
import Status from 'pages/sportstree/fixtures/Status';
import { getTimeZone } from 'helpers/dates';

class FixtureHelpers {
    createOrUpdateFixtureParticipant = (fixture, participantId, side) => {
        const fixtureParticipantsApi = new Api('fixtures/participants');

        const fixtureParticipantId =
            fixture.participants &&
            _get(
                fixture.participants.find((p) => p.side === side),
                'id'
            );

        if (fixtureParticipantId) {
            return fixtureParticipantsApi.update(fixtureParticipantId, {
                participantId,
            });
        }

        return fixtureParticipantsApi.create({
            side,
            participantId,
            fixtureId: fixture.id,
        });
    };

    updateFixtureStat = (fixtureId, stat, value) => {
        const fixtureResultsApi = new Api('fixtures/results');

        return fixtureResultsApi.update(fixtureId, {
            [`stats.${stat}`]: value,
        });
    };

    createFixtureStat = (fixtureId, stat, value) => {
        const fixtureResultsApi = new Api('fixtures/results');

        return fixtureResultsApi.create({
            fixtureId,
            stats: {
                [stat]: value,
            },
        });
    };

    getStatuses = () => {
        return [
            { label: 'NOT STARTED', value: Status.NOT_STARTED },
            { label: 'LIVE', value: Status.LIVE },
            { label: 'SUSPENDED', value: Status.SUSPENDED },
            { label: 'ENDED', value: Status.ENDED },
            { label: 'CANCELLED', value: Status.CANCELLED },
            { label: 'POSTPONED', value: Status.POSTPONED },
            { label: 'FORFEIT', value: Status.FORFEIT },
            { label: 'UNKNOWN', value: Status.UNKNOWN },
        ];
    };

    getStatusesColors = (value) => {
        switch (value) {
            case Status.NOT_STARTED:
                return { color: 'white', bgColor: '#fc672e' };
            case Status.LIVE:
                return { color: 'white', bgColor: '#049934' };
            case Status.SUSPENDED:
                return { color: 'white', bgColor: '#009cc6' };
            case Status.ENDED:
                return { color: 'white', bgColor: '#e60201' };
            case Status.CANCELLED:
                return { color: 'white', bgColor: '#999999' };
            case Status.POSTPONED:
                return { color: 'white', bgColor: '#993399' };
            case Status.FORFEIT:
                return { color: 'white', bgColor: '#fada5e' };
            case Status.UNKNOWN:
                return { color: 'white', bgColor: '#636363' };
            default:
                return { color: 'white', bgColor: '#636363' };
        }
    };

    mapResponse = ({ timeOnly, kickoffISO = false }) => (response) => {
        response.data.forEach((row) => {
            const homeParticipant = row.participants.find((p) => p.side === 'home');
            const awayParticipant = row.participants.find((p) => p.side === 'away');

            if (homeParticipant) {
                row.player1 = homeParticipant.participant.player.nickname;
                row.homeTeam = homeParticipant.participant.team.name;
            }

            if (awayParticipant) {
                row.player2 = awayParticipant.participant.player.nickname;
                row.awayTeam = awayParticipant.participant.team.name;
            }

            if (!kickoffISO) {
                if (timeOnly) {
                    row.kickoff = row.kickoff ? moment(row.kickoff).tz(getTimeZone()).format('HH:mm') : '-';
                } else {
                    row.kickoff = row.kickoff ? moment(row.kickoff).tz(getTimeZone()).format('DD/MM/YYYY HH:mm') : '-';
                }
            }
        });

        return response;
    };
}

export default new FixtureHelpers();
