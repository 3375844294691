import React, { useState, useEffect } from 'react';
import ProgressBar from 'pages/sportstree/Navigation/ProgressBar';
import Step from 'pages/sportstree/Navigation/Step';
import StepIcon from 'pages/sportstree/Navigation/StepIcon';
import { toAbsoluteUrl } from 'helpers/utils';
import './styles.scss';

const Navigation = ({ depth, urls }) => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        if (depth >= 4) {
            setPercent(100);
        } else if (depth >= 3) {
            setPercent(67);
        } else if (depth >= 2) {
            setPercent(34);
        } else if (depth >= 1) {
            setPercent(0);
        }
    }, [depth]);

    return (
        <div className="card gutter-b">
            <div className="card-body" style={{ padding: '0.5rem 2.25rem 2.25rem 2.25rem' }}>
                <div className="progress-bar-wrapper">
                    <ProgressBar
                        percent={percent}
                        filledBackground="linear-gradient(90deg, #005DFF 10%, #B52EAC 50%, #FF1758 100%)"
                    >
                        <Step>
                            {({ accomplished, index }) => (
                                <StepIcon
                                    accomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/sports_on.svg')}
                                    unaccomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/sports_off.svg')}
                                    title="Sports"
                                    url={urls.get(index + 1)}
                                    accomplished={accomplished}
                                />
                            )}
                        </Step>
                        <Step>
                            {({ accomplished, index }) => (
                                <StepIcon
                                    accomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/categories_on.svg')}
                                    unaccomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/categories_off.svg')}
                                    title="Categories"
                                    url={urls.get(index + 1)}
                                    accomplished={accomplished}
                                />
                            )}
                        </Step>
                        <Step>
                            {({ accomplished, index }) => (
                                <StepIcon
                                    accomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/tournaments_on.svg')}
                                    unaccomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/tournaments_off.svg')}
                                    title="Tournaments"
                                    url={urls.get(index + 1)}
                                    accomplished={accomplished}
                                />
                            )}
                        </Step>
                        <Step>
                            {({ accomplished, index }) => (
                                <StepIcon
                                    accomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/seasons_on.svg')}
                                    unaccomplishedIcon={toAbsoluteUrl('/media/pages/sportstree/steps/seasons_off.svg')}
                                    title="Seasons"
                                    url={urls.get(index + 1)}
                                    accomplished={accomplished}
                                />
                            )}
                        </Step>
                    </ProgressBar>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
