class Status {
    NOT_STARTED = 0;
    LIVE = 1;
    SUSPENDED = 2;
    ENDED = 3;
    CANCELLED = 4;
    POSTPONED = 5;
    FORFEIT = 6;
    UNKNOWN = 10;

    getLabel(value) {
        switch (value) {
            case this.NOT_STARTED:
                return 'Not Started';
            case this.LIVE:
                return 'Live';
            case this.SUSPENDED:
                return 'Suspended';
            case this.ENDED:
                return 'Ended';
            case this.CANCELLED:
                return 'Cancelled';
            case this.POSTPONED:
                return 'Postponed';
            case this.FORFEIT:
                return 'Forfeit';
            case this.UNKNOWN:
                return 'Unknown';

            default:
                return '';
        }
    }
}

export default new Status();
