import React, { useState, useEffect } from 'react';
import Tabs from 'components/Tabs';
import List from 'components/List';
import Api from 'helpers/Api';
import CurrentSeasonsStandings from 'pages/currentseasons/Standings';
import NavigateNextIcon from 'pages/sportstree/NavigateNextIcon';
import { useHistory } from 'react-router-dom';
import { useSportsTreeStore } from 'pages/sportstree/store';
import Helmet from 'react-helmet';

const CurrentSeasonsList = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const { setCurrentSeason } = useSportsTreeStore();

    useEffect(() => {
        new Api('seasons').getList({ xtc: false, status: 1 }).then((response) => {
            setData(response);
        });
    }, []);

    const navigateToSeason = (season) => () => {
        setCurrentSeason(season);
        history.push(`/sports-tree/seasons/${season.id}`);
    };

    return (
        <div>
            <Helmet>
                <title>GT Sports - Current Seasons</title>
                <meta
                    name="keywords"
                    content="gtsports gtleagues esports e-gaming e-sports season standings cross-season"
                />
                <meta
                    name="description"
                    content="Easily review active GTSports Leagues seasons (current seasons). Stay up to date with the currently running GTSports Leagues tournaments and cross seasons standings."
                />
                <meta property="og:title" content="GT Sports - Current Seasons" />
                <meta
                    property="og:description"
                    content="Easily review active GTSports Leagues seasons (current seasons). Stay up to date with the currently running GTSports Leagues tournaments and cross seasons standings."
                />
                <meta property="og:url" content="https://gtleagues.com/current-seasons" />
            </Helmet>
            <List
                hasSelection={false}
                hasSearch={false}
                hasPagination={false}
                hasSorting={false}
                hasFiltering={false}
                title={`Current Seasons`}
                data={data}
                fields={[
                    { field: 'tournament.category.sport.name', title: 'Sport' },
                    { field: 'tournament.category.name', title: 'Category' },
                    { field: 'tournament.name', title: 'Tournament' },
                    { field: 'name', title: 'Name' },
                    {
                        field: 'stats',
                        title: 'Stats',
                        render: (rowData) => (
                            <div className="cursor-pointer" onClick={() => history.push(`/head-to-head/${rowData.id}`)}>
                                <i className="far fa-chart-bar"></i>
                            </div>
                        ),
                    },
                    {
                        field: 'season',
                        title: 'Season',
                        render: (rowData) => (
                            <div className="cursor-pointer" onClick={navigateToSeason(rowData)}>
                                <NavigateNextIcon />
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default function CurrentSeasons() {
    return (
        <div>
            <Tabs
                variant="fullWidth"
                items={[
                    {
                        label: 'Current Seasons',
                        render: () => <CurrentSeasonsList />,
                    },
                    {
                        label: 'Cross-Season Standings',
                        render: () => <CurrentSeasonsStandings />,
                    },
                ]}
            />
        </div>
    );
}
