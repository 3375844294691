import React, { useRef, useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import Api from 'helpers/Api';
import Icon from '@material-ui/core/Icon';
import Notification from 'components/Notification';
import queryString from 'query-string';
import { observer } from 'mobx-react-lite';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useAuthStore } from 'app/modules/Auth/auth.store';

const getOperator = (filter) => {
    const allowedOperators = ['between', 'in', 'eq'];

    if (allowedOperators.includes(filter.operator)) {
        return filter.operator;
    }

    return Array.isArray(filter.value) && filter.value.length > 1 ? 'in' : 'eq';
};

export const prepareParams = (query, xtc, totals, defaultOrderBy = false) => {
    let page = query.page;

    const pagesLimit = Math.ceil(totals / query.pageSize);
    if (query.page === pagesLimit && pagesLimit > 0) {
        page = query.page - 1;
    }

    const offset = page * query.pageSize;
    const limit = query.pageSize;

    const { search, filters, orderBy, orderDirection } = query;

    const queryParams = queryString.parse(window.location.search);

    const params = {
        offset,
        limit,
        xtc,
        q: search,
        filters: filters
            .map((filter) => ({
                field: filter.column.field,
                operator: getOperator(filter),
                value: Array.isArray(filter.value) ? filter.value.join(',') : filter.value,
            }))
            .filter((filter) => filter.value !== '')
            .concat(queryParams.id ? { field: 'id', value: queryParams.id, operator: 'eq' } : []),
        orderBy: orderBy ? orderBy.field : defaultOrderBy,
        orderDirection,
    };

    return [page, params];
};

const List = ({
    title,
    resource,
    crudResource,
    apiPath,
    fields,
    actions,
    columns,
    editable,
    detailPanel,
    renderActions,
    renderToolbar,
    mapResponse,
    hasPagination,
    hasSelection,
    hasSearch,
    hasFiltering,
    options,
    data,
    defaultPageSize,
    defaultOrderBy,
    ...rest
}) => {
    const store = useSportsTreeStore();
    const auth = useAuthStore();
    const esportsApiRef = useRef(new Api(apiPath || resource));
    const tableRef = useRef(null);
    const [deleteStatus, setDeleteStatus] = useState(null);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const esportsApi = esportsApiRef.current;

    useEffect(() => {
        store.setTableRef(tableRef);
    }, [store]);

    const fetchData = (query) => {
        const xtc = hasPagination;
        const [page, params] = prepareParams(query, xtc, store.totals.get(resource), defaultOrderBy);

        return esportsApi
            .getList(params)
            .then((response) => {
                if (xtc) {
                    store.updateTotals(resource, response.total);
                    store.updateResources(resource, response.data);
                } else {
                    store.updateResources(resource, response);
                }

                return {
                    page,
                    totalCount: xtc ? response.total : store.totals.get(resource),
                    data: xtc ? response.data : response,
                };
            })
            .then(mapResponse)
            .catch((e) => {
                return {
                    page: 0,
                    totalCount: 0,
                    data: [],
                };
            });
    };

    const bulkDelete = (event, rowData) => {
        const ids = rowData.map((row) => ({ id: row.id }));
        new Api(crudResource || resource)
            .deleteMany(ids)
            .then((response) => {
                store.updateTotals(resource, store.totals.get(resource) - ids.length);
                store.refreshTable();
                notifyDelete('success');
            })
            .catch(() => notifyDelete('error'));
    };

    const notifyDelete = (variant) => {
        setDeleteStatus({
            key: Math.random(),
            variant,
            message: variant === 'success' ? `Deleted successfully!` : 'Failure',
        });
    };

    let actionsColumn = [];

    if (renderActions && !!auth.user) {
        actionsColumn.push({
            field: 'Actions',
            title: 'Actions',
            render: renderActions,
            sorting: false,
            filtering: false,
        });
    }

    const massiveActions =
        hasSelection && !!auth.user
            ? [
                  {
                      icon: () => <Icon className="fa fa-trash" color="secondary" />,
                      tooltip: 'Delete',
                      onClick: (event, rowData) => {
                          if (window.confirm('Are you sure?')) {
                              bulkDelete(event, rowData);
                          }
                      },
                  },
              ]
            : [];

    let propsActions = !!auth.user ? actions : [];

    return (
        <>
            {deleteStatus && (
                <Notification key={deleteStatus.key} variant={deleteStatus.variant} message={deleteStatus.message} />
            )}
            <MaterialTable
                style={{ borderRadius: 0 }}
                tableRef={tableRef}
                title={title}
                columns={[...fields, ...actionsColumn]}
                data={data ? data : fetchData}
                actions={[...propsActions, ...massiveActions]}
                onChangeRowsPerPage={(size) => setPageSize(size)}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: hasPagination ? pageSize : null,
                    pageSizeOptions: hasPagination ? [10, 25, 50, 100] : null,
                    debounceInterval: 500,
                    paging: hasPagination,
                    selection: hasSelection && !!auth.user,
                    search: hasSearch,
                    filtering: hasFiltering,
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                        padding: '10px',
                    },
                    cellStyle: {
                        padding: '10px',
                    },
                    ...options,
                }}
                components={{
                    Toolbar: (props) => (
                        <div>
                            {title && <MTableToolbar {...props} />}
                            {renderToolbar && !!auth.user && renderToolbar()}
                        </div>
                    ),
                }}
                detailPanel={detailPanel}
                editable={editable}
                {...rest}
            />
        </>
    );
};

List.defaultProps = {
    hasPagination: true,
    hasSelection: true,
    hasSearch: true,
    hasFiltering: true,
    actions: [],
    fields: [],
    columns: [],
    editable: {},
    data: null,
    mapResponse: (data) => data,
    defaultPageSize: 10,
    defaultOrderBy: false,
};

List.propTypes = {
    resource: PropTypes.string,
};

export default observer(List);
