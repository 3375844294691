import React from 'react';
import Helmet from 'react-helmet';
import Channel from '../index';

const TV6 = () => (
    <React.Fragment>
        <Helmet>
            <title>GT Sports - TV Channel 6</title>
            <meta
                name="description"
                content="GTSports TV - Channel 6 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:title" content="GT Sports - TV Channel 6" />
            <meta
                property="og:description"
                content="GTSports TV - Channel 6 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:url" content="https://gtleagues.com/TV6" />
        </Helmet>
        <Channel channelId="gt_sports_tv6" channelName="TV6" />;
    </React.Fragment>
);

export default TV6;
