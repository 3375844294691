import React from 'react';

const BonusSystem = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <h1>New Bonus System GT Sports Leagues</h1>
            <p>We are happy to announce the new bonus system introduction in the GT Sports Leagues tournaments.</p>
            <p>The bonuses will apply to each player's wage at the end of every season according to his performance on that.</p>
            <p>To elaborate,</p>
            <ol>
                <li>All players will be rewarded with a prize pool of 17 € per tournament . In each shift the tournaments played are three , so the total of the prize pool is 51 € per shift.
                    <ul>
                        <li>Each tournament rewards the fourth first players.</li>
                        <li>1st place receives 8 €</li>
                        <li>2nd place receives 5 €</li>
                        <li>3rd place receives 3 €</li>
                        <li>4th place receives 1 €</li>
                    </ul>
                </li>
                <li>Additionally , every win rewards the players with an extra 0.30 € per win and,</li>
                <li>Every draw rewards the players with 0.15 €.</li>
            </ol>
            <p>That leads to a total of the placement in the tournament plus wins and draws.</p>
            <p>To summarize, every single player of every League will receive a bonus added to his wage according to his performance on each season. Every player will get the chance to receive at least a small portion of the prize pool by achieving a forth place or winning / drawing games.</p>
            <p>This system is deployed to give all the players motivation to try harder ,to perform better while rewarding the outstanding performances.</p>
            <p>We are committed supporters of fair play and firm believers that the happier the players are the better performance they could have.</p>
            <p>Good luck!</p>
        </div>
    );
};

export default BonusSystem;
