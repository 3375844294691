import Status from 'helpers/seasons/Status';
import Format from 'helpers/seasons/Format';
import ElimenationMode from 'helpers/seasons/ElimenationMode';
import MatchupDistribution from 'helpers/seasons/MatchupDistribution';

class SeasonsHelpers {
    getStatuses = () => {
        return [
            { label: 'NOT STARTED', value: Status.NOT_STARTED },
            { label: 'IN PROGRESS', value: Status.IN_PROGRESS },
            { label: 'PAUSED', value: Status.PAUSED },
            { label: 'COMPLETED', value: Status.COMPLETED },
        ];
    };

    getFormats = () => {
        return [
            { label: Format.getLabel(Format.ROUND_ROBIN), value: Format.ROUND_ROBIN },
            { label: Format.getLabel(Format.KNOCKOUT), value: Format.KNOCKOUT },
            // { label: Format.getLabel(Format.GROUP), value: Format.GROUP },
        ];
    };

    getEliminationModes = () => {
        return [
            {
                label: ElimenationMode.getLabel(ElimenationMode.SCORE_AGGREGATION),
                value: ElimenationMode.SCORE_AGGREGATION,
            },
            {
                label: ElimenationMode.getLabel(ElimenationMode.FIRST_TO_X_WINS),
                value: ElimenationMode.FIRST_TO_X_WINS,
            },
        ];
    };

    getMatchupDistributions = () => {
        return [
            {
                label: MatchupDistribution.getLabel(MatchupDistribution.RANDOM),
                value: MatchupDistribution.RANDOM,
            },
            {
                label: MatchupDistribution.getLabel(MatchupDistribution.FIXED),
                value: MatchupDistribution.FIXED,
            },
        ];
    };

    getStatusIcon = (value) => {
        switch (value) {
            case Status.NOT_STARTED:
                return 'not_started.svg';
            case Status.IN_PROGRESS:
                return 'in_progress.svg';
            case Status.PAUSED:
                return 'paused.svg';
            case Status.COMPLETED:
                return 'completed.svg';
            default:
                return '';
        }
    };
}

export default new SeasonsHelpers();
