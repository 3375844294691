class Status {
    NOT_STARTED = 0;
    IN_PROGRESS = 1;
    PAUSED = 2;
    COMPLETED = 3;

    getLabel(value) {
        switch (value) {
            case this.NOT_STARTED:
                return 'Not Started';
            case this.IN_PROGRESS:
                return 'In Progress';
            case this.PAUSED:
                return 'Paused';
            case this.COMPLETED:
                return 'Completed';

            default:
                return '';
        }
    }
}

export default new Status();
