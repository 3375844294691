import React from 'react';
import moment from 'moment-timezone';
import { isValidUrl, toAbsoluteUrl } from 'helpers/utils';
import './styles.scss';

const PlayerCard = ({ player, index }) => {
    return (
        <div className="relative flex flex-col p-4 profile-wrapper">
            <div className="relative shadow">
                <span className="absolute z-10 p-4 bg-white">{index + 1}</span>
                {player.absent && (
                    <span style={{ backgroundColor: '#EA4335' }} className="absolute right-0 z-10 p-4">
                        <span className="text-xs text-white">ABSENT</span>
                    </span>
                )}
                <header>
                    <div className="profile-image-wrapper">
                        <div
                            className="profile-image"
                            style={{
                                background: `url('${
                                    player.profilePicture && isValidUrl(player.profilePicture)
                                        ? player.profilePicture
                                        : toAbsoluteUrl('/media/pages/sportstree/default-player-avatar.png')
                                }')`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        />
                    </div>
                </header>

                <article className="p-4 bg-white">
                    <h2>{player.nickname}</h2>

                    <div>
                        <div>
                            <span>
                                Age: <strong>{moment().diff(player.birthdate, 'years')}</strong>
                            </span>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
};

export default PlayerCard;
