import { auth } from 'app/modules/Auth/auth.store';

class APIError extends Error {
    constructor(message, status, json) {
        super(message);

        this.name = this.constructor.name;
        this.status = status;
        this.json = json;
    }
}

const createHeadersFromOptions = (options) => {
    const requestHeaders =
        options.headers ||
        new Headers({
            Accept: 'application/json',
        });

    if (
        !requestHeaders.has('Content-Type') &&
        !(options && (!options.method || options.method === 'GET')) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set('Content-Type', 'application/json');
    }

    if (auth && auth.token) {
        requestHeaders.set('Authorization', `Bearer ${auth.token}`);
    }

    return requestHeaders;
};

const request = (url, options = {}) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then((response) =>
            response.text().then((text) => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }

            if (status === 401 && auth.token) {
                localStorage.removeItem('auth');
                window.location.href = '/';
                throw new Error('Unauthorized');
            } else if (status < 200 || status >= 300) {
                return Promise.reject(new APIError(getErrorMessage(json) || statusText, status, json));
            }
            return Promise.resolve({ status, headers, body, json });
        });
};

const getErrorMessage = (json) => {
    if (json.name === 'checkConstraintViolation' && json.errors.length > 0) {
        return json.errors[0].message;
    }

    return json && json.message;
};

export default request;
