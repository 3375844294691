import React from 'react';
import Helmet from 'react-helmet';
import Channel from '../index';

const TV3 = () => (
    <React.Fragment>
        <Helmet>
            <title>GT Sports - TV Channel 3</title>
            <meta
                name="description"
                content="GTSports TV - Channel 3 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:title" content="GT Sports - TV Channel 3" />
            <meta
                property="og:description"
                content="GTSports TV - Channel 3 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:url" content="https://gtleagues.com/TV3" />
        </Helmet>
        <Channel channelId="gt_sports_tv3" channelName="TV3" />;
    </React.Fragment>
);

export default TV3;
