import React from 'react';
import SelectInput from 'components/Form/Fields/SelectInput';
import { fieldComponents } from 'components/Form';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';

export default function CreateEditForm({
    actionsLoading,
    onSubmit,
    onHide,
    fields,
    initialValues,
    validationSchema,
    requestMapper,
}) {
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (requestMapper) {
                        onSubmit(requestMapper(values));
                        return;
                    }

                    if (values.crest && Array.isArray(values.crest)) {
                        values.crest = values.crest.length > 0 ? values.crest[0].url : null;
                    }

                    if (values.profilePicture && Array.isArray(values.profilePicture)) {
                        values.profilePicture = values.profilePicture.length > 0 ? values.profilePicture[0].url : null;
                    }

                    onSubmit(values);
                }}
            >
                {({ handleSubmit, values }) => (
                    <>
                        <Modal.Body className="overlay overlay-block">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            )}
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-12">
                                        {fields.map(({ name, label, componentType, condition, ...field }) => {
                                            if (condition && !condition(values)) {
                                                return null;
                                            }

                                            if (componentType === 'SelectInput') {
                                                return (
                                                    <Field key={name} component={SelectInput} name={name} label={label}>
                                                        {field.options.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                );
                                            }

                                            return (
                                                <Field
                                                    key={name}
                                                    component={fieldComponents[componentType]}
                                                    name={name}
                                                    label={label}
                                                    {...field}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}
