import React, { useState, useEffect } from 'react';
import DatePicker from 'components/common/DatePicker';
import { observer } from 'mobx-react-lite';
import { esportsApi } from 'helpers/Api';

const UpdateKickoff = ({ fixtureId, kickoff, ...rest }) => {
    const [value, setValue] = useState(kickoff);

    useEffect(() => {
        setValue(kickoff);
    }, [kickoff]);

    return (
        <DatePicker
            key={Math.random()}
            value={value}
            dateFormat="dd/MM/yyyy HH:mm"
            showTimeSelect
            timeIntervals={5}
            withPortal
            onChange={(date) => {
                esportsApi.update(fixtureId, { kickoff: date }, 'fixtures').then(() => {
                    setValue(date);
                });
            }}
        />
    );
};

export default observer(UpdateKickoff);
