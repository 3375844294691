import React from 'react';
import Select from 'react-select';
import { listTimeZones } from 'timezone-support';

const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const currentCountry = currentZone && currentZone.split('/')[0];

const zones = listTimeZones().sort((a, b) => {
    if (currentZone && currentZone === a) {
        return -1;
    }
    if (currentCountry && a.includes(currentCountry) && !b.includes(currentCountry)) {
        return -1;
    }
    return 0;
});

const TimeZone = ({ ...props }) => {
    return <Select {...props} options={zones.map((timezone) => ({ value: timezone, label: timezone }))} />;
};

export default TimeZone;
