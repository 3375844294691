import React from 'react';
import PropTypes from 'prop-types';
import MediaManager from 'components/common/mediamanager';
import File from 'components/Form/Fields/MediaFileInput/File';
// import { Button } from 'react-bootstrap';
import { Field } from 'formik';
import { MediaContext } from 'context/media';
import * as Styled from 'components/Form/Fields/MediaFileInput/styles';

class MediaFileInputComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            files: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.field.value && typeof props.field.value === 'string') {
            return { ...state, files: [{ name: '', url: props.field.value, type: 'image' }] };
        }

        if (props.field.value) {
            return { ...state, files: props.field.value };
        }
        return null;
    }

    setFiles = (file) => {
        let { files } = this.state;
        const { multiple } = this.props;

        const { form, field } = this.props;
        if (files.find((el) => el.name === file.name)) {
            files = files.filter((el) => el.name !== file.name);
        } else {
            if (multiple) {
                files.push(file);
            } else {
                files = [file];
            }
        }
        this.setState({ files });

        form.setFieldValue(field.name, files);
    };

    showModalBtnClick = () => {
        // const { field } = this.props;
        // showModal(field.name);
    };

    render() {
        const { field, maxSize, acceptedFileTypes, multiple, fileComponent, info } = this.props;
        const { files } = this.state;

        return (
            <MediaContext.Provider value={{ files, setFiles: this.setFiles, fileComponent }}>
                <MediaManager
                    name={field.name}
                    maxSize={maxSize}
                    acceptedFileTypes={acceptedFileTypes}
                    multiple={multiple}
                    info={info}
                />
                <Styled.FilesContainer column={fileComponent !== 'default'}>
                    {Array.isArray(files) && files.map((file, i) => <File key={`${file.name}_${i}`} file={file} />)}
                </Styled.FilesContainer>
                {/* <Button data-target="#mediaManager" data-toggle="modal" onClick={this.showModalBtnClick}>
                    <i className="fa fa-paperclip" />
                    &nbsp; Attach
                </Button> */}
            </MediaContext.Provider>
        );
    }
}

MediaFileInputComponent.displayName = 'Form.MediaFileInput';

MediaFileInputComponent.propTypes = {
    field: PropTypes.object,
    form: PropTypes.shape({
        setFieldValue: PropTypes.func,
    }),
    maxSize: PropTypes.number,
    acceptedFileTypes: PropTypes.array,
    multiple: PropTypes.bool,
    fileComponent: PropTypes.string,
};

MediaFileInputComponent.defaultProps = {
    fileComponent: 'default',
};

const MediaFileInput = (props) => <Field {...props} component={MediaFileInputComponent} />;

export default MediaFileInput;
