import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const NavigateNext = () => {
    return (
        <div
            style={{
                display: 'inline-block',
                color: '#fff',
                background: 'linear-gradient(90deg, #005DFF 10%, #B52EAC 50%, #FF1758 100%)',
                padding: 5,
                borderRadius: '50%',
            }}
        >
            <NavigateNextIcon color="inherit" />
        </div>
    );
};

export default NavigateNext;
