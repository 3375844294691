import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { observer } from 'mobx-react-lite';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from 'pages/sportstree/NavigateNextIcon';
import List from 'components/List';
import EditAction from 'components/List/EditAction';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import * as Yup from 'yup';
import { toAbsoluteUrl } from 'helpers/utils';
import { useAuthStore } from 'app/modules/Auth/auth.store';

const Categories = () => {
    const { sportId } = useParams();
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const history = useHistory();
    const store = useSportsTreeStore();
    const auth = useAuthStore();

    useEffect(() => {
        if (store.selectedSport.id === -1) {
            history.push(`/sports-tree/sports`);
        }
        store.setDepth(2);
    }, [history, store]);

    let validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        // crest: Yup.string().url(),
        sportId: Yup.number().required(),
    });

    const fields = [
        { name: 'name', label: 'Name', componentType: 'Input' },
        { name: 'crest', label: 'Crest', componentType: 'MediaFileInput' },
        { name: 'sportId', componentType: 'HiddenInput' },
    ];

    const initialValues = useMemo(() => {
        const resource = store.getResource('categories', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            name: resource ? resource.name : '',
            crest: resource ? resource.crest || '' : '',
            sportId: store.selectedSport.id,
        };
    }, [store, selectedResourceId]);

    return (
        <>
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setShowCreateEdit(false);
                    setSelectedResourceId(null);
                }}
                resource="categories"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
            />
            <List
                hasFiltering={false}
                title={`${store.selectedSport.name} Categories`}
                fields={[
                    {
                        field: 'name',
                        title: 'Name',
                        render: (rowData) => (
                            <div>
                                <img
                                    alt="Category Crest"
                                    width="25"
                                    style={{ marginRight: 10 }}
                                    src={
                                        rowData.crest
                                            ? rowData.crest
                                            : toAbsoluteUrl('/media/pages/sportstree/flags/flag_default.jpg')
                                    }
                                />
                                <span>{rowData.name}</span>
                            </div>
                        ),
                    },
                    !!auth.user
                        ? {
                              field: 'edit',
                              title: 'Edit',
                              sorting: false,
                              render: (rowData) => (
                                  <>
                                      <EditAction
                                          handleClick={() => {
                                              setSelectedResourceId(rowData.id);
                                              setShowCreateEdit(true);
                                          }}
                                      />
                                  </>
                              ),
                          }
                        : null,
                    {
                        field: 'tournamnets',
                        title: 'Tournaments',
                        sorting: false,
                        render: (rowData) => (
                            <Link
                                style={{ margin: 10 }}
                                onClick={() => {
                                    store.setSelectedCategory({
                                        id: rowData.id,
                                        name: rowData.name,
                                    });
                                    store.setDepth(3);
                                    history.push(`/sports-tree/categories/${rowData.id}/tournaments`);
                                }}
                            >
                                <NavigateNextIcon />
                            </Link>
                        ),
                    },
                ].filter(Boolean)}
                resource="categories"
                apiPath={`sports/${sportId}/categories`}
                renderToolbar={() => (
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ marginLeft: 15 }} onClick={() => setShowCreateEdit(true)}>
                            Create Category
                        </Button>
                    </div>
                )}
            />
        </>
    );
};

export default observer(Categories);
