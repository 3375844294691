import React from 'react';
import Helmet from 'react-helmet';

const AboutUs = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <Helmet>
                <title>GT Sports - About Us</title>
                <meta
                    name="description"
                    content="Premium esports content and live tournaments. Powering esports experiences for the past 10 years."
                />
                <meta property="og:title" content="GT Sports - About Us" />
                <meta
                    property="og:description"
                    content="Premium esports content and live tournaments. Powering esports experiences for the past 10 years."
                />
                <meta property="og:url" content="https://gtleagues.com/about-us" />
            </Helmet>
            <h1>About Us</h1>
            <p>
                <strong>GTSports Leagues</strong> is a premium esports content and live tournament brand. It has been
                powering esports experiences for the past <strong>10</strong> years. GTSports Leagues has generated
                record levels of engagement with its content and positive reaction throughout the esports community.
            </p>
            <p>
                Every day, we host gaming festivals, manage global esport productions, and organize entertaining content
                for our community around the world. 
            </p>
            <p>
                For everything we do, we take into account our core values. From operations down to rules of the game,
                we try to promote the following values.
            </p>
            <h3>Core Values for the game</h3>
            <br />
            <ul>
                <li>
                    <p>Delivering high quality events</p>
                </li>
                <li>
                    <p>Safeguard the credibility of our games</p>
                </li>
                <li>
                    <p>Be transparent about the game</p>
                </li>
                <li>
                    <p>Create a welcoming environment for players and fans</p>
                </li>
            </ul>
            <h3>Core Business Values</h3>
            <br />

            <p>
                <strong>Be a Reliable Partner  </strong>
            </p>
            <ul>
                <li>
                    <p>Trusted by data providers of the betting and sports industries</p>
                </li>
                <li>
                    <p>
                        Coordinate with key partners to ensure reliability, credibility and transparency over our
                        operations
                    </p>
                </li>
            </ul>
            <p>
                <strong>Availability</strong>
            </p>
            <ul>
                <li>
                    <p>1000+ weekly events</p>
                </li>
                <li>
                    <p>16-18 hours a day coverage</p>
                </li>
                <li>
                    <p>Live coverage of games</p>
                </li>
            </ul>
            <p>
                <strong>Credibility first Approach</strong>
            </p>
            <ul>
                <li>
                    <p>Events take place in gaming studios</p>
                </li>
                <li>
                    <p>Recording and reviewing of events</p>
                </li>
                <li>
                    <p>Players are not allowed to communicate with the outside world when events take place</p>
                </li>
                <li>
                    <p>
                        Coordination with Sportradar and Esic to protect the game and the fans from all possible risks
                    </p>
                </li>
            </ul>
            <p>
                <strong>Committed to Quality</strong>
            </p>
            <ul>
                <li>
                    <p>Qualification approach for new players</p>
                </li>
                <li>
                    <p>Leagues with different levels</p>
                </li>
                <li>
                    <p>Statistics and everything you need to know about the game are available on our website</p>
                </li>
                <li>
                    <p>All events have livestream coverage, including player footage</p>
                </li>
            </ul>
            <p>
                <strong>Mission Statement</strong>
            </p>
            <p>
                Our mission is to create the most credible and competitive gaming experience while at the same time we
                host the most engaging and interactive events for esports fans.
            </p>
        </div>
    );
};

export default AboutUs;
