import React from 'react';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import { toAbsoluteUrl } from 'helpers/utils';
import { getTimeZone } from 'helpers/dates';
import 'components/SimpleStatCard/style.scss';

function StatCard({ title, count, svg }) {
    return (
        <div className="simple-stat-card-wrapper">
            <div className="my-4">
                <div className="simple-stat-card-content">
                    <h2 className="leading-none">{count}</h2>
                    <h3 className="title leading-none">{title}</h3>
                    <SVG className="left-icon" src={svg} />
                    <div className="right-icon">
                        <SVG src={toAbsoluteUrl('/media/pages/dashboard/today_calendar.svg')} />
                        <div className="date absolute flex flex-column text-center">
                            <span className="text-xs leading-none">{moment().tz(getTimeZone()).format('DD')}</span>
                            <span className="text-xs leading-none uppercase">
                                {moment().tz(getTimeZone()).format('MMM')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatCard;
