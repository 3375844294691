import React, { useState, useRef, useMemo } from 'react';
import List from 'components/List';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditAction from 'components/List/EditAction';
import { exporter } from 'components/ExportButton';
import ImportButton from 'components/ImportButton';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import Api from 'helpers/Api';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { toAbsoluteUrl } from 'helpers/utils';
import * as Yup from 'yup';

let validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    // crest: Yup.string().url(),
    tagId: Yup.string().required(),
});

const Teams = () => {
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const store = useSportsTreeStore();
    const esportsApiRef = useRef(new Api('teams'));

    const fields = [
        { name: 'name', label: 'Name', componentType: 'Input' },
        { name: 'crest', label: 'Crest', componentType: 'MediaFileInput', info: 'Please upload images 150x150' },
        { name: 'sportId', componentType: 'HiddenInput' },
    ];

    const handleImport = (values) => {
        if (esportsApiRef.current) {
            esportsApiRef.current
                .createMany(values.map((value) => ({ ...value, tagId: store.selectedSport.tagId })))
                .then((res) => {
                    store.refreshTable();
                });
        }
    };

    const initialValues = useMemo(() => {
        const resource = store.getResource('teams', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            name: resource ? resource.name : '',
            crest: resource ? resource.crest || '' : '',
            tagId: store.selectedSport.tagId,
        };
    }, [store, selectedResourceId]);

    return (
        <>
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setShowCreateEdit(false);
                    setSelectedResourceId(null);
                }}
                resource="teams"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
            />
            <List
                hasFiltering={false}
                title={`${store.selectedSport.name} Teams`}
                fields={[
                    {
                        field: 'name',
                        title: 'Name',
                        sorting: false,
                        render: (rowData) => (
                            <div>
                                <img
                                    alt="team"
                                    src={
                                        rowData.crest
                                            ? rowData.crest
                                            : toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png')
                                    }
                                    style={{ width: 50, margin: 5 }}
                                />
                                {rowData.name}
                            </div>
                        ),
                    },
                ]}
                resource="teams"
                apiPath={`sport-tags/${store.selectedSport.tagId}/teams`}
                renderActions={(row) => (
                    <>
                        <EditAction
                            handleClick={() => {
                                setSelectedResourceId(row.id);
                                setShowCreateEdit(true);
                            }}
                        />
                    </>
                )}
                actions={[
                    {
                        icon: () => <GetAppIcon color="primary" />,
                        tooltip: 'Export',
                        onClick: (event, rowData) => {
                            exporter(
                                rowData.map(({ name, crest }) => ({ name, crest })),
                                'teams'
                            );
                        },
                    },
                ]}
                renderToolbar={() => (
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="ml-4">
                            <ImportButton onChange={handleImport} />
                        </div>
                        <div className="ml-4">
                            <Button onClick={() => setShowCreateEdit(true)}>Create Team</Button>
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default observer(Teams);
