import React, { useEffect, useState, useMemo } from 'react';
import Tabs from 'components/Tabs';
import Fixtures from 'pages/sportstree/fixtures';
import Participants from 'pages/sportstree/participants';
import Standings from 'pages/sportstree/standings';
import Knockouts from 'pages/sportstree/knockouts';
import KnockoutParticipants from 'pages/sportstree/knockouts/participants';
import Api from 'helpers/Api';
import Format from 'helpers/seasons/Format';
import Status from 'pages/sportstree/fixtures/Status';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useAuthStore } from 'app/modules/Auth/auth.store';

export default function SeasonDetails() {
    const {
        selectedSeason: { id },
    } = useSportsTreeStore();

    const auth = useAuthStore();

    const [format, setFormat] = useState([]);

    useEffect(() => {
        new Api('seasons').getOne(id).then((response) => {
            setFormat(response.format);
        });
    }, [id]);

    const items = useMemo(() => {
        const items = [
            {
                label: 'Fixtures',
                render: () => <Fixtures defaultStatusFilter={[String(Status.NOT_STARTED), String(Status.LIVE)]} />,
            },
            {
                label: 'Results',
                render: () => (
                    <Fixtures
                        title="Fixtures Results"
                        defaultStatusFilter={[
                            String(Status.ENDED),
                            String(Status.POSTPONED),
                            String(Status.CANCELLED),
                            String(Status.FORFEIT),
                        ]}
                        defaultKickoffOrderDirection="desc"
                        defaultMatchNrOrderDirection="desc"
                    />
                ),
            },
        ];

        if (format.includes(Format.ROUND_ROBIN)) {
            items.push({
                label: 'Participants',
                render: () => <Participants />,
            });
            items.push({
                label: 'Standings',
                render: () => <Standings />,
            });
        } else if (format.includes(Format.KNOCKOUT)) {
            if (!!auth.user) {
                items.push({
                    label: 'Participants',
                    render: () => <KnockoutParticipants />,
                });
            }

            items.push({
                label: 'Knockouts',
                render: () => <Knockouts />,
            });
        }

        return items;
    }, [format, auth.user]);

    return (
        <div>
            <Tabs centered key={id} items={items} />
        </div>
    );
}
