import request from 'helpers/request';
import queryString from 'query-string';
import config from 'config';
import moment from 'moment-timezone';
import { auth } from 'app/modules/Auth/auth.store';

class Api {
    constructor(resource) {
        this.resource = resource;
        this.limit = 1000;
        this.offset = 0;
        this.auth = auth;
    }

    getList = (params = {}, apiPath = '') => {
        const { xtc = false, offset, limit, q, orderBy, orderDirection, filters, ...rest } = params;

        let mappedFilters = {};

        if (filters && Array.isArray(filters)) {
            mappedFilters = filters.reduce((carry, filter) => {
                let { field, operator, value } = filter;

                if (value instanceof Date) {
                    value = moment(value).toISOString();
                }

                carry[field] = `${operator}:${value}`;
                return carry;
            }, {});
        }

        let sort;

        if (Array.isArray(orderBy)) {
            sort = orderBy.reduce((carry, next, index) => {
                if (index > 0) {
                    carry += ',';
                }

                carry += `${orderDirection[index] === 'asc' ? '' : '-'}${next}`;
                return carry;
            }, '');
        } else if (orderBy) {
            sort = `${orderDirection === 'asc' ? '' : '-'}${orderBy}`;
        }

        const query = {
            offset: offset || this.offset,
            limit: limit !== undefined ? limit : this.limit,
            ...(xtc && { xtc }),
            ...(q && { q }),
            ...(sort && { sort }),
            ...mappedFilters,
            ...rest,
        };

        const url = `${config.api_url}/${this.resource ? this.resource : apiPath}?${queryString.stringify(query)}`;

        return request(url).then((response) => {
            if (xtc) {
                if (!response.headers.has('x-total-count')) {
                    throw new Error('The X-Total-Count header is missing in the HTTP Response.');
                }

                return {
                    data: response.json,
                    total: parseInt(response.headers.get('x-total-count').split('/').pop(), 10),
                };
            }

            return response.json;
        });
    };

    getOne = (id, apiPath = '') =>
        request(`${config.api_url}/${this.resource ? this.resource : apiPath}/${id}`).then(({ json }) => json);

    create = (data, apiPath = '') =>
        request(`${config.api_url}/${this.resource ? this.resource : apiPath}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => json);

    update = (id, data, apiPath = '') =>
        request(`${config.api_url}/${this.resource ? this.resource : apiPath}/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(data),
        }).then(({ json }) => json);

    delete = (id) =>
        request(`${config.api_url}/${this.resource}/${id}`, {
            method: 'DELETE',
        }).then(({ json }) => json);

    createMany = (data) =>
        request(`${config.api_url}/${this.resource}`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(({ json }) => json);

    deleteMany = (ids) =>
        request(`${config.api_url}/${this.resource}`, {
            method: 'DELETE',
            body: JSON.stringify(ids),
        }).then(({ json }) => json);

    getAssociationList = (id, association) => {
        console.log(association);
    };
}

export const esportsApi = new Api();

export default Api;
