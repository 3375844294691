class MatchupDistribution {
    RANDOM = 1;
    FIXED = 2;

    getLabel(value) {
        switch (value) {
            case this.RANDOM:
                return 'Random';
            case this.FIXED:
                return 'Fixed';

            default:
                return '';
        }
    }
}

export default new MatchupDistribution();
