import React, { useEffect, useState, useMemo } from 'react';
import { useStores } from 'stores/mobx';
import List from 'components/List';
import Api from 'helpers/Api';
import SportsFilter from 'components/SportsFilter';
import { observer } from 'mobx-react-lite';
import { getFields, calcStandings } from 'helpers/standings';

function SportStandings() {
    const { common } = useStores();
    const { activeSports: sports } = common;
    const [data, setData] = useState(null);
    const [tagId, setTagId] = useState(null);
    const [activeSport, setActiveSport] = useState(sports[0] ? sports[0].id : null);

    useEffect(() => {
        if (activeSport) {
            new Api(`sports/${activeSport}/players/standings`)
                .getList({ xtc: false, onlyCurrentSeasons: true })
                .then((response) => {
                    setTagId(response.tagId);
                    setData(response.data.map(calcStandings(activeSport)));
                });
        }
    }, [activeSport]);

    let selectedSport = useMemo(
        () =>
            activeSport && sports.length > 0
                ? {
                      label: sports.find((s) => s.id === activeSport).name,
                      value: sports.find((s) => s.id === activeSport).id,
                  }
                : null,
        [activeSport, sports]
    );

    return (
        <div className="py-2">
            <div className="mb-4 md:w-1/4">
                <SportsFilter
                    label="Select a Sport"
                    value={selectedSport}
                    onChange={(selected) => setActiveSport(selected ? selected.value : null)}
                />
            </div>
            {data && activeSport && (
                <List
                    data={data}
                    hasSelection={false}
                    hasSearch={false}
                    hasPagination={false}
                    hasFiltering={false}
                    title="Cross-Season Standings"
                    options={{
                        headerStyle: {
                            padding: '15px',
                        },
                        cellStyle: {
                            padding: '15px',
                        },
                        rowStyle: (props) => {
                            return {
                                backgroundColor: props.tableData && props.tableData.id % 2 === 0 ? '#f1f1f1' : '#fff',
                            };
                        },
                    }}
                    fields={getFields(tagId).map((field) => ({
                        ...field,
                        filtering: false,
                        emptyValue: '-',
                        customSort: standingSort(field),
                    }))}
                />
            )}
        </div>
    );
}

export const standingSort = (column) => (a, b) => {
    const { field } = column;

    if (field === 'nickname') {
        return a[field].localeCompare(b[field], 'en', { ignorePunctuation: true });
    } else {
        return parseFloat(a[field]) - parseFloat(b[field]);
    }
};

export default observer(SportStandings);
