import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import Input from 'components/Form/Fields/Input.js';
import { useAuthStore } from 'app/modules/Auth/auth.store';
import { useHistory } from 'react-router-dom';

function Login(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const { login, loginSuccess } = useAuthStore();
    const history = useHistory();

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (meta, fieldname) => {
        let result = 'form-control form-control-solid h-auto py-5 px-6 ';
        if (meta.touched && meta.error) {
            result += ' is-invalid';
        }

        if (meta.touched && !meta.error) {
            result += ' is-valid';
        }

        return result;
    };

    return (
        <>
            <button onClick={() => history.push('/dashboard')} className="btn btn-primary absolute top-0 right-0 m-2">
                Back to Dashboard
            </button>
            <div className="login-form login-signin">
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">
                        <FormattedMessage id="AUTH.LOGIN.TITLE" />
                    </h3>
                    <p className="text-muted font-weight-bold">Enter your e-mail and password</p>
                </div>

                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (!values.email) {
                            // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                            errors.email = intl.formatMessage({
                                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                            });
                        }

                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                            });
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        setTimeout(() => {
                            login(values.email, values.password)
                                .then(({ json: { token, user } }) => {
                                    disableLoading();
                                    loginSuccess(token, user);
                                })
                                .catch(() => {
                                    disableLoading();
                                    setSubmitting(false);
                                    setStatus(
                                        intl.formatMessage({
                                            id: 'AUTH.VALIDATION.INVALID_LOGIN',
                                        })
                                    );
                                });
                        }, 1000);
                    }}
                >
                    {({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form className="form" noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                            {status ? (
                                <div
                                    role="alert"
                                    className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                                >
                                    <div className="alert-text font-weight-bold">{status}</div>
                                </div>
                            ) : (
                                <div
                                    role="alert"
                                    className="mb-10 alert alert-custom alert-light-info alert-dismissible"
                                >
                                    <div className="alert-text ">Fill your E-mail and your password</div>
                                </div>
                            )}

                            <div className="form-group">
                                <Field name="email" component={Input} placeholder="E-mail" label="E-mail">
                                    {({ field, form, meta }) => (
                                        <div>
                                            <input
                                                type="email"
                                                {...field}
                                                className={`${getInputClasses(meta)}`}
                                                placeholder="Enter e-mail"
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="error invalid-feedback">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group">
                                <Field name="password" component={Input} placeholder="Password" label="Password">
                                    {({ field, form, meta }) => (
                                        <div>
                                            <input
                                                type="password"
                                                {...field}
                                                className={`${getInputClasses(meta)}`}
                                                placeholder="Enter Password"
                                            />
                                            {meta.touched && meta.error && (
                                                <div className="error invalid-feedback">{meta.error}</div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    id="kt_login_signin_submit"
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                                >
                                    <span className={`${loading ? 'pr-3' : ''}`}>Sign In</span>
                                    {loading && <span className="spinner-border text-light"></span>}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
}

export default injectIntl(Login);
