import React from 'react';
import MaterialTable from 'material-table';
import Team from 'pages/sportstree/fixtures/Team';
import CategoryCrest from 'components/CategoryCrest';
import Score from 'components/Score';
import { useHistory } from 'react-router-dom';

const Table = ({ data }) => {
    const history = useHistory();

    return (
        <MaterialTable
            onRowClick={(event, rowData) => {
                history.push(`/fixtures/${rowData.id}`);
            }}
            columns={[
                { field: 'kickoff', title: 'Time', width: '1%' },
                { field: 'matchNr', title: '#', width: '1%' },
                // {
                //     field: 'season.tournament.category.sport.name',
                //     title: 'Sport',
                //     sorting: false,
                //     width: '1%',
                // },
                {
                    field: 'category',
                    title: 'Cat.',
                    sorting: false,
                    render: (rowData) => <CategoryCrest data={rowData} />,
                    width: '1%',
                },
                {
                    field: 'season.tournament.name',
                    title: 'Tour.',
                    sorting: false,
                    width: '20%',
                },
                {
                    field: 'season.name',
                    title: 'Season',
                    sorting: false,
                    width: '10%',
                },
                {
                    field: 'week',
                    title: 'Wk',
                    width: '1%',
                },
                { field: 'player1', title: 'Pl. 1', sorting: false },
                { field: 'player2', title: 'Pl. 2', sorting: false },
                {
                    field: 'homeTeam',
                    title: 'Home',
                    sorting: false,
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                    render: (rowData) => (
                        <Team
                            participant={rowData.participants.find((p) => p.side === 'home')}
                            teamName={rowData.homeTeam}
                        />
                    ),
                },
                {
                    field: 'score',
                    title: 'Score',
                    filtering: false,
                    sorting: false,
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                    width: '18%',
                    render: (rowData) => (
                        <div className="flex justify-center">
                            <Score data={rowData} />
                        </div>
                    ),
                },
                {
                    field: 'awayTeam',
                    title: 'Away',
                    headerStyle: {
                        textAlign: 'center',
                    },
                    cellStyle: {
                        textAlign: 'center',
                    },
                    sorting: false,
                    render: (rowData) => (
                        <Team
                            participant={rowData.participants.find((p) => p.side === 'away')}
                            teamName={rowData.awayTeam}
                        />
                    ),
                },
                {
                    field: 'Stats',
                    title: 'Stats',
                    sorting: false,
                    width: '1%',
                    render: () => {
                        return (
                            <>
                                <div>
                                    <i className="far fa-chart-bar"></i>
                                </div>
                            </>
                        );
                    },
                },
            ]}
            style={{ borderRadius: 0 }}
            title={''}
            data={data}
            options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                toolbar: false,
                headerStyle: {
                    padding: '5px',
                },
                cellStyle: {
                    padding: '5px',
                },
            }}
        />
    );
};
export default Table;
