import React from 'react';
import { Formik, Form, Field } from 'formik';
import AsyncSelectInput from 'components/Form/Fields/AsyncSelectInput';
import Select from 'components/Form/Fields/Select';
import Checkbox from 'components/Form/Fields/Checkbox';
import DatePicker from 'components/Form/Fields/DatePicker';
import DateTimePicker from 'components/Form/Fields/DateTimePicker';
import Input from 'components/Form/Fields/Input';
import HiddenInput from 'components/Form/Fields/HiddenInput';
import SelectInput from 'components/Form/Fields/SelectInput';
import MediaFileInput from 'components/Form/Fields/MediaFileInput';

export const fieldComponents = {
    Input,
    HiddenInput,
    Select,
    Checkbox,
    SelectInput,
    AsyncSelectInput,
    DatePicker,
    DateTimePicker,
    MediaFileInput,
};

const FormComponent = ({ fields, initialValues, validationSchema, onSubmit }) => (
    <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema ? validationSchema : null}
        onSubmit={(values, { ...rest }) => {
            onSubmit(values, { ...rest });
        }}
    >
        {({ handleSubmit, values }) => (
            <>
                <Form className="form form-label-right">
                    <div className="form-group row">
                        <div className="col-lg-12">
                            {fields.map(({ name, label, componentType, condition, ...field }) => {
                                if (condition && !condition(values)) {
                                    return null;
                                }

                                return (
                                    <Field
                                        key={name}
                                        component={fieldComponents[componentType]}
                                        name={name}
                                        label={label}
                                        {...field}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Form>
                {/* <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary btn-elevate">
                    Save
                </button> */}
            </>
        )}
    </Formik>
);

export default FormComponent;
