import React, { useEffect, useState } from 'react';
import TournamentsBrackets from '@betadvanced/tournaments-brackets';
import Api from 'helpers/Api';
import { toAbsoluteUrl, isValidUrl } from 'helpers/utils';
import Status from 'pages/sportstree/fixtures/Status';
import ElimenationMode from 'helpers/seasons/ElimenationMode';

const Brackets = ({ knockout, knockoutId }) => {
    const [matchups, setMatchups] = useState([]);

    useEffect(() => {
        new Api(`knockouts/${knockoutId}/brackets`).getList().then((response) => {
            const result = response.reduce((carry, bracket, currentIndex) => {
                bracket.matchups.forEach((matchup) => {
                    carry.push({ roundNr: currentIndex + 1, ...matchup, bracketRound: bracket.round });
                });

                return carry;
            }, []);

            setMatchups(result);
        });
    }, [knockoutId]);

    const calcTotalRounds = (matchup) => {
        let totalRounds;

        if (knockout.eliminationMode === 1) {
            totalRounds = knockout.totalRounds;
        } else if (knockout.eliminationMode === 2) {
            totalRounds = knockout.progressionRounds;

            if (knockout.singleGameFinal && matchup.bracketRound === 1) {
                totalRounds = 1;
            }
        }

        return totalRounds;
    };

    const computedMatches = matchups
        .sort((a, b) => a.id - b.id)
        .map((matchup, index) => ({
            id: +matchup.id,
            round: matchup.roundNr,
            match: index,
            players: [
                {
                    id: +matchup.homeParticipantId,
                    name: getParticipantName(matchup, 'home'),
                    crest:
                        matchup.homeParticipant &&
                        matchup.homeParticipant.participant.team.crest &&
                        isValidUrl(matchup.homeParticipant.participant.team.crest)
                            ? matchup.homeParticipant.participant.team.crest
                            : toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png'),
                    seed: +matchup.homeParticipantId,
                },
                {
                    id: +matchup.awayParticipantId,
                    name: getParticipantName(matchup, 'away'),
                    crest:
                        matchup.awayParticipant &&
                        matchup.awayParticipant.participant.team.crest &&
                        isValidUrl(matchup.awayParticipant.participant.team.crest)
                            ? matchup.awayParticipant.participant.team.crest
                            : toAbsoluteUrl('/media/pages/sportstree/team_crest/team_crest_default.png'),
                    seed: +matchup.awayParticipantId,
                },
            ],
            score: calculateScore(
                matchup.fixtures,
                knockout.eliminationMode,
                calcTotalRounds(matchup),
                matchup.homeParticipant,
                matchup.awayParticipant
            ),
            winnerId: +matchup.winnerId,
        }));

    return (
        <div>
            <TournamentsBrackets matches={computedMatches} />
        </div>
    );
};

function getParticipantName(matchup, side) {
    const participant = `${side}Participant`;
    const participantId = `${side}ParticipantId`;
    const parentId = `${side}ParentMatchupId`;

    if (!matchup[participantId] && !matchup[parentId]) {
        return '-';
    } else if (!matchup[participantId] && matchup[parentId]) {
        return `Winner of ${matchup[parentId]}`;
    } else if (matchup[participantId]) {
        return `${matchup[participant].participant.player.nickname} (${matchup[participant].participant.team.name})`;
    }
}

function calculateScore(fixtures, eliminationMode, totalRounds, matchupHomeParticipant, matchupAwayParticipant) {
    let participants = {};
    const scoreAggregate = eliminationMode === ElimenationMode.SCORE_AGGREGATION;
    if (fixtures.length === 0) {
        const scores = [];

        scores.push(Array.from({ length: totalRounds }).fill('-'));
        scores.push(Array.from({ length: totalRounds }).fill('-'));

        return scores;
    }

    fixtures.forEach((item, index) => {
        let homeParticipant = item.fixture.participants.find((p) => p.side === 'home');
        let awayParticipant = item.fixture.participants.find((p) => p.side === 'away');

        if (item.fixture.status === Status.ENDED || item.fixture.status === Status.LIVE) {
            if (!participants[homeParticipant.participant.id]) {
                participants[homeParticipant.participant.id] = {
                    total: 0,
                };
            }

            if (!participants[awayParticipant.participant.id]) {
                participants[awayParticipant.participant.id] = {
                    total: 0,
                };
            }

            if (item.fixture.result && item.fixture.result.stats) {
                participants[homeParticipant.participant.id][index + 1] =
                    Number(item.fixture.result.stats.home_score) || 0;
                participants[awayParticipant.participant.id][index + 1] =
                    Number(item.fixture.result.stats.away_score) || 0;

                if (scoreAggregate) {
                    participants[homeParticipant.participant.id].total +=
                        Number(item.fixture.result.stats.home_score) || 0;
                    participants[awayParticipant.participant.id].total +=
                        Number(item.fixture.result.stats.away_score) || 0;
                } else {
                    const homeScore = Number(item.fixture.result.stats.home_score) || 0;
                    const awayScore = Number(item.fixture.result.stats.away_score) || 0;

                    if (homeScore > awayScore) {
                        participants[homeParticipant.participant.id].total += 1;
                    }

                    if (awayScore > homeScore) {
                        participants[awayParticipant.participant.id].total += 1;
                    }
                }
            }
        } else {
            if (!participants[homeParticipant.participant.id]) {
                participants[homeParticipant.participant.id] = {};
            }

            if (!participants[awayParticipant.participant.id]) {
                participants[awayParticipant.participant.id] = {};
            }

            participants[homeParticipant.participant.id][index + 1] = '-';
            participants[awayParticipant.participant.id][index + 1] = '-';
        }
    });

    return [
        participants[matchupHomeParticipant.participant.id],
        participants[matchupAwayParticipant.participant.id],
    ].map(Object.values);
}

export default Brackets;
