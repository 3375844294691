import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

export default function TabsComponent({ items, onChangeCb, defaultValue = 0, ...rest }) {
    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChangeCb && onChangeCb(newValue);
    };

    return (
        <div>
            <AppBar position="static" color="transparent" style={{ background: '#fff' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    {...rest}
                >
                    {items.map((item, index) => (
                        <Tab key={item.label} label={item.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </AppBar>
            {items.map((item, index) => (
                <TabPanel key={item.label} value={value} index={index}>
                    {item.render()}
                </TabPanel>
            ))}
        </div>
    );
}
