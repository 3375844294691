import { auth } from 'app/modules/Auth/auth.store';

export default function setupAxios(axios) {
    axios.interceptors.request.use(
        (config) => {
            const { token } = auth;

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
        function (response) {
            // Do something with response data
            return response;
        },
        function (error) {
            // Do something with response error
            if (error.response.status === 401) {
                localStorage.removeItem('auth');
                window.location.href = '/';
            }

            return Promise.reject(error);
        }
    );
}
