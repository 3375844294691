import React from 'react';
import Api from 'helpers/Api';
import { action, decorate, observe, observable } from 'mobx';
import { auth } from 'app/modules/Auth/auth.store';
import { sportstree } from 'pages/sportstree/store';

class Knockouts {
    participantsToCreate = observable.array();
    knockout;
    auth;
    sportstree;

    constructor(auth, sportstree) {
        this.auth = auth;
        this.sportstree = sportstree;
        this.knockoutApi = new Api('knockouts');

        observe(sportstree, 'selectedSeason', (change) => {
            this.clearParticipantsToCreate();

            if (change.newValue.id !== -1) {
                this.knockoutApi
                    .getList({ seasonId: change.newValue.id })
                    .then(
                        action((response) => {
                            this.setKnockout(response[0]);
                        })
                    )
                    .catch((e) => {
                        console.error(e);
                    });
            }
        });
    }

    updateParticipantsToCreate(values) {
        if (Array.isArray(values)) {
            const data = values.reduce((unique, item) => {
                return unique.some((i) => item.value === i.value) ? unique : [...unique, item];
            }, this.participantsToCreate);

            this.participantsToCreate.replace(data);
        }
    }

    removeParticipantToCreate(id) {
        const data = this.participantsToCreate.filter((item) => item.value !== id);
        this.participantsToCreate.replace(data);
    }

    clearParticipantsToCreate() {
        this.participantsToCreate.replace([]);
    }

    setKnockout(value) {
        this.knockout = value;
    }
}

decorate(Knockouts, {
    knockout: observable,
    participantsToCreate: observable,
    updateParticipantsToCreate: action.bound,
    removeParticipantToCreate: action.bound,
    clearParticipantsToCreate: action.bound,
    setKnockout: action.bound,
});

const knockouts = new Knockouts(auth, sportstree);

const storeContext = React.createContext(knockouts);

export const useKnockoutsStore = () => React.useContext(storeContext);

export default Knockouts;
