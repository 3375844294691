import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useDashboardStore } from 'pages/dashboard/store';
import moment from 'moment-timezone';
// import { getTimeZone } from 'helpers/dates';
import { toAbsoluteUrl } from 'helpers/utils';
import 'pages/dashboard/style.scss';

const FixturesCount = () => {
    const { getFixturesCount, setFixturesCount, fixturesCount, totalFixturesCount, activeDay } = useDashboardStore();

    useEffect(() => {
        getFixturesCount();
    }, [getFixturesCount, setFixturesCount, activeDay]);

    if (fixturesCount.length === 0) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-md-7 mb-2">
                <div
                    className="flex flex-wrap text-center fixtures-count-wrapper"
                    style={{
                        color: '#fff',
                        background: 'linear-gradient(90deg, #005DFF 10%, #B52EAC 50%, #FF1758 100%)',
                    }}
                >
                    {fixturesCount.map((fc, index) => (
                        <React.Fragment key={fc.date}>
                            {index === 5 && <div className="white-line" />}
                            <div
                                className={clsx('flex flex-col w-1/5 p-2 fixtures-count-item', {
                                    active: index === 0,
                                })}
                            >
                                <div className="text-base leading-none font-medium uppercase">
                                    {moment(fc.date).format('DD MMM')}.
                                </div>
                                <div className="text-3xl leading-none font-medium">{fc.count}</div>
                                <div className="text-sm leading-none font-light">FIXTURES</div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="col-md-5 mb-2">
                <div
                    style={{
                        color: '#fff',
                    }}
                    className="flex flex-1 flex-center flex-col h-full fixtures-total-count-wrapper py-4"
                >
                    <div className="text-5xl leading-none">{totalFixturesCount}</div>
                    <div className="text-xl w-64">Next 10 Days Scheduled Fixtures</div>
                    <SVG src={toAbsoluteUrl('/media/pages/sportstree/info_panel/logos/upcoming_matches.svg')} />
                </div>
            </div>
        </div>
    );
};

export default observer(FixturesCount);
