import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import BootstrapButton from 'components/BootstrapButton';
import { parse } from 'papaparse';

const ImportButton = ({ delimiter = ',', onChange }) => {
    const handleChange = (e) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            parse(file, {
                skipEmptyLines: true,
                delimiter,
                complete: (result) => {
                    const { data } = result;

                    const keys = data[0];
                    const primaryKey = keys[0];

                    const values = data.slice(1).map((row) => {
                        const value = {};

                        keys.forEach((key, index) => {
                            value[key] = row[index] === '' ? null : row[index];
                        });

                        return value;
                    });

                    const ids = values.map((v) => v[primaryKey]);

                    if (onChange) {
                        onChange(values, ids);
                    }
                },
            });
        }
    };

    return (
        <>
            <input
                key={Math.random()}
                type="file"
                id="text-button-file"
                style={{ display: 'none' }}
                accept=".csv"
                onChange={handleChange}
            />
            <label htmlFor="text-button-file" style={{ margin: 0 }}>
                {/* <Button variant="outlined" color="primary" component="span"> */}
                <BootstrapButton variant="outlined" color="secondary" component="span">
                    <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '1rem' }} />
                    <span style={{ paddingLeft: '0.5em' }}>Import</span>
                </BootstrapButton>
            </label>
        </>
    );
};

export default ImportButton;
