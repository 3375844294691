import { auth } from 'app/modules/Auth/auth.store';
import moment from 'moment-timezone';

export function getTimeZone() {
    let defaultTimeZone;

    try {
        defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
        defaultTimeZone = 'Europe/Madrid';
    }

    const timeZone = auth.user ? auth.user.timezone : defaultTimeZone;

    return timeZone;
}

export const getNextNDays = (n) => {
    const now = moment().tz(getTimeZone());

    const dates = [];

    for (let i = 0; i < n; i++) {
        if (i === 0) {
            dates.push(now);
        } else {
            dates.push(now.clone().add(i, 'days'));
        }
    }

    return dates;
};
