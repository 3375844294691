import React from 'react';
import _get from 'lodash/get';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import { action, computed, decorate, observable, observe } from 'mobx';
import { persist } from 'mobx-persist';
import { auth } from 'app/modules/Auth/auth.store';
import Api from 'helpers/Api';

export default class SportsTree {
    constructor(auth) {
        this.auth = auth;
        this.sportsApi = new Api('sports');

        observe(auth, 'user', (change) => {
            if (!change.newValue) {
                this.clear();
            }
        });

        observe(this, 'selectedSport', (change) => {
            if (change.newValue && change.newValue.id !== -1 && !this.activeSport) {
                this.sportsApi.getOne(change.newValue.id).then(
                    action((response) => {
                        this.sports = [...this.sports, response];
                        this.setSelectedSport({
                            id: response.id,
                            name: response.name,
                            tagId: response.tag.id,
                        });
                    })
                );
            }
        });
    }

    depth = -1;
    sports = observable.array();
    categories = observable.array();
    tournaments = observable.array();
    seasons = observable.array();
    standings = observable.array();
    fixtures = observable.array();
    participants = observable.array();
    teams = observable.array();
    players = observable.array();
    totals = observable.map();
    tableRef;
    selectedSport = observable.object({
        id: -1,
        name: '',
        tagId: -1,
    });
    selectedCategory = SportsTree.getInitialObject();
    selectedTournament = SportsTree.getInitialObject();
    selectedSeason = SportsTree.getInitialObject();

    get urlByDepth() {
        const urlMap = new Map();

        urlMap.set(1, `/sports-tree/sports`);

        if (this.depth > 1) {
            urlMap.set(2, `/sports-tree/sports/${this.selectedSport.id}/categories`);
        }

        if (this.depth > 1) {
            urlMap.set(2, `/sports-tree/sports/${this.selectedSport.id}/categories`);
        }

        if (this.depth > 2) {
            urlMap.set(3, `/sports-tree/categories/${this.selectedCategory.id}/tournaments`);
        }

        if (this.depth > 3) {
            urlMap.set(4, `/sports-tree/tournaments/${this.selectedTournament.id}/seasons`);
        }

        return urlMap;
    }

    get breadcrumbsItems() {
        let data = [];

        data.push({
            text: 'Sports',
            active: this.depth >= 2,
            link: this.urlByDepth.get(1),
        });

        if (this.depth > 1) {
            data.push({
                text: this.selectedSport.name,
                active: this.depth !== 2,
                link: this.urlByDepth.get(2),
            });
        }

        if (this.depth > 2) {
            data.push({
                text: this.selectedCategory.name,
                active: this.depth !== 3,
                link: this.urlByDepth.get(3),
            });
        }

        if (this.depth > 3) {
            data.push({
                text: this.selectedTournament.name,
                active: this.depth !== 4,
                link: this.urlByDepth.get(4),
            });
        }

        if (this.depth > 4) {
            data.push({
                text: this.selectedSeason.name,
                active: false,
            });
        }

        return data;
    }

    get activeSport() {
        return this.sports.find((sport) => sport.id === this.selectedSport.id);
    }

    setDepth(value) {
        this.depth = value;
    }

    setSelectedSport(value) {
        this.selectedSport = value;
    }

    setSelectedCategory(value) {
        this.selectedCategory = value;
    }

    setSelectedTournament(value) {
        this.selectedTournament = value;
    }

    setSelectedSeason(value) {
        this.selectedSeason = value;
    }

    updateResource(resource, data) {
        let editedIndex = this[resource].findIndex((resource) => resource.id === data.id);

        const resources = this[resource]
            .slice(0, editedIndex)
            .concat([data])
            .concat(this[resource].slice(editedIndex + 1));

        this[resource] = resources;
    }

    updateResources(resource, data) {
        this[resource].replace(data);
    }

    updateTotals(resource, total) {
        this.totals.set(resource, total);
    }

    setTableRef(value) {
        this.tableRef = value;
    }

    refreshTable() {
        this.tableRef.current && this.tableRef.current.onQueryChange();
    }

    setCurrentSeason = (season) => {
        this.setSelectedSport({
            id: season.tournament.category.sport.id,
            name: season.tournament.category.sport.name,
        });

        this.setSelectedCategory({
            id: season.tournament.category.id,
            name: season.tournament.category.name,
        });

        this.setSelectedTournament({
            id: season.tournament.id,
            name: season.tournament.name,
        });

        this.setSelectedSeason({
            id: season.id,
            name: season.name,
        });

        this.setDepth(5);
    };

    getResource(resource, id) {
        return this[resource].find((resource) => resource.id === id);
    }

    getFixtureById(id) {
        return this.fixtures.find((f) => f.id === id);
    }

    updateFixtureStat(id, stat, value) {
        const fixture = this.getFixtureById(id);

        if (fixture) {
            if (_get(fixture, 'result.id')) {
                this.callUpdateFixtureStat(fixture, stat, value);
            } else {
                this.callCreateFixtureStat(fixture, stat, value);
            }
        }
    }

    callUpdateFixtureStat(fixture, stat, value) {
        return fixtureHelpers
            .updateFixtureStat(fixture.result.id, stat, value)
            .then(
                action((response) => {
                    fixture.result.stats[stat] = response.stats[stat];
                })
            )
            .catch((error) => {
                if (error.status === 503) {
                    this.callUpdateFixtureStat(fixture, stat, value);
                }
            });
    }

    callCreateFixtureStat(fixture, stat, value) {
        return fixtureHelpers
            .createFixtureStat(fixture.id, stat, value)
            .then(
                action((response) => {
                    fixture.result.id = response.id;
                    fixture.result.stats = response.stats;
                })
            )
            .catch(() => {
                this.callUpdateFixtureStat(fixture, stat, value);
            });
    }

    clear() {
        this.selectedSport = SportsTree.getInitialObject();
        this.selectedCategory = SportsTree.getInitialObject();
        this.selectedTournament = SportsTree.getInitialObject();
        this.selectedSeason = SportsTree.getInitialObject();
    }

    static getInitialObject() {
        return observable.object({
            id: -1,
            name: '',
        });
    }
}

decorate(SportsTree, {
    depth: [persist, observable],
    totals: observable,
    sports: [persist('object'), observable],
    categories: observable,
    tournaments: observable,
    seasons: observable,
    standings: observable,
    fixtures: observable,
    participants: observable,
    teams: observable,
    players: observable,
    tableRef: observable,
    selectedSport: [persist('object'), observable],
    selectedCategory: [persist('object'), observable],
    selectedTournament: [persist('object'), observable],
    selectedSeason: [persist('object'), observable],
    breadcrumbsItems: computed,
    activeSport: computed,
    urlByDepth: computed,
    setDepth: action.bound,
    setSelectedSport: action.bound,
    setSelectedCategory: action.bound,
    setSelectedTournament: action.bound,
    setSelectedSeason: action.bound,
    updateResources: action.bound,
    updateResource: action.bound,
    updateTotals: action.bound,
    setTableRef: action.bound,
    updateFixtureStat: action.bound,
});

export const sportstree = new SportsTree(auth);

if (process.env.NODE_ENV === 'development') window.sportstree = sportstree;

const storeContext = React.createContext(sportstree);

export const useSportsTreeStore = () => React.useContext(storeContext);
