import React from 'react';
import moment from 'moment-timezone';
import { getTimeZone } from 'helpers/dates';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const DatesPagination = ({ onChange }) => {
    const [currentDate, setCurrentDate] = React.useState(moment().tz(getTimeZone()));
    const [selectedDate, setSelectedDate] = React.useState(currentDate);

    const dates = React.useMemo(() => {
        const dates = [];
        for (let i = 4; i > 0; i--) {
            dates.push(currentDate.clone().subtract(i, 'days'));
        }

        dates.push(currentDate);

        return dates;
    }, [currentDate]);

    React.useEffect(() => {
        onChange(selectedDate);
    }, [onChange, selectedDate]);

    return (
        <div className="flex justify-center p-4 my-4 bg-white">
            <button
                className="p-0 bg-transparent border-none shadow-none outline-none"
                onClick={() => {
                    setCurrentDate(currentDate.clone().subtract(1, 'days'));
                    setSelectedDate(dates[0].clone().subtract(1, 'days'));
                }}
            >
                <ArrowBackIosIcon />
            </button>
            {dates.map((date) => (
                <div
                    className="px-2 cursor-pointer"
                    style={{
                        borderBottom: date.toISOString() === selectedDate.toISOString() ? '1px solid #222' : null,
                    }}
                    onClick={() => {
                        setSelectedDate(date);
                    }}
                    key={date.toISOString()}
                >
                    {date.format('DD MMM')}
                </div>
            ))}

            <button
                className="p-0 bg-transparent border-none shadow-none outline-none"
                disabled={moment().diff(currentDate, 'days') === 0}
                onClick={() => {
                    setCurrentDate(currentDate.clone().add(1, 'days'));
                    setSelectedDate(dates[4].clone().add(1, 'days'));
                }}
            >
                <ArrowForwardIosIcon />
            </button>
        </div>
    );
};

export default DatesPagination;
