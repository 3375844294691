import config from 'config';
import axios from 'axios';
import { setupAxios } from 'stores/mobx';

class MediaApi {
    constructor(resource) {
        this.ajax = axios.create({});
        setupAxios(this.ajax);
    }

    uploadMedia(file, onUploadProgressCallback) {
        let data = new FormData();
        data.append('file', file);

        return this.ajax.post(`${config.api_url}/upload`, data, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgressCallback(percentCompleted);
            },
        });
    }
}

export default new MediaApi();
