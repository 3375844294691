import styled from 'styled-components';

export const Container = styled.span`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #bdbdbd;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

export const ThumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 15px;
`;

export const ThumbInner = styled.div`
    overflow: hidden;
`;

export const ThumbImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

export const ThumbUnknownFile = styled.div`
    padding: 16px;
    width: 138px;
    height: 138px;
    background-color: rgba(9, 30, 66, 0.3);
    color: #fff;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    opacity: ${props => (props.hasError ? 1 : 0)};
    padding: 16px;
    box-shadow: ${props => (props.hasError ? 'inset 0 0 0 4px rgb(255, 30, 66)' : 'none')};

    transition: background 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, border-color 0.3s ease 0s;
`;

export const ErrorOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0 0 4px rgb(255, 30, 66);
    padding: 16px;
`;

export const RemoveIcon = styled.i`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    font-size: 30px;
    color: rgb(255, 30, 66);
    cursor: pointer;
`;

export const Title = styled.p`
    opacity: 0;
    color: white;
    visibility: hidden;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    transition: opacity 0.3s ease 0s;
`;

export const UnknownFileTitle = styled(Title)`
    opacity: 1;
    visibility: visible;
`;

export const Thumb = styled.div`
    display: inline-flex;
    box-sizing: border-box;
    flex: 0 0 138px;
    height: 138px;
    margin: 2px;
    position: relative;
    outline: 1px solid #ddd;
    cursor: ${props => (props.hasError ? 'default' : 'pointer')};

    &:hover {
        ${Overlay} {
            background-color: rgba(9, 30, 66, 0.5);
            opacity: 0.9;
        }

        ${Title} {
            opacity: 1;
            visibility: visible;
        }
    }
`;

export const Top = styled.div``;

export const Bottom = styled.div`
    position: absolute;
    bottom: 16px;

    & p {
        opacity: 1;
        visibility: visible;
    }
`;

export const CheckIcon = styled.i`
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 22px;
    height: 22px;
    color: #2684ff;
    font-size: 22px;
    animation: iconBounce 0.1s linear;

    @keyframes iconBounce {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(0.65);
        }
    }
`;
