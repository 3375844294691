import React from 'react';

const getColor = (form) => {
    switch (form) {
        case 'W':
            return '#219653';
        case 'L':
            return '#EB5757';
        case 'D':
            return '#EAB000';
        default:
            return '#bbb';
    }
};

const Form = ({ form }) => (
    <div className="flex flex-row">
        {form &&
            form.form.split(' ').map((form, index) => (
                <div
                    key={index}
                    className={`relative m-1 p-1 text-xs w-6 h-6 flex flex-wrap flex-center ${
                        index === 0 ? 'active-indicator' : ''
                    }`}
                    style={{
                        backgroundColor: getColor(form),
                    }}
                >
                    <span className="leading-none text-white">{form}</span>
                </div>
            ))}
    </div>
);

export default Form;
