import React from 'react';
import { loadJS } from 'helpers/utils';

const properties = {
    width: '100%',
    height: 800,
    autoplay: false,
};

const Channel = ({ channelId, channelName }) => {
    React.useEffect(() => {
        const twitchEmbed = () => {
            new window.Twitch.Embed('twitch-embed-1', {
                ...properties,
                channel: channelId,
                autoplay: true,
                parent: [],
            });
        };

        loadJS('https://embed.twitch.tv/embed/v1.js', twitchEmbed, document.body);
    }, [channelId]);

    return (
        <div className="max-w-screen-md mx-auto">
            <div id="channel-1" className="py-6">
                <div className="flex items-center">
                    Live Streaming: <span className="gt-bg m-1 p-1 text-white">{channelName}</span>
                </div>
                <div id="twitch-embed-1"></div>
            </div>
        </div>
    );
};

export default Channel;
