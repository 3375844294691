import React from 'react';
import clsx from 'clsx';
// import SVG from 'react-inlinesvg';
import _get from 'lodash/get';
// import Form from 'pages/fixture/Form';
// import Stats from 'pages/fixture/Stats';
// import StatsPopover, { useStatsPopover } from 'pages/fixture/StatsPopover';
import ReactSelect from 'react-select';
import Crest from 'components/Crest';
import Status from 'pages/sportstree/fixtures/Status';
import moment from 'moment-timezone';
// import { toAbsoluteUrl } from 'helpers/utils';
import { getTimeZone } from 'helpers/dates';
import TVLink from 'components/TVLink';

const Banner = ({
    fixture,
    homeParticipant,
    awayParticipant,
    participants,
    onHomeParticipantChange,
    onAwayParticipantChange,
}) => {
    // const { anchorEl, handleClick, handleClose } = useStatsPopover();

    // const sportId = _get(fixture, 'season.tournament.category.sport.id');

    const [homeSelectedParticipant, awaySelectedParticipant] = React.useMemo(() => {
        const homeSelectedParticipant = {
            id: homeParticipant.participant.id,
            label: homeParticipant.participant.player.nickname,
        };

        const awaySelectedParticipant = {
            id: awayParticipant.participant.id,
            label: awayParticipant.participant.player.nickname,
        };

        return [homeSelectedParticipant, awaySelectedParticipant];
    }, [homeParticipant, awayParticipant]);
    return (
        <>
            <div className="relative flex justify-between mb-4 gt-border banner-wrapper">
                <div className="relative home-crest">
                    <div className="transparent-bg" />
                    <Crest margin={0} participant={homeParticipant} />
                </div>
                <div className="z-10 flex items-center justify-center flex-1 py-4 text-center">
                    {fixture && (
                        <div
                            className={clsx('match-number-bg', {
                                ended: fixture.status === Status.ENDED,
                                not_started: fixture.status === Status.NOT_STARTED,
                                live: fixture.status === Status.LIVE,
                            })}
                        />
                    )}
                    <span className="match-number">{_get(fixture, 'matchNr')}</span>
                    <div className="m-4 participant-wrapper">
                        <div className="flex flex-col items-center justify-end flex-1 md:flex-row">
                            <div className="m-2">
                                <h6>{homeParticipant.participant.team.name}</h6>
                                <h4 className="font-bold">{homeParticipant.participant.player.nickname}</h4>
                                {participants && (
                                    <div className="w-48">
                                        <ReactSelect
                                            options={participants.filter(
                                                (participant) => participant.value !== awayParticipant.participant.id
                                            )}
                                            value={homeSelectedParticipant}
                                            onChange={onHomeParticipantChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="m-2 crest-wrapper">
                                <Crest participant={homeParticipant} />
                            </div>
                            {fixture && (
                                <React.Fragment>
                                    {(fixture.status === Status.ENDED || fixture.status === Status.LIVE) && (
                                        <span
                                            className={clsx('score px-4', {
                                                ended: fixture.status === Status.ENDED,
                                                live: fixture.status === Status.LIVE,
                                            })}
                                        >
                                            {_get(fixture, 'result.stats.home_score', '')}
                                        </span>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {fixture && (
                        <div className="m-4 date-time-wrapper">
                            <p className="m-0 text-xl font-bold leading-7">
                                {moment(fixture.kickoff).tz(getTimeZone()).format('MMMM D')}
                            </p>
                            <p className="m-0 text-xl font-bold leading-7">
                                {moment(fixture.kickoff).tz(getTimeZone()).format('HH:mm')}
                            </p>
                            <p className="m-0 text-xl leading-7">
                                Week: <span className="font-bold">{fixture.week}</span>
                            </p>
                            {fixture.channel && <TVLink channel={fixture.channel} />}
                        </div>
                    )}
                    <div className="m-4 participant-wrapper">
                        <div className="flex flex-col-reverse items-center justify-start flex-1 md:flex-row">
                            {fixture && (
                                <React.Fragment>
                                    {(fixture.status === Status.ENDED || fixture.status === Status.LIVE) && (
                                        <span
                                            className={clsx('score px-4', {
                                                ended: fixture.status === Status.ENDED,
                                                live: fixture.status === Status.LIVE,
                                            })}
                                        >
                                            {_get(fixture, 'result.stats.away_score', '')}
                                        </span>
                                    )}
                                </React.Fragment>
                            )}
                            <div className="m-2 crest-wrapper">
                                <Crest participant={awayParticipant} />
                            </div>
                            <div className="m-2">
                                <h6>{awayParticipant.participant.team.name}</h6>
                                <h4 className="font-bold">{awayParticipant.participant.player.nickname}</h4>
                                {participants && (
                                    <div className="w-48">
                                        <ReactSelect
                                            options={participants.filter(
                                                (participant) => participant.value !== homeParticipant.participant.id
                                            )}
                                            value={awaySelectedParticipant}
                                            onChange={onAwayParticipantChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative away-crest">
                    <div className="transparent-bg" />
                    <Crest margin={0} participant={awayParticipant} />
                </div>
                {/* <div className="flex flex-row flex-center stats-wrapper gt-border">
                    <div className="flex md:mr-20">
                        <Form form={homeForm} />
                    </div>
                    {fixture.status === Status.NOT_STARTED && (
                        <>
                            <div
                                onClick={handleClick}
                                className="absolute flex rounded-full cursor-pointer flex-center gt-border games-played-number"
                            >
                                <div className="absolute p-2 games-played-info-number">
                                    <span className="mb-2 gradient-color">
                                        {stats && Array.isArray(stats) && stats.length > 0
                                            ? stats[0].games_played
                                            : '-'}
                                    </span>
                                </div>
                            </div>
                            <div className="pointer-events-none games-played-btn">Games Played</div>
                        </>
                    )}
                    {(fixture.status === Status.ENDED || fixture.status === Status.LIVE) && (
                        <>
                            <div className="absolute flex rounded-full flex-center gt-border stats-wrapper">
                                <div className="p-2">
                                    <SVG
                                        src={toAbsoluteUrl(`/media/pages/sportstree/seasons_statuses/completed.svg`)}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="flex md:ml-20">
                        <Form form={awayForm} />
                    </div>
                </div>
                {fixture.status === Status.NOT_STARTED && (
                    <StatsPopover anchorEl={anchorEl} handleClose={handleClose}>
                        {stats && Array.isArray(stats) && (
                            <Stats
                                sportId={sportId}
                                homeParticipant={homeParticipant}
                                awayParticipant={awayParticipant}
                                stats={stats}
                            />
                        )}
                    </StatsPopover>
                )} */}
            </div>
        </>
    );
};
export default Banner;
