import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isFileImage } from 'helpers/utils';
import { MediaContext } from 'context/media';
import * as Styled from 'components/common/mediamanager/styles';
import Api from 'helpers/MediaApi';

export default class Thumb extends Component {
    static contextType = MediaContext;

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: false,
            error: false,
            fileUrl: '',
        };
    }

    componentDidMount() {
        const { file } = this.props;
        this.setState({ loading: true });
        Api.uploadMedia(file, this.onUploadProgress)
            .then((res) => {
                this.setState({ loading: false, fileUrl: res.data.files[0].location, preview: URL.createObjectURL(file) });
            })
            .catch(() => this.setState({ preview: URL.createObjectURL(file), loading: false, error: true }));
    }

    componentWillUnmount() {
        URL.revokeObjectURL(this.state.preview);
    }

    onUploadProgress = (percentCompleted) => {
        this.setState({ percentage: percentCompleted });
    };

    onThumbClick = () => {
        const { setFiles, fileComponent } = this.context;
        const { file } = this.props;
        const { error, loading, fileUrl } = this.state;

        if (!error && !loading) {
            setFiles({
                name: file.name,
                url: fileUrl,
                type: isFileImage(file) ? 'image' : 'non-image',
                active: fileComponent === 'enhanced' ? true : null,
            });
        }
    };

    removeFile = () => {
        const { file, removeFile } = this.props;
        removeFile(file.name);
    };

    render() {
        const { files } = this.context;
        const { file } = this.props;
        const { loading, preview, percentage, error } = this.state;

        return (
            <Styled.Thumb onClick={this.onThumbClick} hasError={error}>
                {loading ? (
                    <div className="progress" style={{ width: '100%', alignSelf: 'center' }}>
                        <div
                            className="progress-bar progress-bar-striped active"
                            role="progressbar"
                            aria-valuenow={percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${percentage}%` }}
                        >
                            <span className="sr-only">{percentage}% Complete</span>
                        </div>
                    </div>
                ) : (
                    <Styled.ThumbInner>
                        {isFileImage(file) ? (
                            <>
                                <Styled.ThumbImage src={preview} />

                                <Styled.Overlay hasError={error}>
                                    <Styled.Top>
                                        <Styled.Title>{file.name}</Styled.Title>
                                    </Styled.Top>
                                    {error && (
                                        <>
                                            <Styled.RemoveIcon
                                                onClick={this.removeFile}
                                                className="fa fa-times-circle"
                                            />
                                            <Styled.Bottom>
                                                <Styled.Title>Upload failed !</Styled.Title>
                                            </Styled.Bottom>
                                        </>
                                    )}
                                </Styled.Overlay>
                            </>
                        ) : (
                            <>
                                <Styled.ThumbUnknownFile>
                                    <Styled.UnknownFileTitle>{file.name}</Styled.UnknownFileTitle>
                                    {error && (
                                        <Styled.ErrorOverlay>
                                            <Styled.RemoveIcon
                                                onClick={this.removeFile}
                                                className="fa fa-times-circle"
                                            />
                                            <Styled.Bottom>
                                                <Styled.Title>Upload failed !</Styled.Title>
                                            </Styled.Bottom>
                                        </Styled.ErrorOverlay>
                                    )}
                                </Styled.ThumbUnknownFile>
                            </>
                        )}
                        {Array.isArray(files) && files.find((el) => el.name === file.name) && (
                            <Styled.CheckIcon className="fa fa-check-circle" />
                        )}
                    </Styled.ThumbInner>
                )}
            </Styled.Thumb>
        );
    }
}

Thumb.propTypes = {
    file: PropTypes.object,
    removeFile: PropTypes.func.isRequired,
};
