import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { esportsApi } from 'helpers/Api';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import Status from 'pages/sportstree/fixtures/Status';
import Notification from 'components/Notification';
import { useSportsTreeStore } from 'pages/sportstree/store';

const options = fixtureHelpers.getStatuses();

export default function UpdateStatus({ status, enabled, fixtureId }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(status);
    const open = Boolean(anchorEl);
    const store = useSportsTreeStore();
    const [notificationStatus, setNotificationStatus] = useState(null);

    const notify = (variant, message) => {
        setNotificationStatus({ key: Math.random(), message, variant });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        const newStatus = +event.currentTarget.dataset.value;

        setTimeout(() => {
            if (!isNaN(newStatus)) {
                esportsApi
                    .update(fixtureId, { status: newStatus }, 'fixtures')
                    .then(() => {
                        store.refreshTable();
                    })
                    .catch((e) => {
                        notify('error', e.message);
                    });
            }
        }, 250);

        // setCurrentStatus(newStatus);
        setAnchorEl(null);
    };

    useEffect(() => {
        setCurrentStatus(status);
    }, [status]);

    return (
        <div>
            {notificationStatus && (
                <Notification
                    key={notificationStatus.key}
                    variant={notificationStatus.variant}
                    message={notificationStatus.message}
                />
            )}
            <Tooltip title={Status.getLabel(currentStatus)}>
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                    <ExpandMoreIcon />
                </IconButton>
            </Tooltip>
            {enabled && (
                <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                    {options.map((option) => (
                        <MenuItem
                            data-value={option.value}
                            key={option.value}
                            selected={option.value === currentStatus}
                            onClick={handleClose}
                        >
                            <Typography>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
}
