import React, { useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import Link from '@material-ui/core/Link';
import List from 'components/List';
import Teams from 'pages/sportstree/sports/teams';
import NavigateNextIcon from 'pages/sportstree/NavigateNextIcon';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import EditAction from 'components/List/EditAction';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { toAbsoluteUrl } from 'helpers/utils';
import { useAuthStore } from 'app/modules/Auth/auth.store';

// import './styles.scss';

function Sports() {
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const store = useSportsTreeStore();
    const history = useHistory();
    const { user } = useAuthStore();

    useEffect(() => {
        store.setDepth(1);
        store.setSelectedSport(null);
    }, [store]);

    const fields = [
        { name: 'order', label: 'Order', componentType: 'Input' },
        { name: 'active', label: 'Is Active', componentType: 'Checkbox' },
    ];

    const initialValues = useMemo(() => {
        const resource = store.getResource('sports', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            order: resource ? resource.order : '',
            active: resource ? resource.active : true,
        };
    }, [store, selectedResourceId]);

    return (
        <>
            <div className="row sports-container">
                <div className={clsx({ 'col-md-12': !store.selectedSport, 'col-md-6': !!store.selectedSport })}>
                    <CreateEditDialog
                        id={selectedResourceId}
                        show={showCreateEdit}
                        onHide={() => {
                            store.refreshTable();
                            setSelectedResourceId(null);
                            setShowCreateEdit(false);
                        }}
                        resource="sports"
                        fields={fields}
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                    />
                    <List
                        title="Sports"
                        resource="sports"
                        fields={[
                            {
                                field: 'name',
                                title: 'Name',
                                filtering: false,
                                render: (rowData) => (
                                    <div>
                                        <img
                                            alt="Sport Crest"
                                            width="25"
                                            style={{ marginRight: 10 }}
                                            src={
                                                rowData.crest
                                                    ? rowData.crest
                                                    : toAbsoluteUrl(
                                                          '/media/pages/sportstree/sports_symbols/sport_default.svg'
                                                      )
                                            }
                                        />
                                        <span>{rowData.name}</span>
                                    </div>
                                ),
                            },
                            { field: 'console', title: 'Console', filtering: false },
                            {
                                field: 'teams',
                                title: 'Teams',
                                sorting: false,
                                filtering: false,
                                render: (rowData) => (
                                    <Link
                                        onClick={() => {
                                            store.selectedSport && store.selectedSport.id === rowData.id
                                                ? store.setSelectedSport(null)
                                                : store.setSelectedSport({
                                                      id: rowData.id,
                                                      name: rowData.name,
                                                      tagId: rowData.tag.id,
                                                  });
                                        }}
                                        style={{ margin: 10 }}
                                    >
                                        <SVG
                                            // className={clsx({
                                            //     active: !!store.selectedSport && store.selectedSport.id === rowData.id,
                                            // })}
                                            src={toAbsoluteUrl('/media/pages/sportstree/teams/teams.svg')}
                                        ></SVG>
                                    </Link>
                                ),
                            },
                            {
                                field: 'active',
                                title: 'Is Active',
                                defaultFilter: ['true'],
                                lookup: { true: 'YES', false: 'NO' },
                                render: (rowData) => {
                                    return <strong>{rowData.active ? 'YES' : 'NO'}</strong>;
                                },
                            },
                            !!user
                                ? {
                                      field: 'edit',
                                      title: 'Edit',
                                      sorting: false,
                                      filtering: false,
                                      render: (rowData) => (
                                          <EditAction
                                              handleClick={() => {
                                                  setSelectedResourceId(rowData.id);
                                                  setShowCreateEdit(true);
                                              }}
                                          />
                                      ),
                                  }
                                : null,
                            {
                                field: 'categories',
                                title: 'Categories',
                                sorting: false,
                                filtering: false,
                                render: (rowData) => (
                                    <Link
                                        onClick={() => {
                                            store.setSelectedSport({
                                                id: rowData.id,
                                                name: rowData.name,
                                                tagId: rowData.tag.id,
                                            });
                                            store.setDepth(2);
                                            history.push(`/sports-tree/sports/${rowData.id}/categories`);
                                        }}
                                        style={{ margin: 10 }}
                                    >
                                        <NavigateNextIcon />
                                    </Link>
                                ),
                            },
                        ].filter(Boolean)}
                        detailPanel={(rowData) => {
                            return (
                                <>
                                    {rowData.tag &&
                                        rowData.tag.availableStats &&
                                        Array.isArray(rowData.tag.availableStats) && (
                                            <div style={{ padding: '12px' }}>
                                                <h3>Available Stats</h3>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {rowData.tag.availableStats
                                                        .filter(({ active = true }) => active)
                                                        .sort(
                                                            ({ order: orderA = 999 }, { order: orderB = 999 }) =>
                                                                orderA - orderB
                                                        )
                                                        .map((stat) => (
                                                            <div style={{ marginRight: 15 }} key={stat.key}>
                                                                <span>{stat.name}</span>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    {Array.isArray(rowData.settings) && (
                                        <div style={{ padding: '12px' }}>
                                            <h3>Settings</h3>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {rowData.settings.map((setting) => (
                                                    <div style={{ marginRight: 15 }} key={setting.key}>
                                                        <span>
                                                            <strong>{setting.name}: </strong>
                                                            {setting.setting}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        }}
                        onRowClick={(row) => {}}
                    />
                </div>
                {store.selectedSport && (
                    <div className="col-md-6">
                        <Teams key={store.selectedSport.id} />
                    </div>
                )}
            </div>
        </>
    );
}

export default observer(Sports);
