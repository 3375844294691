import React from 'react';
import ReactDatePicker from 'react-datepicker';

const DatePicker = ({ value, dateFormat = 'dd/MM/yyyy', onChange, ...rest }) => {
    return (
        <ReactDatePicker
            style={{ width: '100%' }}
            value={(value && new Date(value)) || null}
            selected={(value && new Date(value)) || null}
            dateFormat={dateFormat}
            onChange={onChange}
            {...rest}
        />
    );
};

export default DatePicker;
