import React from 'react';
import Helmet from 'react-helmet';
import { loadJS } from 'helpers/utils';

const properties = {
    width: '100%',
    height: 500,
    autoplay: false,
};

const twitchEmbed = () => {
    new window.Twitch.Embed('twitch-embed-1', {
        ...properties,
        channel: 'gt_sports_tv1',
        autoplay: true,
        parent: [],
    });
    new window.Twitch.Embed('twitch-embed-2', {
        ...properties,
        channel: 'gt_sports_tv2',
        parent: [],
    });
    new window.Twitch.Embed('twitch-embed-3', {
        ...properties,
        channel: 'gt_sports_tv3',
        parent: [],
    });
    new window.Twitch.Embed('twitch-embed-4', {
        ...properties,
        channel: 'gt_sports_tv4',
        parent: [],
    });
    new window.Twitch.Embed('twitch-embed-5', {
        ...properties,
        channel: 'gt_sports_tv5',
        parent: [],
    });
    new window.Twitch.Embed('twitch-embed-6', {
        ...properties,
        channel: 'gt_sports_tv6',
        parent: [],
    });
};

const LiveStream = () => {
    React.useEffect(() => {
        loadJS('https://embed.twitch.tv/embed/v1.js', twitchEmbed, document.body);
    }, []);
    return (
        <React.Fragment>
            <Helmet>
                <title>GT Sports - Live TV</title>
                <meta name="description" content="Overview of all available GTSports live streams." />
                <meta property="og:title" content="GT Sports - Live TV" />
                <meta property="og:description" content="Overview of all available GTSports live streams." />
                <meta property="og:url" content="https://gtleagues.com/live-stream" />
                <meta name="keywords" content="gtsports gtleagues esports live stream" />
            </Helmet>
            <div className="max-w-screen-md mx-auto">
                <div id="channel-1" className="py-6">
                    <h2>Channel 1</h2>
                    <div id="twitch-embed-1"></div>
                </div>
                <div id="channel-2" className="py-6">
                    <h2>Channel 2</h2>
                    <div id="twitch-embed-2"></div>
                </div>
                <div id="channel-3" className="py-6">
                    <h2>Channel 3</h2>
                    <div id="twitch-embed-3"></div>
                </div>
                <div id="channel-4" className="py-6">
                    <h2>Channel 4</h2>
                    <div id="twitch-embed-4"></div>
                </div>
                <div id="channel-5" className="py-6">
                    <h2>Channel 5</h2>
                    <div id="twitch-embed-5"></div>
                </div>
                <div id="channel-6" className="py-6">
                    <h2>Channel 6</h2>
                    <div id="twitch-embed-6"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LiveStream;
