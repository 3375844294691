import React from 'react';
import request from 'helpers/request';
import config from 'config';
import { action, decorate, observable } from 'mobx';
import { persist } from 'mobx-persist';

export const LOGIN_URL = 'api/login';

export default class Auth {
    token = null;
    user = null;

    login(email, password) {
        return request(`${config.api_url_rw}/login`, { method: 'POST', body: JSON.stringify({ email, password }) });
    }

    logout() {
        this.user = null;
        this.token = null;
    }

    loginSuccess(token, user) {
        this.token = token;
        this.user = user;

        config.api_url = process.env.REACT_APP_API_URL_RW;
    }

    updateTimeZone(timezone) {
        return request(`${config.api_url_rw}/users/${this.user.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ timezone }),
        }).then(
            action('Auth->updateUser', (response) => {
                if (response && response.json) {
                    this.user = response.json; // UPDATE the user
                }

                return response;
            })
        );
    }
}

decorate(Auth, {
    user: [persist('object'), observable],
    token: [persist, observable],
    login: action.bound,
    logout: action.bound,
    loginSuccess: action.bound,
    updateTimeZone: action.bound,
});

export const auth = new Auth();

if (process.env.NODE_ENV === 'development') window.auth = auth;

const storeContext = React.createContext(auth);

export const useAuthStore = () => React.useContext(storeContext);
