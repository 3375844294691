import React from 'react';

const HiddenInput = ({ field, form, ...props }) => (
    <>
        <input type="hidden" {...field} {...props} />
    </>
);

HiddenInput.displayName = 'FormikHiddenInput';

export default HiddenInput;
