import * as React from 'react';
import { Transition } from 'react-transition-group';
import { transitions } from './transitions';
import { getSafePercent } from '../ProgressBar';

export default class Step extends React.Component {
    render() {
        const { accomplished, position, index, children, transition = "scale", transitionDuration = 300 } = this.props;

        const safePosition = getSafePercent(position);

        let style = {
            left: `${safePosition}%`,
            transitionDuration: `${transitionDuration}ms`,
        };

        return (
            <Transition in={accomplished} timeout={transitionDuration}>
                {(state) => {
                    if (transition) {
                        style = {
                            ...style,
                            ...transitions[transition][state],
                        };
                    }

                    return (
                        <div className="react-progress-bar__step" style={style}>
                            {children({
                                accomplished,
                                position: safePosition,
                                transitionState: state,
                                index,
                            })}
                        </div>
                    );
                }}
            </Transition>
        );
    }
}
