import React from 'react';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import { esportsApi } from 'helpers/Api';
import { action, computed, decorate, observe, observable } from 'mobx';

const getSeasonStandings = (seasonId) => esportsApi.getList({}, `seasons/${seasonId}/standings`);
const getSeasonParticipants = (seasonId) => esportsApi.getList({}, `seasons/${seasonId}/participants`);
const getPlayerForm = (sportId, playerId) => esportsApi.getList({}, `sports/${sportId}/players/${playerId}/forms`);
const getHeadToHeadStats = (sportId, homePlayerId, awayPlayerId) =>
    esportsApi.getList({}, `sports/${sportId}/players/${homePlayerId}/head-to-head/${awayPlayerId}/stats`);
const getHeadToHeadFixtures = (sportId, seasonId, homePlayerId, awayPlayerId, activeTab) => {
    const apiPath =
        activeTab === 1
            ? `seasons/${seasonId}/players/${homePlayerId}/head-to-head/${awayPlayerId}/fixtures`
            : `sports/${sportId}/players/${homePlayerId}/head-to-head/${awayPlayerId}/fixtures`;

    return esportsApi
        .getList({}, apiPath)
        .then((response) => {
            return { data: response };
        })
        .then(fixtureHelpers.mapResponse({ timeOnly: false }))
        .then((res) => res.data);
};
const getLatestFixtures = (sportId, playerId) =>
    esportsApi
        .getList({}, `sports/${sportId}/players/${playerId}/fixtures/latest`)
        .then((response) => {
            return { data: response };
        })
        .then(fixtureHelpers.mapResponse({ timeOnly: false }))
        .then((res) => res.data);
const getUpcomingFixtures = (sportId, playerId) =>
    esportsApi
        .getList({}, `sports/${sportId}/players/${playerId}/fixtures/upcoming`)
        .then((response) => {
            return { data: response };
        })
        .then(fixtureHelpers.mapResponse({ timeOnly: false }))
        .then((res) => res.data);

export default class HeadToHead {
    season;
    seasonStandings = observable.array();
    seasonParticipants = observable.array();
    homeParticipant;
    awayParticipant;
    homeForm;
    awayForm;
    stats;
    headToHeadFixtures = observable.array();
    homeUpcomingFixtures = observable.array();
    awayUpcomingFixtures = observable.array();
    homeLatestFixtures = observable.array();
    awayLatestFixtures = observable.array();
    activeTab = 0;

    constructor() {
        observe(this, 'seasonParticipants', (change) => {
            if (this.player1 && this.player2) {
                this.homeParticipant = this.seasonParticipants.find((participant) => participant.id === this.player1);
                this.awayParticipant = this.seasonParticipants.find((participant) => participant.id === this.player2);
            } else {
                this.homeParticipant = this.seasonParticipants[0];
                this.awayParticipant = this.seasonParticipants[1];
            }
            this.fetchData();
        });

        observe(this, 'activeTab', (change) => {
            getHeadToHeadFixtures(
                this.sportId,
                this.seasonId,
                this.homeParticipant.player.id,
                this.awayParticipant.player.id,
                this.activeTab
            ).then((headToHeadFixtures) => {
                this.headToHeadFixtures = headToHeadFixtures;
            });
        });
    }

    fetchData() {
        const homeForm = getPlayerForm(this.sportId, this.homeParticipant.player.id);
        const awayForm = getPlayerForm(this.sportId, this.awayParticipant.player.id);
        const stats = getHeadToHeadStats(this.sportId, this.homeParticipant.player.id, this.awayParticipant.player.id);
        const headToHeadFixtures = getHeadToHeadFixtures(
            this.sportId,
            this.seasonId,
            this.homeParticipant.player.id,
            this.awayParticipant.player.id,
            this.activeTab
        );
        const homeUpcomingFixtures = getUpcomingFixtures(this.sportId, this.homeParticipant.player.id);
        const awayUpcomingFixtures = getUpcomingFixtures(this.sportId, this.awayParticipant.player.id);
        const homeLatestFixtures = getLatestFixtures(this.sportId, this.homeParticipant.player.id);
        const awayLatestFixtures = getLatestFixtures(this.sportId, this.awayParticipant.player.id);

        return Promise.all([
            homeForm,
            awayForm,
            stats,
            headToHeadFixtures,
            homeUpcomingFixtures,
            awayUpcomingFixtures,
            homeLatestFixtures,
            awayLatestFixtures,
        ]).then(
            action(
                ([
                    homeForm,
                    awayForm,
                    stats,
                    headToHeadFixtures,
                    homeUpcomingFixtures,
                    awayUpcomingFixtures,
                    homeLatestFixtures,
                    awayLatestFixtures,
                ]) => {
                    this.homeForm = homeForm;
                    this.awayForm = awayForm;
                    this.stats = stats;

                    this.headToHeadFixtures = headToHeadFixtures;
                    this.homeUpcomingFixtures = homeUpcomingFixtures;
                    this.awayUpcomingFixtures = awayUpcomingFixtures;
                    this.homeLatestFixtures = homeLatestFixtures;
                    this.awayLatestFixtures = awayLatestFixtures;
                }
            )
        );
    }

    get sportId() {
        if (!this.season) {
            return null;
        }

        return this.season.tournament.category.sport.id;
    }

    get seasonId() {
        if (!this.season) {
            return null;
        }

        return this.season.id;
    }

    get homeStandings() {
        if (!this.homeParticipant) {
            return null;
        }
        return this.seasonStandings.find((standing) => standing.participantId === this.homeParticipant.id);
    }

    get awayStandings() {
        if (!this.awayParticipant) {
            return null;
        }
        return this.seasonStandings.find((standing) => standing.participantId === this.awayParticipant.id);
    }

    fetchSeason(seasonId, player1, player2) {
        this.player1 = player1;
        this.player2 = player2;
        return Promise.all([
            esportsApi.getOne(seasonId, 'seasons'),
            getSeasonParticipants(seasonId),
            getSeasonStandings(seasonId),
        ]).then(
            action(([season, participants, standings]) => {
                this.season = season;
                this.seasonParticipants = participants;
                this.seasonStandings = standings.data.map((standing, index) => ({ ...standing, rank: index + 1 }));
            })
        );
    }

    updateHomeParticipant(sel) {
        const participant = this.seasonParticipants.find((participant) => participant.id === sel.value);
        this.homeParticipant = participant;
        this.fetchData();
    }

    updateAwayParticipant(sel) {
        const participant = this.seasonParticipants.find((participant) => participant.id === sel.value);
        this.awayParticipant = participant;
        this.fetchData();
    }

    setActiveTab(tab) {
        this.activeTab = tab;
    }
}

decorate(HeadToHead, {
    season: observable,
    seasonStandings: observable,
    seasonParticipants: observable,
    homeParticipant: observable,
    awayParticipant: observable,
    homeForm: observable,
    awayForm: observable,
    stats: observable,
    headToHeadFixtures: observable,
    homeUpcomingFixtures: observable,
    awayUpcomingFixtures: observable,
    homeLatestFixtures: observable,
    awayLatestFixtures: observable,
    activeTab: observable,
    sportId: computed,
    seasonId: computed,
    homeStandings: computed,
    awayStandings: computed,
    fetchSeason: action.bound,
    updateHomeParticipant: action.bound,
    updateAwayParticipant: action.bound,
    setActiveTab: action.bound,
});

export const headToHead = new HeadToHead();

const storeContext = React.createContext(headToHead);

export const useHeadToHeadStore = () => React.useContext(storeContext);
