import React from 'react';
import MuiChip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        background: (props) => props.bgColor,
        borderRadius: 0,
        border: 0,
        color: (props) => props.color,
        height: 25,
        padding: 0,
        minWidth: 100,
        textAlign: 'center',
    },
});

const Chip = ({ color, bgColor, ...props }) => {
    const classes = useStyles({ color, bgColor });

    return <MuiChip className={classes.root} {...props} />;
};

export default Chip;
