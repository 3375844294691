import React from 'react';

const StandingsLegend = () => {
    return (
        <div className="p-4 my-8 bg-white">
            <div className="flex items-center">
                <div
                    className="w-4 h-4 mr-4 rounded ring-1 ring-inset ring-black ring-opacity-0"
                    style={{ backgroundColor: '#3AA856' }}
                ></div>
                <p className="p-0 m-0">Champion/ Runner-Up</p>
            </div>
            {/* <div className="flex items-center">
                <div
                    className="w-4 h-4 mr-4 rounded ring-1 ring-inset ring-black ring-opacity-0"
                    style={{ backgroundColor: '#EA4335' }}
                ></div>
                <p className="p-0 m-0">Relegation*</p>
            </div>
            <p className="my-2">(*) if applicable. League A participants are not promoted. League C participants are not relegated.</p> */}
        </div>
    );
};

export default StandingsLegend;
