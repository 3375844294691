import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (base, state) => ({
        ...base,
        color: '#fff',
        background: '#4a7dff',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        svg: {
            fill: '#fff',
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: '#fff',
    }),
    singleValue: (base) => ({
        ...base,
        color: '#fff',
    }),
    input: (base) => ({
        color: '#fff',
    }),
    placeholder: (base) => ({
        ...base,
        color: 'hsl(0 ,0% ,90%)',
    }),
    menu: (base) => ({
        ...base,
        zIndex: 20,
    }),
};

function SelectInptu(props) {
    return <Select isClearable styles={customStyles} {...props} />;
}

export default SelectInptu;
