import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'components/common/mediamanager/dropzone.react';
import { Modal } from 'react-bootstrap';
import { MediaContext } from 'context/media';

export default class MediaManager extends Component {
    static contextType = MediaContext;

    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    render() {
        const { maxSize, acceptedFileTypes, multiple, info } = this.props;

        return (
            <Modal.Dialog className="media-modal">
                <Modal.Body>
                    {info && <div className="my-2">{info}</div>}
                    <Dropzone maxSize={maxSize} acceptedFileTypes={acceptedFileTypes} multiple={multiple} />
                </Modal.Body>
            </Modal.Dialog>
        );
    }
}

MediaManager.propTypes = {
    children: PropTypes.any,
    name: PropTypes.string,
    maxSize: PropTypes.number,
    acceptedFileTypes: PropTypes.array,
    multiple: PropTypes.bool,
};

MediaManager.defaultProps = {
    children: null,
};
