import React, { useEffect, useState } from 'react';
import { esportsApi } from 'helpers/Api';
import { useParams, useHistory } from 'react-router-dom';
import { homeAwayParticipants } from 'helpers/utils';
import Status from 'pages/sportstree/fixtures/Status';
import fixtureHelpers from 'pages/sportstree/fixtures/helpers';
import CompactTable from 'pages/fixture/CompactTable';
import Banner from 'pages/fixture/Banner';
import GraphStats from 'pages/fixture/GraphStats';
import Results from 'pages/fixture/Results';
import BackButton from 'components/BackButton';
import HeadToHeadTable from 'components/HeadToHeadTable';
import StatsPopover, { useStatsPopover } from 'pages/fixture/StatsPopover';
import { observer } from 'mobx-react-lite';
import { useFixturePageStore } from 'pages/fixture/store';
import './styles.scss';

const LATEST_MATCHES_TAB = 0;
const CURRENT_SEASON_TAB = 1;

const Fixture = () => {
    const { id } = useParams();
    const { fetchFixture } = useFixturePageStore();
    const [headToHeadFixtures, setHeadToHeadFixtures] = useState([]);
    const [homeForm, setHomeForm] = useState(null);
    const [awayForm, setAwayForm] = useState(null);
    const [stats, setStats] = useState(null);
    const [activeTab, setActiveTab] = useState(LATEST_MATCHES_TAB);
    const [fixture, setFixture] = useState(null);
    const [homeUpcomingFixtures, setHomeUpcomingFixtures] = useState([]);
    const [homeLatestFixtures, setHomeLatestFixtures] = useState([]);
    const [awayUpcomingFixtures, setAwayUpcomingFixtures] = useState([]);
    const [awayLatestFixtures, setAwayLatestFixtures] = useState([]);
    const [homeSeasonStandings, setHomeSeasonStandings] = useState();
    const [awaySeasonStandings, setAwaySeasonStandings] = useState();
    const [totalSeasonParticipants, setTotalSeasonParticipants] = useState(0);
    const [seasonParticipants, setSeasonParticipants] = useState([]);
    const history = useHistory();
    const { anchorEl, handleClick, handleClose } = useStatsPopover();

    useEffect(() => {
        fetchFixture(id).then((res) => {
            setFixture(res);
        });
    }, [id, fetchFixture]);

    const [homeParticipant, awayParticipant] = homeAwayParticipants(fixture ? fixture.participants : []);

    useEffect(() => {
        if (fixture && fixture.participants.length === 2) {
            const homeParticipantId = homeParticipant.participant.player.id;
            const awayParticipantId = awayParticipant.participant.player.id;

            const apiPath =
                activeTab === CURRENT_SEASON_TAB
                    ? `seasons/${fixture.season.id}/players/${homeParticipantId}/head-to-head/${awayParticipantId}/fixtures`
                    : `sports/${fixture.season.tournament.category.sport.id}/players/${homeParticipantId}/head-to-head/${awayParticipantId}/fixtures`;

            esportsApi
                .getList({}, apiPath)
                .then((response) => {
                    return { data: response };
                })
                .then(fixtureHelpers.mapResponse({ timeOnly: false }))
                .then((response) => {
                    setHeadToHeadFixtures(response.data);
                });

            esportsApi
                .getList({}, `sports/${fixture.season.tournament.category.sport.id}/players/${homeParticipantId}/forms`)
                .then((response) => {
                    setHomeForm(response);
                });

            esportsApi
                .getList({}, `sports/${fixture.season.tournament.category.sport.id}/players/${awayParticipantId}/forms`)
                .then((response) => {
                    setAwayForm(response);
                });

            esportsApi
                .getList(
                    {},
                    `sports/${fixture.season.tournament.category.sport.id}/players/${homeParticipantId}/head-to-head/${awayParticipantId}/stats`
                )
                .then((response) => {
                    setStats(response);
                });

            esportsApi
                .getList(
                    {},
                    `sports/${fixture.season.tournament.category.sport.id}/players/${homeParticipantId}/fixtures/upcoming`
                )
                .then((response) => {
                    return { data: response };
                })
                .then(fixtureHelpers.mapResponse({ timeOnly: false }))
                .then((response) => {
                    setHomeUpcomingFixtures(response.data);
                });

            esportsApi
                .getList(
                    {},
                    `sports/${fixture.season.tournament.category.sport.id}/players/${awayParticipantId}/fixtures/upcoming`
                )
                .then((response) => {
                    return { data: response };
                })
                .then(fixtureHelpers.mapResponse({ timeOnly: false }))
                .then((response) => {
                    setAwayUpcomingFixtures(response.data);
                });

            esportsApi
                .getList(
                    {},
                    `sports/${fixture.season.tournament.category.sport.id}/players/${homeParticipantId}/fixtures/latest`
                )
                .then((response) => {
                    return { data: response };
                })
                .then(fixtureHelpers.mapResponse({ timeOnly: false }))
                .then((response) => {
                    setHomeLatestFixtures(response.data);
                });

            esportsApi
                .getList(
                    {},
                    `sports/${fixture.season.tournament.category.sport.id}/players/${awayParticipantId}/fixtures/latest`
                )
                .then((response) => {
                    return { data: response };
                })
                .then(fixtureHelpers.mapResponse({ timeOnly: false }))
                .then((response) => {
                    setAwayLatestFixtures(response.data);
                });

            esportsApi.getList({}, `seasons/${fixture.season.id}/standings`).then(({ data: response}) => {
                const homeStandings = response.find((standing) => standing.playerId === homeParticipantId);
                const homeRank = response.findIndex((standing) => standing.playerId === homeParticipantId) + 1;
                const awayStandings = response.find((standing) => standing.playerId === awayParticipantId);
                const awayRank = response.findIndex((standing) => standing.playerId === awayParticipantId) + 1;

                setHomeSeasonStandings({ ...homeStandings, rank: homeRank });
                setAwaySeasonStandings({ ...awayStandings, rank: awayRank });
                setTotalSeasonParticipants(response.length);
            });

            esportsApi.getList({}, `seasons/${fixture.season.id}/participants`).then((response) => {
                setSeasonParticipants(response);
            });
        }
    }, [fixture, homeParticipant, awayParticipant, activeTab]);

    const onHomeParticipantChange = (sel) => {
        history.push(`/head-to-head/${fixture.season.id}/${sel.value}/${awayParticipant.participant.id}`);
    };

    const onAwayParticipantChange = (sel) => {
        history.push(`/head-to-head/${fixture.season.id}/${homeParticipant.participant.id}/${sel.value}`);
    };

    return (
        <div>
            {fixture && (fixture.status === Status.ENDED || fixture.status === Status.LIVE) && (
                <button className="btn btn-primary" onClick={handleClick}>
                    <i className="far fa-chart-bar"></i>
                </button>
            )}

            {fixture && (
                <div className="my-2">
                    <h3>
                        <b>{fixture.season.tournament.category.name}</b> / <b>{fixture.season.tournament.name}</b> /{' '}
                        <b>{fixture.season.name}</b>
                    </h3>
                </div>
            )}

            {fixture && (
                <div className="fixture-wrapper">
                    <Banner
                        fixture={fixture}
                        homeParticipant={homeParticipant}
                        awayParticipant={awayParticipant}
                        homeForm={homeForm}
                        awayForm={awayForm}
                        stats={stats}
                        participants={seasonParticipants.map(({ id, player: { nickname } }) => ({
                            value: id,
                            label: nickname,
                        }))}
                        onHomeParticipantChange={onHomeParticipantChange}
                        onAwayParticipantChange={onAwayParticipantChange}
                    />

                    {stats && homeParticipant && awayParticipant && (
                        <GraphStats
                            homePlayerId={homeParticipant.participant.player.id}
                            awayPlayerId={awayParticipant.participant.player.id}
                            homeSeasonStandings={homeSeasonStandings}
                            awaySeasonStandings={awaySeasonStandings}
                            totalSeasonParticipants={totalSeasonParticipants}
                            season={fixture.season}
                            sportId={fixture.season.tournament.category.sport.id}
                            homeForm={homeForm}
                            awayForm={awayForm}
                            stats={stats}
                        />
                    )}

                    <StatsPopover anchorEl={anchorEl} handleClose={handleClose}>
                        {(fixture.status === Status.ENDED || fixture.status === Status.LIVE) && (
                            <div>
                                {fixture.result && fixture.result.stats && (
                                    <div
                                        className="gt-border"
                                        style={{
                                            width: '70%',
                                            margin: '0 auto',
                                        }}
                                    >
                                        <Results
                                            fixture={fixture}
                                            sportId={fixture.season.tournament.category.sport.id}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </StatsPopover>

                    {headToHeadFixtures && fixture.status === Status.NOT_STARTED && (
                        <HeadToHeadTable data={headToHeadFixtures} setActiveTab={setActiveTab} />
                    )}

                    {(homeLatestFixtures.length > 0 || awayLatestFixtures.length > 0) && (
                        <>
                            <div
                                className="p-2 mt-4 gt-bg"
                                style={{
                                    color: '#fff',
                                }}
                            >
                                Latest Matches
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ flex: '0 0 50%', borderRight: '2px solid #999' }}>
                                    {homeLatestFixtures.length > 0 && <CompactTable data={homeLatestFixtures} />}
                                </div>
                                <div style={{ flex: '0 0 50%' }}>
                                    {awayLatestFixtures.length > 0 && <CompactTable data={awayLatestFixtures} />}
                                </div>
                            </div>
                        </>
                    )}

                    {(homeUpcomingFixtures.length > 0 || awayUpcomingFixtures.length > 0) && (
                        <>
                            <div
                                className="p-2 mt-4 gt-bg"
                                style={{
                                    color: '#fff',
                                }}
                            >
                                Upcoming Matches
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div style={{ flex: '0 0 50%', borderRight: '2px solid #999' }}>
                                    {homeUpcomingFixtures.length > 0 && <CompactTable data={homeUpcomingFixtures} />}
                                </div>
                                <div style={{ flex: '0 0 50%' }}>
                                    {awayUpcomingFixtures.length > 0 && <CompactTable data={awayUpcomingFixtures} />}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}

            <div className="my-2 text-right">
                <BackButton />
            </div>
        </div>
    );
};

export default observer(Fixture);
