import React, { useEffect, useRef } from 'react';
import Api from 'helpers/Api';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useAuthStore } from 'app/modules/Auth/auth.store';
import { useStores } from 'stores/mobx';
import { toAbsoluteUrl } from 'helpers/utils';

function FavoriteSeasons() {
    const history = useHistory();
    const { user } = useAuthStore();
    const { setCurrentSeason } = useSportsTreeStore();
    const { favoriteSeasons } = useStores();
    const favoriteSeasonsApiRef = useRef(new Api(`users/${user.id}/favorite-seasons`));

    useEffect(() => {
        favoriteSeasonsApiRef.current &&
            favoriteSeasonsApiRef.current.getList({ xtc: false }).then((data) => {
                favoriteSeasons.setData(data);
            });
    }, [favoriteSeasons]);

    const navigateToSeason = (favoriteSeason) => () => {
        setCurrentSeason(favoriteSeason.season);
        history.push(`/sports-tree/seasons/${favoriteSeason.season.id}`);
    };

    return (
        <div className="container mb-2">
            <div className="row">
                <div className="col-md-12">
                    {favoriteSeasons.data.length > 0 && (
                        <div>
                            <h3 className="leading-none font-weight-bolder text-dark">Favorite Seasons</h3>
                            <div>
                                <div className="md:flex flex-wrap -mx-2">
                                    {favoriteSeasons.data.map((favoriteSeason) => (
                                        <div key={favoriteSeason.id} className="md:flex md:w-1/3 lg:w-1/5 p-2">
                                            <div
                                                onClick={navigateToSeason(favoriteSeason)}
                                                className="text-center cursor-pointer relative py-2 w-full bg-color-primary"
                                                style={{
                                                    color: '#fff',
                                                }}
                                            >
                                                <span style={{ fontSize: 16, position: 'absolute', left: 5, top: 5 }}>
                                                    ★
                                                </span>
                                                <SVG
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        if (window.confirm('Are you sure?')) {
                                                            const api = new Api(`users/favorite-seasons`);
                                                            api.delete(favoriteSeason.id).then(() => {
                                                                favoriteSeasonsApiRef.current
                                                                    .getList({ xtc: false })
                                                                    .then((response) => {
                                                                        favoriteSeasons.setData(response);
                                                                    });
                                                            });
                                                        }
                                                    }}
                                                    width={15}
                                                    height={15}
                                                    style={{ position: 'absolute', right: 7, top: 7 }}
                                                    src={toAbsoluteUrl('/media/general/recycle_bin.svg')}
                                                />
                                                <div>{favoriteSeason.season.tournament.category.sport.name}</div>
                                                <div>
                                                    <img
                                                        src={
                                                            favoriteSeason.season.tournament.category.crest
                                                                ? favoriteSeason.season.tournament.category.crest
                                                                : toAbsoluteUrl(
                                                                      '/media/pages/sportstree/flags/flag_default.jpg'
                                                                  )
                                                        }
                                                        width="25"
                                                        alt="Category Crest"
                                                    />
                                                </div>
                                                <div className="px-1">
                                                    {favoriteSeason.season.tournament.name} •{' '}
                                                    {favoriteSeason.season.name}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default observer(FavoriteSeasons);
