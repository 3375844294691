import React, { useState, useContext, useRef, useMemo } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import List from 'components/List';
import EditAction from 'components/List/EditAction';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import { exporter } from 'components/ExportButton';
import ImportButton from 'components/ImportButton';
import GlobalContext from 'context';
import Api from 'helpers/Api';
import Notification from 'components/Notification';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import * as Yup from 'yup';
import { isValidUrl, toAbsoluteUrl } from 'helpers/utils';

let validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    nickname: Yup.string().required(),
    nationality: Yup.string().max(2).nullable(),
    birthdate: Yup.date(),
    // profilePicture: Yup.string().url(),
});

const Players = () => {
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const store = useSportsTreeStore();
    const globalContext = useContext(GlobalContext);
    const esportsApiRef = useRef(new Api('players'));
    const [status, setStatus] = useState(null);

    const notify = (variant, message) => {
        setStatus({ key: Math.random(), message, variant });
    };

    const countries = globalContext.countries.getNames('en');
    const player = store.getResource('players', selectedResourceId);

    const countriesOptions = Object.keys(countries).map((key) => ({
        label: countries[key],
        value: key,
    }));

    const fields = [
        { name: 'name', label: 'Name', componentType: 'Input' },
        { name: 'nickname', label: 'Nickname', componentType: 'Input' },
        {
            name: 'nationality',
            label: 'Nationality',
            isClearable: true,
            componentType: 'Select',
            defaultValue: player
                ? countriesOptions.filter((item) => {
                      return item.value === player.nationality;
                  })
                : null,
            options: countriesOptions,
        },
        { name: 'birthdate', label: 'Birthday', componentType: 'DatePicker' },
        { name: 'active', label: 'Is Active', componentType: 'Checkbox' },
        { name: 'absent', label: 'Is Absent', componentType: 'Checkbox' },
        {
            name: 'profilePicture',
            label: 'Profile Picture',
            componentType: 'MediaFileInput',
            info: 'Please upload images 500x700',
        },
    ];

    const handleImport = (values) => {
        if (esportsApiRef.current) {
            esportsApiRef.current
                .createMany(values.map((value) => ({ ...value, name: value.nickname })))
                .then((res) => {
                    store.refreshTable();
                })
                .catch((e) => {
                    notify('error', e.message);
                });
        }
    };

    const initialValues = useMemo(() => {
        const resource = store.getResource('players', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            name: resource ? resource.name : '',
            nickname: resource ? resource.nickname : '',
            nationality: resource ? resource.nationality || '' : '',
            birthdate: resource ? resource.birthdate || '' : '',
            profilePicture: resource ? resource.profilePicture || '' : '',
            active: resource ? resource.active : true,
            absent: resource ? resource.absent : false,
        };
    }, [store, selectedResourceId]);

    return (
        <>
            {status && <Notification key={status.key} variant={status.variant} message={status.message} />}
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setSelectedResourceId(null);
                    setShowCreateEdit(false);
                }}
                resource="players"
                fields={fields}
                initialValues={initialValues}
                validationSchema={validationSchema}
            />
            <List
                hasFiltering={true}
                title="Players"
                fields={[
                    {
                        field: 'name',
                        title: 'Name',
                        width: '30%',
                        render: (rowData) => (
                            <div className="flex flex-row items-center">
                                <div
                                    // alt="Player Profile"
                                    style={{
                                        margin: 10,
                                        border: '2px solid transparent',
                                        borderRadius: '50%',
                                        width: 75,
                                        height: 75,
                                        objectFit: 'contain',
                                        backgroundImage:
                                            'linear-gradient(white, white), linear-gradient(to right, #005DFF, #FF1758)',
                                        backgroundOrigin: 'border-box',
                                        backgroundClip: 'content-box, border-box',
                                    }}
                                    // src={
                                    //     rowData.profilePicture && isValidUrl(rowData.profilePicture)
                                    //         ? rowData.profilePicture
                                    //         : toAbsoluteUrl('/media/pages/sportstree/default-player-avatar.png')
                                    // }
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '50%',
                                            background: `url('${
                                                rowData.profilePicture && isValidUrl(rowData.profilePicture)
                                                    ? rowData.profilePicture
                                                    : toAbsoluteUrl('/media/pages/sportstree/default-player-avatar.png')
                                            }')`,
                                            backgroundSize: 'cover',
                                        }}
                                    ></div>
                                </div>
                                <span>{rowData.nickname}</span>
                            </div>
                        ),
                    },
                    // { field: 'nationality', title: 'Nationality' },
                    // { field: 'nickname', title: 'Nickname' },
                    { field: 'birthdate', title: 'Birthday', filtering: false },
                    {
                        field: 'active',
                        title: 'Is Active',
                        defaultFilter: ['true'],
                        lookup: { true: 'YES', false: 'NO' },
                        render: (rowData) => {
                            return <strong>{rowData.active ? 'YES' : 'NO'}</strong>;
                        },
                    },
                    {
                        field: 'absent',
                        title: 'Is Absent',
                        lookup: { true: 'YES', false: 'NO' },
                        render: (rowData) => {
                            return <strong>{rowData.absent ? 'YES' : 'NO'}</strong>;
                        },
                    },
                ]}
                resource="players"
                renderActions={(row) => (
                    <>
                        <EditAction
                            handleClick={() => {
                                setSelectedResourceId(row.id);
                                setShowCreateEdit(true);
                            }}
                        />
                    </>
                )}
                actions={[
                    {
                        icon: () => <GetAppIcon color="primary" />,
                        tooltip: 'Export',
                        onClick: (event, rowData) => {
                            exporter(
                                rowData.map(({ name }) => ({ name })),
                                'players'
                            );
                        },
                    },
                ]}
                renderToolbar={() => (
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <div className="ml-4">
                            <ImportButton onChange={handleImport} />
                        </div>
                        <div className="ml-4">
                            <Button onClick={() => setShowCreateEdit(true)}>Create Player</Button>
                        </div>
                    </div>
                )}
                mapResponse={(response) => {
                    return {
                        ...response,
                        data: response.data.map((item) => ({
                            ...item,
                            nationality: countries[item.nationality] || '-',
                        })),
                    };
                }}
            />
        </>
    );
};

export default Players;
