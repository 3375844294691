import React from 'react';
import Helmet from 'react-helmet';

const CoordinationWithEsic = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <Helmet>
                <title>GT Sports - Coordination with ESIC</title>
                <meta
                    name="description"
                    content="We and ESIC are working together towards having all the right policies, streamlined operations and an environment that promotes and verifies the high credibility in our games."
                />
                <meta property="og:title" content="GT Sports - Coordination with ESIC" />
                <meta
                    property="og:description"
                    content="We and ESIC are working together towards having all the right policies, streamlined operations and an environment that promotes and verifies the high credibility in our games."
                />
                <meta property="og:url" content="https://gtleagues.com/current-seasons" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports coordination with esic" />
            </Helmet>
            <h1>Coordination with ESIC</h1>
            <p>The easy ability to cheat used regularly can destroy the credibility of the game.</p>
            <p>
                We and ESIC, a not for profit members association, are working together towards having all the right
                policies, streamlined operations and an environment that promotes and verifies the high credibility in
                our games.
            </p>

            <p>
                <strong>MATCH-FIXING DETECTION</strong>
            </p>
            <p>Match-fixing is one of the most significant threats to esports integrity.</p>
            <p>
                We use several techniques to detect match-fixing including suspicious betting monitoring, investigation,
                prosecution, engagement with the sports betting industry and more. 
            </p>

            <p>
                <strong>EDUCATION OF PARTICIPANTS</strong>
            </p>
            <p>The most effective deterrent to match-fixing is participant education.</p>
            <p>
                Participants are educated through face to face presentations or through an online certification program
                and they receive a certificate after completion. They are all aware of what is an offence, how they
                report an offence and what consequences there are for offenders.
            </p>

            <p>
                <strong>OTHER MEASURES</strong>
            </p>
            <ul>
                <li>
                    <p>The physical presence of anti-corruption officials at venues and hotels</p>
                </li>
                <li>
                    <p>Liaison and cooperation with other anti-corruption units and law-enforcement</p>
                </li>
                <li>
                    <p>Alerts to Participants</p>
                </li>
                <li>
                    <p>Warnings off of known corruptors</p>
                </li>
                <li>
                    <p>Good database of participant information</p>
                </li>
            </ul>

            <p>
                <strong>Anti-Corruption Code</strong>
            </p>

            <p>
                In our coordination with ESIC, we have fully incorporated the anti-corruption code. The anti-corruption
                code among other things describes what the offences are and what are the consequences to these offences.
            </p>

            <p>
                A small summary of the offences, which is not limited to the points mentioned below, is presented below
                for clarification purposes.
            </p>
            <ul>
                <li>
                    <p>Fixing events, either directly or indirectly</p>
                </li>
                <li>
                    <p>Bribery of participants and/or officials</p>
                </li>
                <li>
                    <p>Misusing and selling insider information</p>
                </li>
                <li>
                    <p>Failure to disclose information directly or indirectly related to corruption</p>
                </li>
                <li>
                    <p>Failure of cooperation in investigations</p>
                </li>
            </ul>

            <p>When it comes to consequences, the penalties are quite strong. Including but not limited to:</p>

            <ul>
                <li>
                    <p>Suspension from participation</p>
                </li>
                <li>
                    <p>Up-to lifetime ban for corruption</p>
                </li>
                <li>
                    <p>Bans are also issued for betting, misuse of information and general offences</p>
                </li>
                <li>
                    <p>Loss of job &amp; career titles</p>
                </li>
            </ul>
        </div>
    );
};

export default CoordinationWithEsic;
