import React, { useMemo } from 'react';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { toAbsoluteUrl } from 'helpers/utils';

export function Footer() {
    const today = new Date().getFullYear();
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            footerClasses: uiService.getClasses('footer', true),
            footerContainerClasses: uiService.getClasses('footer_container', true),
        };
    }, [uiService]);

    return (
        <div className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`} id="kt_footer">
            <div
                className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                <div>
                    <a href="https://esic.gg/" target="_blank" rel="noopener noreferrer">
                        <img className="w-48 md:w-24 m-2" alt="esic" src={toAbsoluteUrl('/media/general/esic.png')} />
                    </a>
                </div>
                <div>
                    <a href="https://www.sportradar.com/" target="_blank" rel="noopener noreferrer">
                        <img
                            className="w-48 md:w-40 my-8"
                            alt="esic"
                            src={toAbsoluteUrl('/media/general/sportradar.png')}
                        />
                    </a>
                </div>

                <div className="text-dark m-2">
                    <a
                        className="m-2"
                        href="https://www.instagram.com/gtsportsleagues/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-instagram text-2xl color-insta"></i>
                    </a>
                    <a
                        className="m-2"
                        href="https://www.linkedin.com/company/gt-sports-leagues"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-linkedin text-2xl color-linkedin"></i>
                    </a>
                    <a
                        className="m-2"
                        href="https://twitter.com/gtsportsleagues"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fab fa-twitter text-2xl color-twitter"></i>
                    </a>
                    <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{' '}
                    <a href="/" target="_blank" rel="noopener noreferrer" className="text-dark-75 text-hover-primary">
                        GT Sports
                    </a>
                </div>

                {/* <div className="nav nav-dark order-1 order-md-2">
                    <a
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link pr-3 pl-0"
                    >
                        Menu Item 1
                    </a>
                    <a
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link px-3"
                    >
                        Menu Item 2
                    </a>
                    <a
                        href="/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nav-link pl-3 pr-0"
                    >
                        Menu Item 3
                    </a>
                </div> */}
            </div>
        </div>
    );
}
