import React from 'react';
import Helmet from 'react-helmet';

const CredibilityOfTheGame = () => {
    return (
        <div className="max-w-screen-sm mx-auto text-justify">
            <Helmet>
                <title>GT Sports - Credibility of the Game</title>
                <meta
                    name="description"
                    content="We take credibility and transparency seriously. From live-streaming down to rewarding players, all you need to know about our steps & measures towards credibility is here."
                />
                <meta property="og:title" content="GT Sports - Credibility of the Game" />
                <meta
                    property="og:description"
                    content="We take credibility and transparency seriously. From live-streaming down to rewarding players, all you need to know about our steps & measures towards credibility is here."
                />
                <meta property="og:url" content="https://gtleagues.com/credibility-of-the-game" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports game credibility" />
            </Helmet>
            <h1>Credibility of the Game</h1>
            <p>
                <strong>Live streaming</strong>
            </p>
            <p>
                All events played in GT Leagues go live on Twitch. You can always keep up with the games and view them
                live. Each livestream incorporates live footage of the players that participate in the event. This way
                you can also verify that the games are played by the specific players.
            </p>
            <p>
                <strong>Events played only on GT league studios</strong>
            </p>
            <p>
                In the previous years, esports have really taken off as a sport. The public interest has grown
                tremendously. During this time, there have been several reports of players cheating or attempting to
                cheat in esports. Especially in situations where players played from their home-office and unregulated
                environments. At GT Leagues we have taken steps to safeguard our product and your favorite games from
                this issue. 
            </p>
            <p>
                All games are being held in GT League studios and everything is fully monitored and streamlined. We do
                not allow players to play from their home office. Players can not have any interaction with the
                equipment or the studios, other than what you see Live on Twitch, when it is game time.
            </p>
            <p>
                <strong>Reviewing/Officiating games</strong>
            </p>
            <p>
                Our studios are being fully monitored 24/7. Games played are always reviewed live by the Studio Manager
                who is in charge of the whole process, as well as the live streaming operators. The Studio Manager in
                charge makes sure that the rules of the game are being followed. Footage from ended games is stored.
                This footage is reviewed periodically to make sure that our operations run smoothly and meet high
                standards.
            </p>
            <p>
                <strong>Coordination with Sportradar</strong>
            </p>
            <p>
                We are in close coordination with <strong>Sportradar</strong> as regards to any suspicious activity that
                might occur surrounding our events. This way we can take yet another measure in not allowing players to
                cheat the game or collide with one another to fix the result of a game. 
            </p>
            <p>
                <strong>Coordination with ESIC</strong>
            </p>
            <p>
                We and ESIC are working together towards having all the right policies, streamlined operations and the
                environment that promotes and verifies the high credibility in our games.
            </p>
            <p>
                <strong>New Players</strong>
            </p>
            <p>
                New players that enter GT have to play qualifying games with the main squad before they become part of
                the main squad. Such qualification games are not offered for betting. New players are enrolled to an
                appropriate league based on their results. Every visitor can check the players’ level via the dashboard,
                standings or global rankings. Qualification games and their results are also available on the GT Leagues
                site.
            </p>
            <p>
                <strong>Rewarding Players</strong>
            </p>
            <p>
                We are motivating players to perform better and improve their game. All players can receive bonuses
                based on their performance, or miss the bonuses if they do not perform well. You can always be sure that
                our players are fully motivated and locked in, in all games.
            </p>
        </div>
    );
};

export default CredibilityOfTheGame;
