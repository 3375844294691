import React from 'react';
import Helmet from 'react-helmet';
import Channel from '../index';

const TV1 = () => (
    <React.Fragment>
        <Helmet>
            <title>GT Sports - TV Channel 1</title>
            <meta
                name="description"
                content="GTSports TV - Channel 1 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:title" content="GT Sports - TV Channel 1" />
            <meta
                property="og:description"
                content="GTSports TV - Channel 1 - Live Streaming. Watch your favorite games in live action."
            />
            <meta property="og:url" content="https://gtleagues.com/TV1" />
        </Helmet>
        <Channel channelId="gt_sports_tv1" channelName="TV1" />;
    </React.Fragment>
);

export default TV1;
