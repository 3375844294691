import React from 'react';
import Api, { esportsApi } from 'helpers/Api';
import Status from 'pages/sportstree/fixtures/Status';
import moment from 'moment-timezone';
import { getTimeZone } from 'helpers/dates';
import { createCachedCall } from 'helpers/utils';
import { action, computed, decorate, observe, observable } from 'mobx';

export default class Dashboard {
    constructor() {
        this.fixturesApi = new Api('fixtures');

        this.getFixturesCountCall = createCachedCall(async () => {
            const tz = getTimeZone();
            const DAYS = 10;
            const start = this.fixturesCountStartDay.clone().add(this.activeDay, 'days');
            const startISO = start.tz(tz).startOf('day').toISOString();
            const end = start.clone().add(DAYS, 'days').tz(tz).endOf('day').toISOString();

            const between = `${startISO},${end}`;

            return esportsApi.getList({ kickoff: `between:${between}`, timezone: tz }, 'fixtures/count');
        }, 1000 * 60 * 5);

        observe(this, 'activeDay', (change) => {
            this.setActiveStatus(null);
            const secondsPassed = moment().diff(this.fixturesCountStartDay, 'seconds');
            if (secondsPassed > 60 * 5) {
                this.setFixturesCountStartDay(moment());
            }
        });
    }

    activeSport;
    activeCategory;
    activeStatus;
    liveMatchesCount;
    todaysMatchesCount;
    upcomingMatchesCount;
    endedMatchesCount = 0;
    scheduledMatchesCount = 0;
    remainingMatchesCount = 0;
    fixturesCount = observable.array();
    fixturesCountStartDay = moment();
    activeDay = 0;

    setActiveSport(value) {
        this.activeSport = value;
        this.setActiveCategory(null);
    }

    setActiveCategory(value) {
        this.activeCategory = value;
    }

    setActiveStatus(value) {
        this.activeStatus = value;
    }

    setFixturesCountStartDay(value) {
        this.fixturesCountStartDay = value;
    }

    getEndedMatchesCount() {
        const date = moment().tz(getTimeZone());
        const startISO = date.clone().startOf('day').toISOString();
        const endISO = date.clone().endOf('day').toISOString();

        const filters = [
            {
                field: 'kickoff',
                operator: 'between',
                value: `${startISO},${endISO}`,
            },
            {
                field: 'status',
                operator: 'in',
                value: `${Status.ENDED},${Status.FORFEIT}`,
            },
        ];

        this.fixturesApi.getList({ limit: 0, xtc: true, filters }).then(
            action((response) => {
                this.endedMatchesCount = response.total;
            })
        );
    }

    getScheduledMatchesCount() {
        const date = moment().tz(getTimeZone());
        const startISO = date.clone().startOf('day').toISOString();
        const endISO = date.clone().endOf('day').toISOString();

        const filters = [
            {
                field: 'kickoff',
                operator: 'between',
                value: `${startISO},${endISO}`,
            },
        ];

        this.fixturesApi.getList({ limit: 0, xtc: true, filters }).then(
            action((response) => {
                this.scheduledMatchesCount = response.total;
            })
        );
    }

    getRemainingMatchesCount() {
        const date = moment().tz(getTimeZone());
        const startISO = date.clone().startOf('day').toISOString();
        const endISO = date.clone().endOf('day').toISOString();

        const filters = [
            {
                field: 'kickoff',
                operator: 'between',
                value: `${startISO},${endISO}`,
            },
            {
                field: 'status',
                operator: 'in',
                value: [Status.NOT_STARTED, Status.LIVE, Status.SUSPENDED],
            },
        ];

        this.fixturesApi.getList({ limit: 0, xtc: true, filters }).then(
            action((response) => {
                this.remainingMatchesCount = response.total;
            })
        );
    }

    getFixturesCount() {
        const DAYS = 10;
        const start = this.fixturesCountStartDay.clone().add(this.activeDay, 'days');
        return this.getFixturesCountCall([this.fixturesCountStartDay.toISOString(), this.activeDay]).then(
            action((response) => {
                const responseMap = response.reduce((map, item) => {
                    map.set(item.date.split('T')[0], item.count);
                    return map;
                }, new Map());

                const result = Array.from({ length: DAYS }, (_, i) =>
                    start.clone().add(i, 'days').format('YYYY-MM-DD')
                ).map((date) => {
                    const key = date;
                    if (!responseMap.has(key)) {
                        responseMap.set(key, '-');
                    }

                    return { date: key, count: responseMap.get(key) };
                });

                this.fixturesCount.replace(result);
            })
        );
    }

    setActiveDay(value) {
        this.activeDay = value;
    }

    get totalFixturesCount() {
        return this.fixturesCount.reduce((carry, item) => {
            const num = parseInt(item.count) || 0;
            return carry + num;
        }, 0);
    }
}

decorate(Dashboard, {
    activeSport: observable,
    activeCategory: observable,
    activeStatus: observable,
    liveMatchesCount: observable,
    todaysMatchesCount: observable,
    upcomingMatchesCount: observable,
    fixturesCount: observable,
    endedMatchesCount: observable,
    scheduledMatchesCount: observable,
    remainingMatchesCount: observable,
    activeDay: observable,
    totalFixturesCount: computed,
    setActiveSport: action.bound,
    setActiveCategory: action.bound,
    setActiveStatus: action.bound,
    getFixturesCount: action.bound,
    getEndedMatchesCount: action.bound,
    getScheduledMatchesCount: action.bound,
    getRemainingMatchesCount: action.bound,
    setActiveDay: action.bound,
});

export const dashboard = new Dashboard();

if (process.env.NODE_ENV === 'development') window.dashboard = dashboard;

const storeContext = React.createContext(dashboard);

export const useDashboardStore = () => React.useContext(storeContext);
