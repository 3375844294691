import React, { useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import SportStandings from 'components/SportStandings';
import SportsFilter from 'components/SportsFilter';
import { useStores } from 'stores/mobx';
import { observer } from 'mobx-react-lite';

const GlobalRanking = () => {
    const { common } = useStores();
    const { activeSports: sports } = common;
    const [activeSport, setActiveSport] = useState(sports[0] ? sports[0].id : null);

    useEffect(() => {
        setActiveSport(sports[0] ? sports[0].id : null);
    }, [sports]);

    let selectedSport = useMemo(
        () =>
            activeSport && sports.length > 0
                ? {
                      label: sports.find((s) => s.id === activeSport).name,
                      value: sports.find((s) => s.id === activeSport).id,
                  }
                : null,
        [activeSport, sports]
    );

    return (
        <div>
            <Helmet>
                <title>GT Sports - Global Ranking</title>
                <meta
                    name="description"
                    content="Check how players rank among players across seasons and tournaments. Identify upcoming stars and player performance statistics."
                />
                <meta property="og:title" content="GT Sports - Global Ranking" />
                <meta
                    property="og:description"
                    content="Check how players rank among players across seasons and tournaments. Identify upcoming stars and player performance statistics."
                />
                <meta property="og:url" content="https://gtleagues.com/global-ranking" />
                <meta name="keywords" content="gtsports gtleagues esports e-gaming e-sports global ranking" />
            </Helmet>
            <div className="md:w-1/4 mb-4">
                <SportsFilter
                    label="Select a Sport"
                    value={selectedSport}
                    onChange={(selected) => setActiveSport(selected ? selected.value : null)}
                />
            </div>
            {activeSport && (
                <SportStandings
                    title="Global Ranking"
                    sportId={activeSport}
                    options={{
                        headerStyle: {
                            padding: '15px',
                        },
                        cellStyle: {
                            padding: '15px',
                        },
                    }}
                />
            )}
        </div>
    );
};

export default observer(GlobalRanking);
