import React from 'react';
import Common from './Common';
import FavoriteSeasons from './FavoriteSeasons';
import { sportstree } from 'pages/sportstree/store';
import { auth } from 'app/modules/Auth/auth.store';
export { default as mockAxios } from './__mocks__/mockAxios';
export { default as setupAxios } from './setupAxios';

const storesContext = React.createContext({
    common: new Common(),
    favoriteSeasons: new FavoriteSeasons(sportstree, auth),
});

export const useStores = () => React.useContext(storesContext);
