class Format {
    ROUND_ROBIN = 1;
    KNOCKOUT = 2;
    GROUP = 3;

    getLabel(value) {
        switch (value) {
            case this.ROUND_ROBIN:
                return 'Round Robin';
            case this.KNOCKOUT:
                return 'Knockout';
            case this.GROUP:
                return 'Group';
            default:
                return '';
        }
    }
}

export default new Format();
