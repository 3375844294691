import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/mobx';

const Favorite = ({ row }) => {
    const { favoriteSeasons } = useStores();

    return favoriteSeasons.data.some((favoriteSeason) => favoriteSeason.seasonId === row.id) ? (
        <StarIcon color="primary" />
    ) : (
        <StarIcon />
    );
};

export default observer(Favorite);
