import React from 'react';
import Async from 'react-select/async';

const customStyles = {
    control: (base, state) => ({
        ...base,
        color: '#fff',
        background: '#4a7dff',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        svg: {
            fill: '#fff',
        },
    }),
    indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: '#fff',
    }),
    singleValue: (base) => ({
        ...base,
        color: '#fff',
    }),
    input: (base) => ({
        color: '#fff',
    }),
    placeholder: (base) => ({
        ...base,
        color: 'hsl(0 ,0% ,90%)',
    }),
    menu: (base) => ({
        ...base,
        zIndex: 20,
    }),
};

function AsyncInput(props) {
    return (
        <Async
            isClearable
            styles={customStyles}
            defaultOptions
            cacheOptions
            {...props}
        />
    );
}

export default AsyncInput;
