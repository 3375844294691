import React, { useEffect, useState, useRef, useMemo } from 'react';
import List from 'components/List';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import EditAction from 'components/List/EditAction';
import CreateEditDialog from 'pages/sportstree/CreateEditDialog';
import NavigateNextIcon from 'pages/sportstree/NavigateNextIcon';
import Api from 'helpers/Api';
import seasonsHelpers from 'helpers/seasons';
import Status from 'pages/sportstree/seasons/Status';
import Favorite from 'pages/sportstree/seasons/Favorite';
import Format from 'helpers/seasons/Format';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button } from 'react-bootstrap';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { useAuthStore } from 'app/modules/Auth/auth.store';
import { useStores } from 'stores/mobx';
import * as Yup from 'yup';

export const isKnockout = (values) => Array.isArray(values.format) && values.format.includes(Format.KNOCKOUT);

function Seasons() {
    const history = useHistory();
    const { user } = useAuthStore();
    const { tournamentId } = useParams();
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [selectedResourceId, setSelectedResourceId] = useState(null);
    const store = useSportsTreeStore();
    const favoriteSeasonsApiRef = useRef(user ? new Api(`users/${user.id}/favorite-seasons`) : null);
    const { favoriteSeasons } = useStores();
    const season = store.getResource('seasons', selectedResourceId);

    useEffect(() => {
        if (store.selectedTournament.id === -1) {
            history.push(`/sports-tree/sports`);
        }
        store.setDepth(4);
    }, [store, history]);

    let validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        status: Yup.number().required(),
        format: Yup.array(),
        tournamentId: Yup.number().required(),
        totalSeeds: Yup.number().when('format', (format, schema) => {
            if (Array.isArray(format) && format.includes(Format.KNOCKOUT)) {
                return schema.required();
            }

            return schema;
        }),
    });

    const toggleFavorite = (seasonId) => {
        const api = new Api(`users/favorite-seasons`);

        if (favoriteSeasons.data.some((favoriteSeason) => favoriteSeason.seasonId === seasonId)) {
            const favoriteSeasonId = favoriteSeasons.data.find((favoriteSeason) => favoriteSeason.seasonId === seasonId)
                .id;
            api.delete(favoriteSeasonId).then(() => {
                favoriteSeasonsApiRef.current.getList({ xtc: false }).then((response) => {
                    favoriteSeasons.setData(response);
                });
            });
        } else {
            api.create({
                userId: user.id,
                seasonId,
            }).then((data) => {
                favoriteSeasonsApiRef.current.getList({ xtc: false }).then((response) => {
                    favoriteSeasons.setData(response);
                });
            });
        }
    };

    const initialValues = useMemo(() => {
        const resource = store.getResource('seasons', selectedResourceId);

        return {
            ...(resource && { id: resource.id }),
            name: resource ? resource.name : '',
            status: resource ? resource.status : '',
            tournamentId: store.selectedTournament.id,
        };
    }, [store, selectedResourceId]);

    const fields = [
        { name: 'name', label: 'Name', componentType: 'Input' },
        { name: 'tournamentId', componentType: 'HiddenInput' },
        {
            name: 'status',
            label: 'Status',
            componentType: 'SelectInput',
            options: seasonsHelpers.getStatuses(),
        },
        {
            name: 'format',
            label: 'Format',
            componentType: 'Select',
            options: seasonsHelpers.getFormats(),
            isMulti: true,
            defaultValue:
                selectedResourceId && store.getResource('seasons', selectedResourceId)
                    ? store
                          .getResource('seasons', selectedResourceId)
                          .format.map((value) => ({ label: Format.getLabel(value), value }))
                    : '',
        },
    ];

    const knockoutFields = [
        {
            name: 'totalSeeds',
            label: 'Total Seeds',
            componentType: 'Select',
            options: [
                {
                    value: 4,
                    label: '4',
                },
                {
                    value: 8,
                    label: '8',
                },
                {
                    value: 16,
                    label: '16',
                },
                {
                    value: 32,
                    label: '32',
                },
            ],
            condition: isKnockout,
        },
        // {
        //     name: 'rankedBasedAllocation',
        //     label: 'Ranked Based Allocation',
        //     componentType: 'Checkbox',
        //     condition: isKnockout,
        // },
        // {
        //     name: 'matchupDistribution',
        //     label: 'Matchup Distribution',
        //     componentType: 'Select',
        //     options: seasonsHelpers.getMatchupDistributions(),
        //     condition: isKnockout,
        // },
        {
            name: 'eliminationMode',
            label: 'Elimenation Mode',
            componentType: 'Select',
            options: seasonsHelpers.getEliminationModes(),
            condition: isKnockout,
        },
        {
            name: 'totalRounds',
            label: 'Total Rounds',
            componentType: 'Input',
            type: 'number',
            condition: (values) => isKnockout(values) && +values.eliminationMode === 1,
        },
        {
            name: 'progressionRounds',
            label: 'Progression Rounds',
            componentType: 'Input',
            type: 'number',
            condition: (values) => isKnockout(values) && +values.eliminationMode === 2,
        },
        {
            name: 'singleGameFinal',
            label: 'Single Game Final',
            componentType: 'Checkbox',
            condition: isKnockout,
        },
    ];

    const createFields = [...fields, ...knockoutFields];

    return (
        <>
            <CreateEditDialog
                id={selectedResourceId}
                show={showCreateEdit}
                onHide={() => {
                    store.refreshTable();
                    setShowCreateEdit(false);
                    setSelectedResourceId(null);
                }}
                resource="seasons"
                fields={season ? fields : createFields}
                initialValues={initialValues}
                validationSchema={validationSchema}
                customOnSubmit={
                    season
                        ? null
                        : (data) => {
                              const {
                                  totalSeeds,
                                  //   rankedBasedAllocation,
                                  //   matchupDistribution,
                                  eliminationMode,
                                  totalRounds,
                                  progressionRounds,
                                  singleGameFinal,
                                  ...rest
                              } = data;

                              return new Api('seasons').create(rest).then((response) => {
                                  if (totalSeeds) {
                                      new Api('knockouts').create({
                                          seasonId: response.id,
                                          totalSeeds,
                                          //   rankedBasedAllocation,
                                          //   matchupDistribution,
                                          eliminationMode,
                                          totalRounds:
                                              +eliminationMode === 2
                                                  ? progressionRounds + progressionRounds - 1
                                                  : totalRounds,
                                          progressionRounds,
                                          singleGameFinal,
                                      });
                                  }

                                  return response;
                              });
                          }
                }
            />
            <List
                defaultOrderBy="createdAt"
                hasFiltering={false}
                title={`${store.selectedTournament.name} Seasons`}
                apiPath={`tournaments/${tournamentId}/seasons`}
                resource="seasons"
                fields={[
                    { field: 'name', title: 'Name' },
                    !!user
                        ? {
                              field: 'favorite',
                              title: 'Favorite',
                              sorting: false,
                              render: (rowData) => (
                                  <IconButton
                                      onClick={(event) => {
                                          event.stopPropagation();
                                          toggleFavorite(rowData.id);
                                      }}
                                  >
                                      <Favorite row={rowData} />
                                  </IconButton>
                              ),
                          }
                        : null,
                    {
                        field: 'status',
                        title: 'Status',
                        sorting: false,
                        render: (rowData) => <Status enabled={!!user} seasonId={rowData.id} status={rowData.status} />,
                    },
                    !!user
                        ? {
                              field: 'edit',
                              title: 'Edit',
                              sorting: false,
                              render: (rowData) => (
                                  <EditAction
                                      handleClick={() => {
                                          setSelectedResourceId(rowData.id);
                                          setShowCreateEdit(true);
                                      }}
                                  />
                              ),
                          }
                        : null,
                    {
                        field: 'stats',
                        title: 'Stats',
                        sorting: false,
                        render: (rowData) => (
                            <div className="cursor-pointer" onClick={() => history.push(`/head-to-head/${rowData.id}`)}>
                                <i className="far fa-chart-bar"></i>
                            </div>
                        ),
                    },
                    {
                        field: 'season',
                        title: 'Season',
                        sorting: false,
                        render: (rowData) => (
                            <Link
                                style={{ margin: 10 }}
                                onClick={() => {
                                    store.setSelectedSeason({
                                        id: rowData.id,
                                        name: rowData.name,
                                    });
                                    store.setDepth(5);
                                    history.push(`/sports-tree/seasons/${rowData.id}`);
                                }}
                            >
                                <NavigateNextIcon />
                            </Link>
                        ),
                    },
                ].filter(Boolean)}
                renderToolbar={() => (
                    <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ marginLeft: 15 }} onClick={() => setShowCreateEdit(true)}>
                            Create Season
                        </Button>
                    </div>
                )}
            />
        </>
    );
}

export default observer(Seasons);
