import React from 'react';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';

export default function Checkbox({
    field,
    form: { touched, errors },
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = 'text',
    ...props
}) {
    return (
        <>
            <div className="form-check">
                <input
                    type="checkbox"
                    id={field.name}
                    className="form-check-input"
                    {...field}
                    {...props}
                    checked={field.value}
                />
                {label && (
                    <label title="" htmlFor={field.name} className="form-check-label">
                        {label}
                    </label>
                )}
            </div>

            {withFeedbackLabel && !props.disabled && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
