import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores/mobx';
import SelectInput from 'components/SelectInput';

const SportsFilter = ({ label = 'All Sports', ...props }) => {
    const {common} = useStores();
    const {activeSports: sports} = common;

    const options = useMemo(() => sports.map((s) => ({ value: s.id, label: s.name })), [sports]);

    return <SelectInput {...props} placeholder={label} options={options} />;
};

export default observer(SportsFilter);
