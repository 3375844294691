import React, { useEffect, useState } from 'react';
import List from 'components/List';
import Api from 'helpers/Api';
import StandingsLegend from 'components/StandingsLegend';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { useSportsTreeStore } from 'pages/sportstree/store';
import { standingSort } from 'pages/currentseasons/Standings';
import { getFields, calcStandings, rowStyle } from 'helpers/standings';

function Standings() {
    const { seasonId } = useParams();
    const history = useHistory();
    const store = useSportsTreeStore();
    const [data, setData] = useState(null);
    const [tagId, setTagId] = useState(null);

    useEffect(() => {
        if (store.selectedSeason.id === -1) {
            history.push(`/sports-tree/sports`);
        }
    }, [history, store]);

    useEffect(() => {
        new Api(`seasons/${seasonId}/standings`).getList({ xtc: false }).then((response) => {
            setData(response.data.map(calcStandings(store.selectedSport.id)));
            setTagId(response.tagId);
        });
    }, [seasonId, store.selectedSport.id]);

    return (
        <>
            {data && (
                <React.Fragment>
                    <List
                        data={data}
                        hasSelection={false}
                        hasPagination={false}
                        hasSearch={false}
                        hasFiltering={false}
                        title={`${store.selectedSeason.name} Standings`}
                        options={{
                            rowStyle: rowStyle(true, data),
                        }}
                        fields={getFields(tagId).map((field) => ({
                            ...field,
                            filtering: false,
                            emptyValue: '-',
                            customSort: standingSort(field),
                        }))}
                    />
                    <StandingsLegend />
                </React.Fragment>
            )}
        </>
    );
}

export default observer(Standings);
