import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SVG from 'react-inlinesvg';
import Api from 'helpers/Api';
import seasonsHelpers from 'helpers/seasons';
import Status from 'helpers/seasons/Status';
import { toAbsoluteUrl } from 'helpers/utils';

const options = seasonsHelpers.getStatuses();

export default function StatusHandler({ status, enabled, seasonId }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(status);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        const newStatus = +event.currentTarget.dataset.value;

        if (!isNaN(newStatus)) {
            new Api('seasons').update(seasonId, { status: newStatus }).then((response) => {
                setCurrentStatus(newStatus);
            });
        }

        setAnchorEl(null);
    };

    useEffect(() => {
        setCurrentStatus(status);
    }, [status]);

    return (
        <div>
            <Tooltip title={Status.getLabel(currentStatus)}>
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                    <SVG
                        src={toAbsoluteUrl(
                            `/media/pages/sportstree/seasons_statuses/${seasonsHelpers.getStatusIcon(currentStatus)}`
                        )}
                    />
                </IconButton>
            </Tooltip>
            {enabled && (
                <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                    {options.map((option) => (
                        <MenuItem
                            data-value={option.value}
                            key={option.value}
                            selected={option.value === currentStatus}
                            onClick={handleClose}
                        >
                            <ListItemIcon>
                                <SVG
                                    src={toAbsoluteUrl(
                                        `/media/pages/sportstree/seasons_statuses/${seasonsHelpers.getStatusIcon(
                                            option.value
                                        )}`
                                    )}
                                />
                            </ListItemIcon>
                            <Typography>{option.label}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    );
}
