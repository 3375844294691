import React, { useMemo, useCallback } from 'react';
import AsyncInput from 'components/AsyncInput';
import Api from 'helpers/Api';

const SeasonsFilter = ({ tournamentId, ...rest }) => {
    const sportsApi = useMemo(() => new Api(tournamentId ? `tournaments/${tournamentId}/seasons` : 'seasons'), [tournamentId]);

    const loadOptions = useCallback(
        (inputValues) => {
            return sportsApi
                .getList({ q: inputValues })
                .then((sport) => sport.map((s) => ({ value: s.id, label: s.name })));
        },
        [sportsApi]
    );

    return <AsyncInput key={tournamentId} {...rest} placeholder="All Seasons" loadOptions={loadOptions} />;
};

export default SeasonsFilter;
