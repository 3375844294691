class EliminationMode {
    SCORE_AGGREGATION = 1;
    FIRST_TO_X_WINS = 2;

    getLabel(value) {
        switch (value) {
            case this.SCORE_AGGREGATION:
                return 'Score Aggregation';
            case this.FIRST_TO_X_WINS:
                return 'First to X Wins';

            default:
                return '';
        }
    }
}

export default new EliminationMode();
