import * as React from 'react';

export function getSafePercent(percent) {
    return Math.min(100, Math.max(percent, 0));
}

export function getStepPosition(steps, stepIndex, hasStepZero) {
    if (hasStepZero) {
        return (100 / (steps - 1)) * stepIndex;
    }
    return (100 / steps) * (stepIndex + 1);
}

export default class ProgressBar extends React.Component {
    render() {
        const {
            percent,
            children,
            stepPositions = [],
            filledBackground = null,
            hasStepZero = true,
            text = null,
        } = this.props;

        const safePercent = getSafePercent(percent);

        return (
            <div className="react-progress-bar">
                {React.Children.map(children, (step, index) => {
                    const position =
                        stepPositions.length > 0
                            ? stepPositions[index]
                            : getStepPosition(React.Children.count(children), index, hasStepZero);

                    return React.cloneElement(step, {
                        accomplished: position <= safePercent,
                        position,
                        index,
                    });
                })}

                {text ? <div className="react-progress-bar__text">{text}</div> : null}

                <div
                    className="react-progress-bar__progression"
                    style={{
                        background: filledBackground,
                        width: `${safePercent}%`,
                    }}
                />
            </div>
        );
    }
}
